import { useApi } from 'contexts';
import { classes, desktopWallet } from 'utils';

const DefaultWalletList = () => {
  const { onConnect, setSelectedWallet } = useApi();
  return (
    <div>
      {desktopWallet.map(({ value, img, name }) => {
        return (
          <button
            data-cy={`${name}`}
            key={value}
            className={classes(
              ' mb-2 flex max-w-full items-center overflow-hidden text-ellipsis px-4 py-2 rounded-2xl border border-[#ffffff1f] hover:bg-[#ffffff0d] hover:border-[#ffffff0d] h-[88px] w-[496px] gap-4 last:mb-0 focus:border-white'
            )}
            onClick={() => {
              setSelectedWallet(value);
              onConnect(null, value);
            }}
          >
            <img src={img} alt={value} width={56} height={56} className="rounded-lg" />
            <span>{name}</span>
          </button>
        );
      })}
    </div>
  );
};

export default DefaultWalletList;
