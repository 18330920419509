import { Layout } from 'components';
import {
  ApiKeyProvider,
  ApiProvider,
  MyProfileProvider,
  PublisherProvider,
  ResourceProvider,
} from 'contexts';
import { TabProvider } from 'contexts/Tab';
import { SearchType } from 'gql';
import {
  ApiKeyPage,
  BalanceHistoryPage,
  FundingActivityPage,
  LandingPage,
  LibraryPage,
  MyProfilePage,
  NotFoundPage,
  OnBoardingPage,
  PublisherPage,
  SmartContractPage,
  ViewBuilderPage,
  ViewPage,
} from 'pages';
import { Navigate, createBrowserRouter } from 'react-router-dom';

import { detailsRoutes, routes } from './routesConst';

export const router = createBrowserRouter([
  {
    element: (
      <ApiProvider>
        <Layout classNames={{ inner: 'md:pt-0 sm:px-0 px-3', base: 'bg-[#010101] ' }} isLanding />
      </ApiProvider>
    ),
    children: [
      {
        path: routes.base,
        element: <LandingPage />,
      },
    ],
  },
  {
    element: (
      <ApiProvider>
        <Layout
          isOnboarding
          classNames={{ inner: 'flex md:items-center justify-center md:pt-0' }}
        />
      </ApiProvider>
    ),
    children: [
      {
        path: routes.onboarding,
        element: <OnBoardingPage />,
      },
    ],
  },
  {
    element: (
      <ApiProvider>
        <TabProvider>
          <Layout />
        </TabProvider>
      </ApiProvider>
    ),
    children: [
      {
        path: routes.library,
        element: <LibraryPage />,
      },
      {
        path: routes.profile,
        element: (
          <MyProfileProvider>
            <MyProfilePage />
          </MyProfileProvider>
        ),
      },
      {
        path: routes.viewBuilder,
        element: <ViewBuilderPage />,
      },
      {
        path: detailsRoutes.smartContract,
        element: (
          <ResourceProvider type={SearchType.SmartContracts}>
            <SmartContractPage />
          </ResourceProvider>
        ),
      },
      {
        path: detailsRoutes.view,
        element: (
          <ResourceProvider type={SearchType.Views}>
            <ViewPage />
          </ResourceProvider>
        ),
      },
      {
        path: detailsRoutes.apiKey,
        element: (
          <ApiKeyProvider>
            <ApiKeyPage />
          </ApiKeyProvider>
        ),
      },
      {
        path: detailsRoutes.publisher,
        element: (
          <PublisherProvider>
            <PublisherPage />
          </PublisherProvider>
        ),
      },
      {
        path: detailsRoutes.fundingActivity,
        element: <FundingActivityPage />,
      },
      {
        path: routes.balanceHistory,
        element: (
          <MyProfileProvider>
            <BalanceHistoryPage />
          </MyProfileProvider>
        ),
      },
    ],
  },
  {
    path: routes[404],
    element: <NotFoundPage />,
  },
  {
    path: '/*',
    element: <Navigate to={routes[404]} replace />,
  },
]);
