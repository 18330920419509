import { useCallback } from 'react';

import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import BigNumber from 'bignumber.js';
import { useApi } from 'contexts';
import { useBalance } from 'hooks';
import { classes, formatBalance } from 'utils';

import { Button } from '../Button';
import { DeploySuccess } from '../DeploySuccess';
import { FormatBalance } from '../FormatBalance';
import { Identicon } from '../Identicon';
import { Input } from '../Input';
import { Loader } from '../Loader';
import { Modal } from '../Modal';
import { BrokenExclamation } from '../svg';
import { ToolTip } from '../ToolTip';

import { FundsModalProps } from './types';

export function FundsModal({
  showBalance,
  accountBalance,
  isOpen,
  setIsOpen,
  isLoading,
  depositStatus,
  renderContent,
  texts: { header, headerSub, submit, inputPlaceHolder, inputLabel },
  successTexts: { header: successHeader, submitToButton },
  successModalSubmitTo,
  onlyAccountBalance,
  accountBalanceText,
  isAddFunds,
  minimumReq,
  amount,
  setAmount,
  Summary,
  onSubmit,
  amountError,
  fundRefAddress,
  error,
  disabledSubmitButton,
  LOWER_LIMIT,
}: FundsModalProps) {
  const {
    account,
    chainProps: { tokenDecimals },
  } = useApi();

  const walletBalance = useBalance(account?.address);

  const format = useCallback(
    (balance: BigNumber) => {
      const x = formatBalance(balance, {
        symbol: '',
        decimalPlaces: 5,
        tokenDecimals,
      });
      return Number(x);
    },
    [tokenDecimals]
  );

  return fundRefAddress === null ? (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      classNames={{ base: 'p-0 bg-transparent', dialog: 'z-[100]' }}
      isDarkTheme
    >
      <div className="max-w-full sm:w-full flex flex-col md:gap-6 gap-5 md:p-[32px] px-[24px] py-[18px] relative bg-gradient-to-b from-black to-black rounded-3xl shadow border border-[#1C1C1C]  h-fit">
        {isLoading ? (
          <div className="w-[80vw] max-w-[510px] flex flex-col items-center justify-center">
            <Loader className="h-[320px] flex text-white" />
            {submit === 'Deposit' && depositStatus === 'isInBlock' && (
              <span>Your deposit is being been processed.</span>
            )}
          </div>
        ) : (
          <>
            <div className="flex-col justify-start items-start md:gap-2 gap-[6px] flex">
              <div
                data-cy={`${header}`}
                className="self-stretch text-white md:text-2xl text-xl font-normal"
              >
                {header}
              </div>
              <div className=" text-[#B2B3B8] text-sm font-normal leading-tight">{headerSub}</div>
            </div>
            <div className="p-4 flex flex-col gap-3 rounded-[10px] border border-[#1F1F1F] bg-[#060606]">
              {!onlyAccountBalance && (
                <div className="flex items-center gap-2">
                  <Identicon
                    className="h-[32px] w-[32px] rounded-full"
                    value={account?.address || ''}
                  />
                  <div className="text-white text-base font-medium leading-none">
                    {account?.meta.name}
                  </div>
                </div>
              )}
              <div className="flex flex-row">
                {showBalance && !onlyAccountBalance && (
                  <div className="flex-1 justify-start gap-1 flex flex-col">
                    <div className="text-[#808080] text-xs font-normal leading-none">
                      Wallet Balance
                    </div>
                    <div
                      data-cy={`${
                        tokenDecimals &&
                        formatBalance(walletBalance, {
                          symbol: '',
                          decimalPlaces: 5,
                          tokenDecimals,
                        })
                      }`}
                      className=" text-white text-sm font-medium leading-[18.20px]"
                    >
                      {tokenDecimals &&
                        formatBalance(walletBalance, {
                          symbol: '$TANLOG',
                          decimalPlaces: 5,
                          tokenDecimals,
                        })}
                    </div>
                  </div>
                )}
                {accountBalance && (
                  <div className="flex-1 justify-start gap-1 flex flex-col">
                    <div className="text-[#808080] text-xs font-normal  leading-none">
                      {accountBalanceText || 'Account Balance'}
                    </div>
                    <div
                      data-cy={`${accountBalance}`}
                      className=" text-white text-sm font-medium  leading-[18.20px]"
                    >
                      {tokenDecimals &&
                        formatBalance(accountBalance, {
                          symbol: '$TANLOG',
                          decimalPlaces: 5,
                          tokenDecimals,
                        })}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div>
              <div className="text-[#808080] text-xs font-normal leading-none mb-[6px] flex gap-1">
                {inputLabel}
                {isAddFunds && (
                  <ToolTip
                    message={'Add minimum amount of funds needed to be deposited'}
                    anchor="center-up"
                  >
                    <QuestionMarkCircleIcon className="h-[14px] w-[14px]" />
                  </ToolTip>
                )}
              </div>
              <Input
                dataCy="input-amount"
                className={classes(
                  'text-white !border-[#1F1F1F] !bg-[#060606]',
                  error && '!border-[#FF6666]'
                )}
                value={amount.toString()}
                onChange={(text) => {
                  if (Number(text) >= 0) {
                    const [number1, decimals1] = [...text].join('').split('.');
                    if (text.includes('.')) {
                      decimals1.length < 6 && setAmount(number1 + '.' + decimals1);
                    } else {
                      setAmount(text);
                    }
                  }
                }}
                placeholder={inputPlaceHolder}
                filter={/[^\d.]/g}
                isDarkTheme
                classNames={{
                  base: 'shadow-[transparent]',
                  input: classes('rounded-3xl'),
                }}
              >
                <div className="gap-2 flex items-center text-sm">
                  {!isAddFunds && <div className="font-normal">$TANLOG</div>}
                  <button
                    data-cy="Max-btn"
                    className={classes(
                      'text-[10px] px-2 mr-3 bg-white rounded-2xl flex-col justify-center items-center gap-2.5 inline-flex text-slate-950 font-normal uppercase',
                      isAddFunds && 'text-white bg-[#FFFFFF14] px-3 py-2 mr-2 font-medium text-xs'
                    )}
                    onClick={() =>
                      setAmount(
                        formatBalance(
                          submit === 'Withdraw' || isAddFunds ? accountBalance : walletBalance,
                          {
                            symbol: '',
                            decimalPlaces: 5,
                            tokenDecimals,
                          }
                        )
                      )
                    }
                  >
                    max
                  </button>
                </div>
              </Input>
              {amountError && (
                <div
                  data-cy={`${amountError}`}
                  className="flex flex-row gap-1 my-2 text-[#FF6666] text-xs leading-[20px] items-center"
                >
                  <BrokenExclamation />
                  {amountError}
                </div>
              )}
              {!(
                submit === 'Deposit' &&
                tokenDecimals &&
                Number(
                  formatBalance(walletBalance, {
                    symbol: '',
                    decimalPlaces: 5,
                    tokenDecimals,
                  })
                ) <= 0
              ) && (
                <div className="w-full md:mt-3 mt-2 h-4 justify-between gap-2 inline-flex items-center">
                  <div className="flex items-center flex-row gap-[6px] text-[#B2B3B8]">
                    <div className="text-sm font-normal  capitalize leading-none">
                      {isAddFunds ? (
                        <div className="text-zinc-400 text-xs sm:mt-0 mt-8 font-normal leading-none flex justify-between w-full gap-2 sm:flex-row flex-col">
                          <div className="flex">
                            {format(new BigNumber(accountBalance || 0)) <
                              format(new BigNumber(minimumReq || 0)) && 'Minimum '}
                            Limit:
                            <div
                              data-cy={`${minimumReq}`}
                              className="text-white text-xs font-normal leading-none flex flex-row ml-1"
                            >
                              <FormatBalance
                                value={minimumReq || ''}
                                options={{ decimalPlaces: 5 }}
                              />

                              {format(new BigNumber(accountBalance || 0)) >
                              format(new BigNumber(minimumReq || 0)) ? (
                                <>
                                  <span className="mx-1">-</span>
                                  <span>
                                    {formatBalance(new BigNumber(accountBalance || 0), {
                                      symbol: '$TANLOG',
                                      decimalPlaces: 5,
                                      tokenDecimals,
                                    })}
                                  </span>
                                </>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="text-zinc-400 text-sm font-normal leading-none flex items-center justify-between w-full">
                          {submit === 'Withdraw' ? (
                            <div>
                              {format(new BigNumber(accountBalance || 0)) < LOWER_LIMIT!
                                ? 'Minimum '
                                : ''}
                              Limit:
                            </div>
                          ) : (
                            <div>Maximum balance amount: </div>
                          )}
                          <div className="justify-start ml-1 text-sm items-baseline gap-0.5 flex">
                            {submit === 'Withdraw' && (
                              <>
                                <span className="text-right text-white text-sm font-normal capitalize leading-3">
                                  {LOWER_LIMIT} $TANLOG
                                </span>
                              </>
                            )}
                            <span className="text-right text-white text-sm font-normal capitalize leading-3">
                              {submit === 'Withdraw' ? (
                                format(new BigNumber(accountBalance || 0)) > LOWER_LIMIT! ? (
                                  <>
                                    <span className="mx-1">-</span>
                                    {formatBalance(new BigNumber(accountBalance || 0), {
                                      symbol: '$TANLOG',
                                      decimalPlaces: 5,
                                      tokenDecimals,
                                    })}
                                  </>
                                ) : (
                                  ''
                                )
                              ) : (
                                formatBalance(new BigNumber(walletBalance || 0), {
                                  symbol: '$TANLOG',
                                  decimalPlaces: 5,
                                  tokenDecimals,
                                })
                              )}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* {!isAddFunds && <InformationIcon className="h-4 w-4 text-[#B2B3B8]" />} */}
                  </div>
                </div>
              )}
            </div>

            {!!error && (
              <p className="text-[#F66] text-sm font-normal capitalize min-h-5">{error}</p>
            )}
            {!!Number(amount) && !amountError && !error && Summary}

            <Button
              variant="plain"
              className={classes(
                'md:min-w-[168px] min-w-[94px] m-auto md:h-12 h-11 px-5 pt-[7px] pb-[9px] bg-white rounded-[61px] border border-gray-800 justify-center items-center gap-2 inline-flex text-slate-950 text-base font-normal'
              )}
              onClick={onSubmit}
              isDisabled={!!amountError || !Number(amount) || !!error || disabledSubmitButton}
              data-cy={`${submit}-popup-btn`}
            >
              {submit}
            </Button>
          </>
        )}
      </div>
    </Modal>
  ) : (
    <DeploySuccess
      submitTo={successModalSubmitTo as string}
      labels={{
        text: successHeader,
        button: submitToButton,
      }}
      renderContent={renderContent}
      // renderContent={
      //   (fundRefAddress as undefined) && (
      //     <div className="text-center">
      //       <Input
      //         className="w-full rounded bg-neutral-50"
      //         isCopyOnly
      //         isDarkTheme
      //         amount={fundRefAddress}
      //         classNames={{
      //           input: 'max-w-[285px] truncate text-[16px]',
      //         }}
      //       />
      //     </div>
      //   )
      // }
      isOpen
      isModal
      isDarkTheme
      setIsOpen={setIsOpen}
    />
  );
}
