import { HTMLAttributes } from 'types';

export function WalletWithTimeIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="91"
      height="90"
      viewBox="0 0 91 90"
      fill="none"
      {...props}
    >
      <rect
        x="25"
        y="25"
        width="41"
        height="41"
        rx="20.5"
        fill="url(#paint0_linear_408_158741)"
        fillOpacity="0.7"
        filter="blur(25px)"
      />
      <g filter="url(#filter0_bi_408_158741)">
        <path
          d="M74.5 0H16.5C7.66345 0 0.5 7.16344 0.5 16V74C0.5 82.8366 7.66344 90 16.5 90H74.5C83.3366 90 90.5 82.8366 90.5 74V16C90.5 7.16345 83.3366 0 74.5 0Z"
          fill="white"
          fillOpacity="0.08"
        />
      </g>
      <path
        d="M59.59 48.4875C58.645 49.41 58.105 50.7375 58.24 52.155C58.4425 54.585 60.67 56.3625 63.1 56.3625H67.375V59.04C67.375 63.6975 63.5725 67.5 58.915 67.5H36.19C38.0575 65.8575 39.25 63.45 39.25 60.75C39.25 55.7775 35.2225 51.75 30.25 51.75C28.135 51.75 26.1775 52.4925 24.625 53.73V43.8975C24.625 39.24 28.4275 35.4375 33.085 35.4375H58.915C63.5725 35.4375 67.375 39.24 67.375 43.8975V47.1375H62.83C61.57 47.1375 60.4225 47.6325 59.59 48.4875Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.625 43.8974V35.6399C24.625 32.9624 26.2675 30.5773 28.765 29.6323L46.63 22.8823C49.42 21.8473 52.4125 23.8949 52.4125 26.8874V35.4374"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.7573 49.4328V54.068C69.7573 55.3055 68.7673 56.3179 67.5073 56.3629H63.0973C60.6673 56.3629 58.4398 54.5854 58.2373 52.1554C58.1023 50.7379 58.6423 49.4104 59.5873 48.4879C60.4198 47.6329 61.5673 47.1379 62.8273 47.1379H67.5073C68.7673 47.1829 69.7573 48.1953 69.7573 49.4328Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M34.75 45H50.5" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M39.25 60.75C39.25 63.45 38.0575 65.8575 36.19 67.5C34.5925 68.895 32.5225 69.75 30.25 69.75C25.2775 69.75 21.25 65.7225 21.25 60.75C21.25 57.915 22.555 55.3725 24.625 53.73C26.1775 52.4925 28.135 51.75 30.25 51.75C35.2225 51.75 39.25 55.7775 39.25 60.75Z"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.8125 57.9375V61.3125L28 63"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="filter0_bi_408_158741"
          x="-49.5"
          y="-50"
          width="190"
          height="190"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="25" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_408_158741" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_408_158741"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-3" />
          <feGaussianBlur stdDeviation="9.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_408_158741" />
        </filter>
        <linearGradient
          id="paint0_linear_408_158741"
          x1="45.5"
          y1="25"
          x2="45.5"
          y2="66"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B18F7E" />
          <stop offset="1" stopColor="#88296A" />
        </linearGradient>
      </defs>
    </svg>
  );
}
