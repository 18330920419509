import { ReactElement, useMemo } from 'react';

import { SearchLibraryCountQuery } from 'gql';
import { useTabs } from 'hooks';
import { useLocation } from 'react-router-dom';
import { Maybe, Tab as TabType } from 'types';
import { classes } from 'utils';

import { DataDesign } from './components/DataDesign/DataDesign';

interface useProfileResourcesProps {
  apiComponent?: React.ReactNode;
  fundedViewComponent?: React.ReactNode;
  countData?: Maybe<SearchLibraryCountQuery>;
  tab?: number;
  isCountDataLoading?: boolean;
  refreshCountHandler: () => void;
}

const useProfileResources = ({
  fundedViewComponent,
  countData,
  tab,
  isCountDataLoading,
  apiComponent,
  refreshCountHandler,
}: useProfileResourcesProps) => {
  // TODO : Need to uncomment for chart functionality
  // const { publisher } = usePublisher();
  // const { myProfile } = useMyProfile();
  const location = useLocation();

  const isPrivate = useMemo(() => location.pathname.includes('profile'), [location]);
  // const userId = useMemo(() => {
  //   let id = -1;
  //   if (isPrivate && myProfile?.id) {
  //     id = myProfile?.id;
  //   } else if (publisher?.id) {
  //     id = publisher?.id;
  //   }
  //   return id;
  // }, [myProfile?.id, publisher?.id, isPrivate]);

  // commented out telemetry of private/public profile #582

  // const [getStats, { loading: isStatsLoading, data: stats }] = useUserStatsLazyQuery();

  // const [getGraph, { loading: isGraphLoading, data: graphData }] = useUserGraphLazyQuery();

  // const fetchStats = useCallback(() => {
  //   return getStats({
  //     variables: { userId },
  //   });
  // }, [userId]);

  // const fetchChart = useCallback(
  //   (graphRange: RangeType) => {
  //     return getGraph({ variables: { userId, range: graphRange } });
  //   },
  //   [userId]
  // );

  // const chartSummary = useMemo(() => {
  //   if (!isStatsLoading) {
  //     return [
  //       {
  //         value: stats?.UserStats?.totalUserQuery,
  //         name: 'Queries Total',
  //         color: '#FFAD97',
  //       },
  //       {
  //         value: stats?.UserStats.totalUserQueryFee,
  //         name: 'Queries Fees Total',
  //         color: '#F539B5',
  //       },
  //       {
  //         value:
  //           +(stats?.UserStats.totalUserQueryFee || 0) / (stats?.UserStats.totalUserQuery || 1),
  //         name: 'Average Query Cost',
  //         color: '#7CA1F6',
  //       },
  //     ];
  //   }
  // }, [stats, isStatsLoading]);

  // useEffect(() => {
  //   fetchStats();
  // }, [fetchStats]);

  const tabArray: TabType[] = useMemo(() => {
    const array: TabType[] = [
      {
        child: (
          <DataDesign
            isPrivate={isPrivate}
            isLoading={isCountDataLoading}
            countData={countData}
            refreshCountHandler={refreshCountHandler}
          />
        ),
        label: 'Data Design',
        value: 'dataDesign',
      },
    ];

    if (apiComponent) {
      array.push({
        child: apiComponent as ReactElement,
        label: 'API Keys',
        value: 'APIKeys',
      });
    }

    // array.push({
    //   child: (
    //     <div className="flex flex-col gap-5">
    //       <p className="text-[#B2B3B8] text-base leading-[22px]">
    //         This liveboard compiles essential metrics for the published View, such as overall query
    //         count, total query fees, and average query cost per query.
    //       </p>
    //       <ChartComponent
    //         summary={chartSummary}
    //         fetchChart={fetchChart}
    //         isLoading={isGraphLoading || isStatsLoading}
    //         chartData={graphData?.UserGraph}
    //       />
    //     </div>
    //   ),
    //   label: 'Stats',
    //   value: 'stats',
    //   alternateLabel: 'Query stats',
    // });

    if (fundedViewComponent) {
      array.push({
        child: fundedViewComponent as ReactElement,
        label: 'Funded Views',
        value: 'fundedViews',
      });
    }

    return array;
  }, [
    apiComponent,
    // chartSummary,
    // graphData,
    // isStatsLoading,
    // isGraphLoading,
    fundedViewComponent,
    countData,
    isPrivate,
  ]);

  const { selectedTab, setSelectedTab, tabPanels, tabSelector, tabs } = useTabs(tabArray, tab, {
    classNames: {
      base: classes(
        'w-120 p-1 rounded-[25px] text-[#F7F8F8] text-[14px] border border-[#ffffff33]'
      ),
      tab: (selected) =>
        classes(
          'whitespace-pre py-[7px] sm:px-[16px] flex-1 text-[14px]',
          selected && 'bg-[#1B1B1B] text-[#F7F8F8] rounded-[32px] px-4 backdrop-blur-md',
          tabArray.length === 3 && 'px-[10px]'
        ),
    },
  });
  return { selectedTab, setSelectedTab, tabSelector, tabs, tabPanels };
};

export default useProfileResources;
