import { CopyButton, Publisher, Statistics, TimeAgo } from 'components';
import { useSmartContract } from 'contexts';
import { useWindowSize } from 'hooks';
import { truncate } from 'utils';

const ScStatistics = () => {
  const {
    windowSize: { width },
  } = useWindowSize();

  const { resource: smartContract } = useSmartContract();
  const address = smartContract?.address;

  return (
    <Statistics
      value={[
        {
          label: 'Publisher',
          value: smartContract && (
            <Publisher
              isTruncated={width < 1300}
              withLink
              value={smartContract.publisherAddress as string}
              className="sm:text-xl text-[18px] !text-white hover:underline"
            />
          ),
        },
        {
          label: 'Contract address',
          value: smartContract && (
            <div
              data-cy={`${address}`}
              className="flex items-center md:text-base leading-none sm:text-xl text-[18px] !text-white"
            >
              {truncate(address as string, width > 639 && width < 768 ? 6 : 17)}
              <CopyButton className="ml-2" value={address} />
            </div>
          ),
        },
        {
          label: 'Date Created',
          value: smartContract && (
            <TimeAgo date={smartContract.createAt} className="sm:text-xl text-[18px] !text-white" />
          ),
        },
      ]}
      withBorder={width >= 640 ? true : false}
      isDarkTheme
      classNames={{
        base: '!pt-3 sm:!pt-3 border-t border-[#1F1F1F] sm:mt-6 mt-4 sm:flex-row flex-col sm:h-20 h-auto sm:items-center items-start',
        strings: 'flex-col-reverse gap-1 sm:gap-2',
        label: '!text-sm !text-[#B2B3B8] capitalize',
        separator: 'h-[61px]',
        stat: 'sm:mr-auto m-0 break-all',
      }}
    />
  );
};

export default ScStatistics;
