import { HTMLAttributes } from 'types';

export function TwitterLogo(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_1791_546)">
        <path
          d="M18.0231 4.09859C17.3579 4.39391 16.645 4.59219 15.8954 4.68219C16.6604 4.22375 17.2482 3.49812 17.5239 2.63469C16.8095 3.05797 16.015 3.36594 15.1726 3.53187C14.4976 2.81187 13.5357 2.36328 12.4712 2.36328C10.4265 2.36328 8.76855 4.01984 8.76855 6.06453C8.76855 6.35422 8.80231 6.63687 8.86559 6.90828C5.78871 6.75359 3.06199 5.27984 1.23527 3.03969C0.916055 3.58812 0.734649 4.22375 0.734649 4.90156C0.734649 6.18547 1.38715 7.31891 2.38137 7.98406C1.77387 7.96437 1.20293 7.79703 0.703711 7.52C0.703711 7.53547 0.703711 7.55094 0.703711 7.56641C0.703711 9.36078 1.98059 10.8556 3.6723 11.1973C3.36293 11.2817 3.03527 11.3267 2.69777 11.3267C2.45871 11.3267 2.22668 11.3042 2.00027 11.2592C2.47277 12.7287 3.83965 13.8017 5.45824 13.8312C4.19262 14.8241 2.59652 15.4147 0.861211 15.4147C0.56168 15.4147 0.267774 15.3978 -0.0205078 15.3627C1.61637 16.4145 3.56262 17.0262 5.65371 17.0262C12.4628 17.0262 16.1865 11.3858 16.1865 6.49484C16.1865 6.33453 16.1823 6.17281 16.1767 6.01531C16.8995 5.49359 17.5267 4.84109 18.0231 4.09859Z"
          fill="url(#paint0_radial_1791_546)"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_1791_546"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(7.718 4.11423) rotate(90.5388) scale(16.3478 20.1163)"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.2" />
        </radialGradient>
        <clipPath id="clip0_1791_546">
          <rect width="18" height="18" fill="white" transform="translate(0 0.341797)" />
        </clipPath>
      </defs>
    </svg>
  );
}
