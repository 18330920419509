import { Skeleton } from 'components';
import { HTMLAttributes } from 'types';
import { classes } from 'utils';

import useSpecControl from './useSpecControl';

export const SpecControl = (props: HTMLAttributes<HTMLDivElement>) => {
  const { functionSpecs, content } = useSpecControl();

  return (
    <div
      className={classes(
        'relative lg:pb-6 pb-4 flex flex-1 shrink-0 flex-col overflow-hidden rounded-2xl h-full min-h-[calc(100vh_-_310px)] lg:max-h-[unset] max-h-[calc(100vh_-_275px)] z-10',
        !functionSpecs && 'h-[calc(100vh_-_310px)]'
      )}
      {...props}
    >
      <Skeleton.Loader isDarkTheme className="w-[90px] h-[22px] mt-4 ml-5 !rounded-full">
        <div className="flex flex-row justify-between !pb-0 lg:p-6 p-4 lg:pt-4">
          <h5 className="lg:flex hidden">Your Query</h5>
        </div>
      </Skeleton.Loader>
      {content}
    </div>
  );
};
