import { HTMLAttributes } from 'types';

export function DisconnectIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" {...props}>
      <path
        d="M9.1875 6.19336V4.00586C9.1875 3.65776 9.04922 3.32392 8.80308 3.07778C8.55694 2.83164 8.2231 2.69336 7.875 2.69336H4.375C4.0269 2.69336 3.69306 2.83164 3.44692 3.07778C3.20078 3.32392 3.0625 3.65776 3.0625 4.00586V11.8809C3.0625 12.229 3.20078 12.5628 3.44692 12.8089C3.69306 13.0551 4.0269 13.1934 4.375 13.1934H7.875C8.2231 13.1934 8.55694 13.0551 8.80308 12.8089C9.04922 12.5628 9.1875 12.229 9.1875 11.8809V9.69336M7 6.19336L5.25 7.94336M5.25 7.94336L7 9.69336M5.25 7.94336H12.6875"
        stroke="#DD0050"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
