/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useRef, useState } from 'react';

import BigNumber from 'bignumber.js';
import BN from 'bn.js';
import { Skeleton } from 'components';
import { useApi, useMyProfile } from 'contexts';
import { useBalance } from 'hooks/useBalance';
import { InjectedAccountWithMeta } from 'types';
import { depositAmount, formatBalance, getTransactionGasCost } from 'utils';

const useDepositFund = () => {
  // State variables
  const [amount, setAmount] = useState<number | string>('');
  const [fundRefAddress, setFundRefAddress] = useState<string | null | undefined>(null);
  const [error, setError] = useState<string | undefined>('');
  const [amountError, setAmountError] = useState('');
  const [gasFee, setGasFee] = useState<number>(0);
  const [isGasFeeLoading, setIsGasFeeLoading] = useState<boolean>(false);
  const [depositStatus, setDepositStatus] = useState('');
  const [depositHash, setDepositHash] = useState('');
  const [isDepositLoading, setDepositLoading] = useState(false);

  // Ref for debounce mechanism
  const debounceRef = useRef<number>();

  // For API and user profile
  const {
    api,
    account,
    chainProps: { tokenDecimals },
  } = useApi();
  const { refetchAccountBalance } = useMyProfile();

  // Get the wallet balance
  const walletBalance = useBalance(account?.address);

  // Formatted gas fee calculation
  const formattedGasFee = useMemo(() => {
    return Number(
      formatBalance(new BigNumber(gasFee), {
        symbol: '',
        decimalPlaces: 5,
        tokenDecimals,
        roundingMode: 0,
      })
    );
  }, [gasFee, tokenDecimals]);

  // Calculate creditable amount after deducting gas fee
  const creditableAmount = useMemo(() => {
    return (Number(amount) - formattedGasFee).toFixed(5);
  }, [amount, formattedGasFee]);

  // Validate the amount and reset errors
  useEffect(() => {
    const isExceeds = new BigNumber(amount || 0)
      .multipliedBy(10 ** tokenDecimals)
      .isGreaterThan(walletBalance || 0);

    if (walletBalance?.isLessThanOrEqualTo(0)) {
      setAmountError('Insufficient balance');
    } else if (isExceeds) {
      setAmountError('Amount exceeds your wallet balance');
    } else if (Number(amount) && gasFee && Number(amount) - formattedGasFee <= 0) {
      setAmountError('Amount cannot be credited as it is less than the required gas fee.');
    } else {
      setAmountError('');
    }
    if (error) {
      setError('');
    }
    if (fundRefAddress) {
      setFundRefAddress(null);
    }
  }, [amount, tokenDecimals, walletBalance, gasFee, formattedGasFee]);

  // Fetch gas fee
  const getGasFee = async () => {
    if (Number(amount) && !amountError) {
      try {
        const value = new BigNumber(amount).multipliedBy(10 ** tokenDecimals).toNumber();
        const BN_HUNDRED = new BN('100');
        const partialFee = await getTransactionGasCost(
          api,
          account as InjectedAccountWithMeta,
          value
        );
        const adjFee = partialFee.muln(110).div(BN_HUNDRED);
        setGasFee(adjFee?.toNumber());
        setIsGasFeeLoading(false);
        return adjFee;
      } catch (e) {
        setIsGasFeeLoading(false);
        setAmountError('Something went wrong. Try again');
      }
    }
    return 0;
  };

  // Debounced effect to fetch gas fee when amount changes
  useEffect(() => {
    setIsGasFeeLoading(true);
    clearTimeout(debounceRef.current);
    debounceRef.current = setTimeout(getGasFee, 800) as unknown as number;
  }, [amount, tokenDecimals, amountError]);

  // Handle deposit submission
  const handleSubmit = () => {
    setDepositLoading(true);
    depositAmount(
      api,
      account as InjectedAccountWithMeta,
      Number(creditableAmount),
      tokenDecimals,
      (status, hash) => {
        if (status === 'Error') {
          setDepositStatus(status);
          setDepositLoading(false);
          return;
        }
        if (status === 'isInBlock') {
          setDepositStatus(status);
          setDepositLoading(true);
          setDepositHash(hash);
        }
        if (status === 'isFinalized') {
          setDepositStatus((prev) => (prev === 'Error' ? 'Error' : status));
          setFundRefAddress(hash);
          setDepositLoading(false);
          refetchAccountBalance();
          // msg hash + explorer url
        }
      },
      setDepositLoading
    );
  };

  // Memoized summary component
  const Summary = useMemo(
    () => (
      <>
        <div className="border-[#1f1f1f] border-[1px]"></div>
        <div className="text-xs font-normal leading-[16px] uppercase text-[rgba(255,255,255,0.40)]">
          Summary
        </div>
        <div className="flex flex-col gap-3 text-sm font-normal leading-[20px] text-[#B2B3B8]">
          <div className="flex justify-between">
            <span>Fees</span>
            <span
              data-cy={`${formattedGasFee}`}
              className="flex text-[#fff] text-sm font-normal leading-[20px] gap-2"
            >
              <Skeleton.Loader className="w-8" isDarkTheme isLoading={isGasFeeLoading}>
                {formattedGasFee}
              </Skeleton.Loader>
              <span className="text-[#808080]">ANL</span>
            </span>
          </div>
          <div className="flex justify-between">
            <span>Total Creditable Amount</span>
            <span
              data-cy={`${creditableAmount}`}
              className="flex gap-2 text-[#fff] text-sm font-normal leading-[20px]"
            >
              <Skeleton.Loader className="w-8" isDarkTheme isLoading={isGasFeeLoading}>
                {Number(creditableAmount) > 0 ? creditableAmount : 0}
              </Skeleton.Loader>
              <span className="text-[#808080]"> ANL</span>
            </span>
          </div>
        </div>
      </>
    ),
    [creditableAmount, formattedGasFee, isGasFeeLoading]
  );

  return {
    handleSubmit,
    setAmount,
    amount,
    Summary,
    amountError,
    fundRefAddress,
    error,
    depositStatus,
    depositHash,
    setDepositHash,
    isDepositLoading,
    isGasFeeLoading,
  };
};

export default useDepositFund;
