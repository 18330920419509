export function truncate(value: string | undefined, sideLength = 6): string {
  return value
    ? value.length > sideLength * 2
      ? `${value.substring(0, sideLength)}...${value.substring(value.length - sideLength)}`
      : value
    : '';
}

export function countLines(value: string): number {
  try {
    return value.split('\n').length;
  } catch (e) {
    return 0;
  }
}

/**
 * Converts an array of strings into a URL-safe string.
 * This is useful for passing arrays as query parameters in URLs.
 *
 * @param {string[]} array - The array of strings to be converted.
 * @returns {string} - The URL-safe string representation of the array.
 */
export function arrayToUrlString(array: string[]): string {
  return encodeURIComponent(array.join(','));
}

/**
 * Converts a URL-safe string back into an array of strings.
 * This reverses the process done by `arrayToUrlString`.
 *
 * @param {string} urlString - The URL-safe string to be converted back into an array.
 * @returns {string[]} - The array of strings parsed from the URL-safe string.
 */
export function urlStringToArray(urlString: string): string[] {
  return decodeURIComponent(urlString).split(',');
}

