import { HTMLAttributes } from 'types';

export function NoAPI(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="123"
      height="123"
      viewBox="0 0 123 123"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="42"
        y="36"
        width="41"
        height="41"
        rx="20.5"
        fill="url(#paint0_linear_1834_105966)"
        fillOpacity="0.7"
        filter="blur(25px)"
      />
      <g filter="url(#filter0_bi_1834_105966)">
        <path
          d="M100.5 7H24.5C15.6634 7 8.5 14.1634 8.5 23V99C8.5 107.837 15.6634 115 24.5 115H100.5C109.337 115 116.5 107.837 116.5 99V23C116.5 14.1634 109.337 7 100.5 7Z"
          fill="white"
          fillOpacity="0.08"
        />
      </g>
      <path
        d="M54.3252 37L54.4002 40.8251C54.4502 42.8501 56.1252 44.5 58.1502 44.5H65.7002C67.7752 44.5 69.4502 42.8 69.4502 40.75V37"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M74.5 74.5L69.5 79.5L74.5 84.5"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M82 74.5L87 79.5L82 84.5"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.5 87H52C43.25 87 39.5 82 39.5 74.5V49.5C39.5 42 43.25 37 52 37H72C80.75 37 84.5 42 84.5 49.5V67"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62 72C67.5 72 72 67.5 72 62C72 56.5 67.5 52 62 52C56.5 52 52 56.5 52 62C52 67.5 56.5 72 62 72Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M59.5 64.5L64.5 59.5" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M64.5 64.5L59.5 59.5" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
      <defs>
        <filter
          id="filter0_bi_1834_105966"
          x="-41.5"
          y="-43"
          width="208"
          height="208"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="25" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_1834_105966"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_1834_105966"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-3" />
          <feGaussianBlur stdDeviation="9.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_1834_105966" />
        </filter>
        <linearGradient
          id="paint0_linear_1834_105966"
          x1="62.5"
          y1="36"
          x2="62.5"
          y2="77"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B18F7E" />
          <stop offset="1" stopColor="#88296A" />
        </linearGradient>
      </defs>
    </svg>
  );
}
