import { useApi } from 'contexts';
import { InjectedAccountWithMeta } from 'types';
import { classes, truncate } from 'utils';

import { Identicon } from '../../Identicon';
import { CustomExcalmationMark } from '../../svg';

const SelectedWalletAccounts = ({
  walletOption,
  onSubmit,
}: {
  walletOption?: InjectedAccountWithMeta[] | null;
  onSubmit: (selected: InjectedAccountWithMeta) => void;
}) => {
  const { account } = useApi();
  return (
    <div className="max-h-96 overflow-auto no-scrollbar">
      {walletOption?.length ? (
        walletOption?.map((option) => {
          const { address, meta } = option;
          return (
            <button
              key={address}
              className={classes(
                ' mb-2 flex max-w-full items-center overflow-hidden text-ellipsis px-4 py-2 rounded-2xl border border-[#ffffff1f] hover:bg-[#ffffff0d] hover:border-[#ffffff0d] last:mb-0 focus:border-white',
                account?.address === address && 'bg-[#ffffff0d] cursor-not-allowed'
              )}
              onClick={() => onSubmit(option)}
              disabled={account?.address === address}
            >
              <Identicon className="mr-3 h-8 w-8" value={address} />
              <div className="flex-1 overflow-hidden">
                <div className="text-lg font-medium">{meta.name}</div>
                <div className="w-full max-w-full overflow-hidden whitespace-nowrap font-mono text-sm text-neutral-500">
                  {truncate(address, 17)}
                </div>
              </div>
            </button>
          );
        })
      ) : (
        <div className="flex flex-col items-center justify-center text-center">
          <CustomExcalmationMark />
          <span
            data-cy="You don't have any connected accounts for this wallet."
            className="text-2xl mt-[31px]"
          >
            {"You don't have any connected accounts for this wallet."}
          </span>
          <span className="text-[#999999] mt-3">
            Add an account or try another Polkadot (Substrate) wallet.
          </span>
        </div>
      )}
    </div>
  );
};

export default SelectedWalletAccounts;
