import { HTMLAttributes } from 'types';

export function BrokenExclamation(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path d="M10 6.66675V10.8334" stroke="#FF6666" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M3.33366 5.00008C2.29199 6.39175 1.66699 8.12508 1.66699 10.0001C1.66699 14.6001 5.40033 18.3334 10.0003 18.3334C14.6003 18.3334 18.3337 14.6001 18.3337 10.0001C18.3337 5.40008 14.6003 1.66675 10.0003 1.66675C8.80866 1.66675 7.66699 1.91675 6.64199 2.37508"
        stroke="#FF6666"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99512 13.3333H10.0026"
        stroke="#FF6666"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
