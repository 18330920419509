import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { Tooltip } from '@mui/material';
import { Button, CheckBox, Input, PlusGradientIcon, QuestionIcon, UsecaseIcon } from 'components';
import { Setter } from 'types';
import { classes } from 'utils';

const CreateNewView = ({
  searchString,
  setSearchString,
  useCases,
  handleSelect,
  setStep,
  formError,
  submitForGame,
  setSubmitForGame,
  filteredUsecases,
}: {
  searchString: string;
  setSearchString: Setter<string>;
  useCases: (string | undefined)[];
  handleSelect: (name: string) => void;
  setStep: Setter<number>;
  formError: any;
  submitForGame: boolean;
  setSubmitForGame: Setter<boolean>;
  filteredUsecases: any;
}) => {
  return (
    <div className="py-6 sm:px-6 px-4 border border-x-0 border-solid border-[#1C1C1C]">
      <div data-cy="add-use-cases-popup-label" className="flex text-xl flex-row items-center gap-2">
        Add Use Cases for this View
      </div>
      <div className={'flex flex-col mt-4 gap-2'}>
        <p className="text-xs text-[#808080]">{`Selected: ${useCases.length} / 10`}</p>
        <Input
          dataCy="input-search-use-case-sc"
          className="relative px-5"
          classNames={{ input: 'dark' }}
          isDarkTheme
          isClearable
          placeholder="Search names"
          value={searchString}
          onChange={(e) => {
            const filteredValue = e.replace(/[^a-zA-Z0-9_\s]/g, ''); // Allow only letters, numbers, spaces, and underscores
            setSearchString(filteredValue);
          }}
        >
          <MagnifyingGlassIcon className="absolute left-2 top-2 h-5 w-5 text-neutral-400" />
        </Input>
        <div className="flex flex-col gap-2 max-h-[180px] overflow-y-auto scrollbar-white">
          {filteredUsecases.map((option: any, index: number) => {
            return (
              <button
                tabIndex={-1}
                key={`usecase-option-${index}`}
                className={classes(
                  'flex items-center border border-solid border-[#1F1F1F] rounded-2xl p-[14px_16px] gap-3'
                )}
                onClick={(e: React.MouseEvent) => {
                  e.preventDefault();
                  option && handleSelect(option);
                }}
              >
                <CheckBox
                  isChecked={useCases.includes(option)}
                  isDarkTheme
                  className="rounded-sm"
                />
                <div data-cy={`${option}`} className="w-full flex gap-2 items-center">
                  <UsecaseIcon className="h-6 w-6 shrink-0" color="grey" />
                  <div className="break-words text-sm text-white break-all">{option}</div>
                </div>
              </button>
            );
          })}
        </div>
        <Button
          variant="plain"
          classNames={{
            base: 'h-[53px] border border-solid border-[#1F1F1F] rounded-2xl p-[14px] focus:border-white focus:border',
            container: 'flex gap-1 justify-start',
          }}
          onClick={(e: React.MouseEvent) => {
            e.preventDefault();
            setStep(3);
          }}
        >
          <PlusGradientIcon className="h-6 w-6" />
          <div className="text-sm bg-[linear-gradient(0deg,_#8D74F7_3%,_#D285F7_45%,_#FFAD97_95%)] bg-clip-text text-transparent">
            Add new Use cases
          </div>
        </Button>
        {formError.useCase.isError && formError.useCase.isTouched && (
          <div
            data-cy="please-include-use-case-error-message"
            className="text-[#FF6666] text-xs mt-2"
          >
            Please include at least one Use case.
          </div>
        )}
        <div className="flex items-center text-xs mt-[18px] gap-2">
          <CheckBox
            tabIndex={0}
            isChecked={submitForGame}
            isDarkTheme
            className="rounded-sm focus:border focus:border-white"
            onClick={() => setSubmitForGame((prev) => !prev)}
          />
          <div className="text-sm">
            Submit for{' '}
            <a
              target="_blank"
              className="bg-[linear-gradient(0deg,_#8D74F7_3%,_#D285F7_45%,_#FFAD97_95%)] bg-clip-text text-transparent cursor-pointer"
              href="https://testnet.analog.one/"
            >
              Watch Game
            </a>{' '}
            shortlisting
          </div>{' '}
          <Tooltip
            placement="top"
            classes={{ tooltip: '!max-w-[360px]' }}
            title={
              <div className="text-sm  text-center text-[#F7F8F8] font-normal">
                Checking this box means you're submitting your View for review to be shortlisted in
                a Voting Session on the Watch Game. If your View meets the guidelines, you'll stand
                the chance of earning rewards. Read more about our incentivized testnet here.
              </div>
            }
            arrow
          >
            <div>
              <QuestionIcon />
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default CreateNewView;
