import { useContext } from 'react';

import { SingleValueProps, components } from 'react-select';
import { SelectOption } from 'types';

import { CustomizeContext } from '../Select';

export function SingleValue<T>(props: SingleValueProps<SelectOption<T>, false>) {
  const { label } = useContext(CustomizeContext);
  return <components.SingleValue {...props}>{label || props.children}</components.SingleValue>;
}
