import { useCallback, useEffect, useMemo, useState } from 'react';

import { useSuggestionsLazyQuery } from 'gql';
import debounce from 'utils/debounce';

const useSearchLibrary = () => {
  const [searchString, setSearchString] = useState<string | null>(null);
  const [getSuggestions, { data: suggestionsQuery }] = useSuggestionsLazyQuery({
    variables: { string: searchString as string },
  });

  const suggestions = useMemo(() => suggestionsQuery?.suggestions, [suggestionsQuery?.suggestions]);

  const memoizedGetSuggestions = useCallback(
    (searchString: string) => {
      getSuggestions({
        variables: {
          string: searchString,
        },
      });
    },
    [getSuggestions]
  );
  const debouncedGetSuggestions = useMemo(
    () =>
      debounce((searchString) => {
        memoizedGetSuggestions(searchString);
      }, 600),
    [memoizedGetSuggestions]
  );

  const handleSearch = useCallback(
    (searchString: string) => {
      debouncedGetSuggestions(searchString);
    },
    [debouncedGetSuggestions]
  );
  useEffect(() => {
    if (searchString !== null && searchString.length >= 3) handleSearch(searchString);
  }, [searchString, handleSearch]);
  return { suggestions, setSearchString, searchString };
};

export default useSearchLibrary;
