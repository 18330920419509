import { useEffect, useMemo, useState } from 'react';

import { QueryResult } from '@analog-labs/timegraph-js';
import { Cycle, QueryDataTable, Skeleton } from 'components';
import { useView } from 'contexts';
import { useWindowSize } from 'hooks';
import { usePageFilter } from 'hooks/usePageFilter';

export const useDataCollections = () => {
  const { isMobile } = useWindowSize();
  const { isRecentDataLoading, recentData, refreshRecentData, isResourceLoading } = useView();
  const [refreshed, setRefreshed] = useState(1);
  const { controller, currentPage } = usePageFilter(
    (recentData as unknown as QueryResult | undefined)?.data
    // status === 'Active' ? 3 : 6
  );

  const newData: QueryResult | undefined = useMemo(() => {
    if (recentData)
      return {
        ...(recentData as unknown as QueryResult),
        header: (recentData as unknown as QueryResult).header.map((el) => {
          if (el === '_clock' || el === '_index') {
            return el.replace('_', '');
          }
          return el;
        }),
      };

    return undefined;
  }, [recentData]);

  const tableContent = useMemo(() => {
    if (isMobile) {
      return (
        <div className="flex flex-col gap-4">
          {currentPage?.map((row, index) => {
            return (
              <div
                key={index}
                className="flex flex-col text-sm text-white bg-[#0E0E0E] rounded-2xl p-4"
              >
                <div className="flex flex-row items-center gap-2">
                  <Skeleton.Loader isDarkTheme className="w-8 h-8 !rounded-[5px]">
                    {typeof row[0] == 'string' && <Cycle />}
                  </Skeleton.Loader>
                  <Skeleton.Loader isDarkTheme className="sm:h-[15px] h-6 w-[180px]">
                    {row[0]}
                  </Skeleton.Loader>
                </div>
                <div className="mt-4 mb-3 bg-[#1F1F1F] h-[1px]" />
                <div className="flex md:flex-row flex-col justify-between">
                  {row?.map((value, index) => {
                    const headerItem = (recentData as unknown as QueryResult)?.header[index];
                    if (index == 0) return;
                    return (
                      <div key={index} className="flex flex-1 gap-[6px]">
                        <Skeleton.Loader
                          isDarkTheme
                          containerClassName="w-full"
                          className="h-[24px] w-[90%]"
                        >
                          <span className="text-[#B2B3B8] capitalize">
                            {headerItem === '_clock' || headerItem === '_index'
                              ? headerItem.replace('_', '')
                              : headerItem}
                            :
                          </span>
                          <span className="truncate">{value}</span>
                        </Skeleton.Loader>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
          {controller}
        </div>
      );
    } else {
      return (
        <QueryDataTable
          isLoading={isRecentDataLoading}
          rowIcon={<Cycle />}
          recentData={newData}
          // defaultPageSize={status === 'Active' ? 3 : 6}
          classNames={{
            base: 'scrollbar-white',
            table: 'border-spacing-y-2',
            row: 'border-none bg-[#0E0E0E]',
            rowItem:
              'text-sm border-none align-middle p-[14px] pl-[16px] first:rounded-l-2xl last:rounded-r-2xl',
            headItem: 'text-xs text-[#B2B3B8]',
          }}
          // showPagination={status === 'Active'}
        />
      );
    }
  }, [isMobile, currentPage, controller, isRecentDataLoading, newData, status]);

  useEffect(() => {
    const refreshedInterval = setInterval(() => {
      setRefreshed((prev) => prev + 1);
    }, 1000);
    return () => clearInterval(refreshedInterval);
  }, []);

  return {
    isRecentDataLoading,
    isResourceLoading,
    refreshed,
    setRefreshed,
    refreshRecentData,
    tableContent,
    recentData,
  };
};

export default useDataCollections;
