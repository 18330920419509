import { Dispatch, SetStateAction } from 'react';

import Tooltip from '@mui/material/Tooltip';
// eslint-disable-next-line import/order
import { Description, Skeleton } from 'components';
import { ChainChip } from 'components/ChainChip';
import { useView } from 'contexts';
import { TimegraphTag } from 'gql';
import { useWindowSize } from 'hooks';
import { Maybe } from 'types';
import { arrayOfSize } from 'utils';

const Summary = ({
  sortedTags,
  tooltipIsOpen,
  setTooltipIsOpen,
  handleKeyDown,
}: {
  sortedTags: any;
  tooltipIsOpen: boolean;
  setTooltipIsOpen: Dispatch<SetStateAction<boolean>>;
  handleKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => void;
}) => {
  const { resource: view, isResourceLoading } = useView();
  const {
    windowSize: { width },
  } = useWindowSize();

  return (
    <div className="flex w-full flex-col items-start space-y-2 text-white break-all">
      <h2 data-cy={`${view?.name}`} className="hidden sm:flex text-white text-lg sm:text-2xl">
        <Skeleton.Loader isDarkTheme className="sm:w-60 w-[120px]">
          {view?.name}
        </Skeleton.Loader>
      </h2>
      <div className="w-full text-sm md:text-base text-[#B2B3B8] sm:!mt-[8px] !mt-0 flex">
        <Skeleton.Loader isDarkTheme className="sm:w-60 w-[200px]">
          <Description value={view?.description} />
        </Skeleton.Loader>
      </div>
      <div className="flex-wrap items-center text-[0.66rem] text-sm sm:!mt-4 !mt-3 flex gap-2">
        {/* Display loader with 3 chips if loading else show first 5 chips */}
        {(isResourceLoading ? arrayOfSize(3).map(() => undefined) : sortedTags?.slice(0, 5))?.map(
          (tag: Maybe<TimegraphTag> | undefined, index: number) => {
            return <ChainChip tag={tag} key={index} />;
          }
        )}
        {/* Display extra chips inside see more tooltip */}
        {!!sortedTags?.length && sortedTags?.length - 5 > 0 && (
          <Tooltip
            placement="bottom"
            classes={{ tooltip: '!max-w-[420px]' }}
            open={tooltipIsOpen}
            onOpen={() => setTooltipIsOpen(true)}
            onClose={() => setTooltipIsOpen(false)}
            title={
              <div className="flex flex-wrap space-x-1">
                {sortedTags
                  ?.slice(5)
                  ?.map((tag: Maybe<TimegraphTag> | undefined, index: number) => {
                    return <ChainChip tag={tag} key={index} />;
                  })}
              </div>
            }
            arrow
          >
            <div
              className="mb-2 bg-[linear-gradient(0deg,_#8D74F7_3%,_#D285F7_45%,_#FFAD97_95%)] bg-clip-text text-transparent cursor-pointer select-none"
              {...(width < 769 && {
                onClick: () => setTooltipIsOpen(!tooltipIsOpen),
                onKeyDown: handleKeyDown,
                role: 'button',
                tabIndex: 0,
              })}
            >
              <Skeleton.Loader isDarkTheme>See More</Skeleton.Loader>
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default Summary;
