import { Tab } from '@headlessui/react';
import { Header, Skeleton } from 'components';
import reactStringReplace from 'react-string-replace';

import useViewTab from './useViewDetailsTab';

const ViewTab = () => {
  const { selectedTab, setSelectedTab, tabPanels, tabSelector, tabs } = useViewTab();
  return (
    <Tab.Group selectedIndex={selectedTab} onChange={setSelectedTab}>
      <Header
        accessory={
          <Skeleton.Loader isDarkTheme className="sm:w-[221px] w-full h-10 !rounded-full ">
            {tabSelector}
          </Skeleton.Loader>
        }
        className="text-white sm:flex-row flex-col-reverse sm:items-center items-start gap-4 sm:mb-6 mb-3 "
        hClassName="sm:text-[32px] text-[20px]"
      >
        {tabs[selectedTab].alternateLabel ||
          reactStringReplace(tabs[selectedTab].label as string, /(\(\d+\))$/, (match) => (
            <span key="parens" className="font-normal text-neutral-500">
              {match}
            </span>
          ))}
      </Header>
      {tabPanels}
    </Tab.Group>
  );
};
export default ViewTab;
