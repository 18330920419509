import { HTMLAttributes } from 'types';

export function NoRecentData(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="31.0186"
        y="26.8516"
        width="37.963"
        height="37.963"
        rx="18.9815"
        fill="url(#paint0_linear_3532_210482)"
        fillOpacity="0.7"
        filter="blur(25px)"
      />
      <g filter="url(#filter0_bi_3532_210482)">
        <path
          d="M84 0H16C7.16344 0 0 7.16345 0 16V84C0 92.8366 7.16345 100 16 100H84C92.8366 100 100 92.8366 100 84V16C100 7.16344 92.8366 0 84 0Z"
          fill="white"
          fillOpacity="0.08"
        />
      </g>
      <path
        d="M70.1765 44.1176C70.1765 48.1786 60.9588 51.4706 49.5882 51.4706C38.2177 51.4706 29 48.1786 29 44.1176M70.1765 32.3529C70.1765 28.292 60.9588 25 49.5882 25C38.2177 25 29 28.292 29 32.3529V67.6471C29 71.708 38.2177 75 49.5882 75C60.9588 75 70.1765 71.708 70.1765 67.6471V32.3529Z"
        stroke="white"
      />
      <path
        d="M70.1765 32.3516C70.1765 36.4125 60.9588 39.7045 49.5882 39.7045C38.2177 39.7045 29 36.4125 29 32.3516M70.1765 55.881C70.1765 59.9419 60.9588 63.2339 49.5882 63.2339C38.2177 63.2339 29 59.9419 29 55.881"
        stroke="white"
      />
      <defs>
        <filter
          id="filter0_bi_3532_210482"
          x="-50"
          y="-50"
          width="200"
          height="200"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="25" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_3532_210482"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_3532_210482"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-3" />
          <feGaussianBlur stdDeviation="9.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_3532_210482" />
        </filter>
        <linearGradient
          id="paint0_linear_3532_210482"
          x1="50"
          y1="26.8516"
          x2="50"
          y2="64.8145"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B18F7E" />
          <stop offset="1" stopColor="#88296A" />
        </linearGradient>
      </defs>
    </svg>
  );
}
