import { Button, NoDataDesign, ResourceList } from 'components';
import { Link } from 'react-router-dom';
import { routes } from 'routes/routesConst';

import useViewList from './useViewList';

const ViewList = () => {
  const { viewList, isLoading, controls } = useViewList();
  return (
    <>
      <ResourceList
        value={viewList as []}
        isLoading={isLoading}
        isEmptyView={
          <div className="w-full py-[81px] justify-center items-center flex flex-col text-center text-white border border-solid rounded-[20px] border-[#1F1F1F] bg-gradient-to-b from-[#000_20.37%] to-[#000_79.34%]">
            <NoDataDesign className="mb-[18px]" />
            <div className="flex gap-2 flex-col mb-4">
              <span className="">There are no Views created with this Smart Contract yet.</span>
              <span>Build a view to get started</span>
            </div>

            <Link to={routes.viewBuilder}>
              <Button variant="darkThemeFilled">View Builder</Button>
            </Link>
          </div>
        }
      />
      {controls}
    </>
  );
};

export default ViewList;
