import { useEffect, useMemo, useState } from 'react';

import Tooltip from '@mui/material/Tooltip';
import {
  BreadCrumbs,
  Button,
  PaginationControls,
  Skeleton,
  WalletWithTimeIcon,
  useSkeleton,
} from 'components';
import { useApi } from 'contexts';
import { useViewFundLogLazyQuery } from 'gql';
import { useBreadCrumbsChild } from 'hooks/useBreadCrumbs';
import { useNavigate, useParams } from 'react-router-dom';
import { classes, pathTo } from 'utils';

import FundDetail from './FundDetail';

export const FundingActivity = () => {
  const isSkLoading = useSkeleton();
  const { id: hashId } = useParams<{ id?: string }>();
  useBreadCrumbsChild({ page: 'FundingActivity', params: [hashId] }, []);
  const {
    account,
    chainProps: { tokenDecimals },
  } = useApi();
  const navigate = useNavigate();

  const startPageIndex = 1;
  const [pageIndex, setPageIndex] = useState<number>(startPageIndex);
  const [pageSize, setPageSize] = useState<number>(3);

  const [getFundHistory, { data: fundingHistory, loading }] = useViewFundLogLazyQuery();

  const isLoading = useMemo(() => {
    if (loading || isSkLoading) return true;
    return false;
  }, [loading, isSkLoading]);

  useEffect(() => {
    hashId &&
      getFundHistory({
        variables: { viewHash: hashId, limit: pageSize, page: pageIndex },
        fetchPolicy: 'cache-and-network',
      });
  }, [getFundHistory, hashId, pageSize, pageIndex]);

  const count = fundingHistory?.viewFundLog?.count;

  return (
    <div className="w-full justify-start lg:p-16 !pt-0 px-0 ">
      <BreadCrumbs />

      <div
        data-cy="funding-activity-page-label"
        className="text-white md:text-[32px] text-2xl md:mb-4 mb-3 md:leading-10 leading-9"
      >
        <Skeleton.Loader isDarkTheme className="sm:w-64 w-[30%] sm:h-12 h-[34px] !rounded-[53px]">
          Funding Activity
        </Skeleton.Loader>
      </div>
      <Skeleton.Provider isLoading={isLoading}>
        <div
          data-cy="funding-activity-history-table"
          className={classes(
            'card sm:p-5 p-4 sm:rounded-3xl rounded-2xl lg:!border lg:!border-solid !border-[#1F1F1F] !border-none lg:!bg-gradient-to-b from-[#000_20.37%] to-[#000_79.34%] !bg-transparent',
            !count &&
              !isLoading &&
              'md:min-h-[410px] min-h-[225px] flex items-center justify-center bg-gradient-to-b from-black to-black shadow-inner !border-solid !border-[#1F1F1F]'
          )}
        >
          {(!!count || isLoading) && (
            <div className="lg:block md:hidden hidden">
              <div className="grid grid-cols-[15%_30%_25%_20%_10%] text-[#B2B3B8] text-xs sm:pl-5 sm:pr-6 px-4">
                <Skeleton.Loader isDarkTheme className="w-14 h-3">
                  <div>Type</div>
                </Skeleton.Loader>
                <Skeleton.Loader isDarkTheme className="w-[81px] h-3 !rounded-full">
                  <div>Sponsor</div>
                </Skeleton.Loader>
                <Skeleton.Loader isDarkTheme className="w-[75px] h-3 !rounded-full">
                  <div>Timestamp</div>
                </Skeleton.Loader>
                <Skeleton.Loader isDarkTheme className="w-[70px] h-3 !rounded-full">
                  <div>Total Amounts</div>
                </Skeleton.Loader>
                <Skeleton.Loader isDarkTheme className="w-[70px] h-3 !rounded-full">
                  <div>Total Rewards</div>
                </Skeleton.Loader>
              </div>
            </div>
          )}

          {!count && !isLoading && (
            <div className="flex items-center justify-center flex-col">
              <WalletWithTimeIcon />
              <div className="text-center text-white text-xl font-normal  leading-7 md:mt-[18px] mt-[14px] mb-[14px]">
                This View is currently not funded.
              </div>
              <Tooltip
                placement="bottom"
                classes={{ tooltip: '!max-w-[200px]' }}
                title={
                  !account?.address && (
                    <div
                      data-cy="to-fund-a-view-connect-wallet-tooltip"
                      className="text-sm  text-center text-[#F7F8F8] font-normal"
                    >
                      To fund a view, please connect your wallet.
                    </div>
                  )
                }
                arrow
              >
                <Button
                  data-cy="add-funds-btn"
                  className=""
                  variant="darkThemeFilled"
                  isDisabled={!account?.address}
                  onClick={() => {
                    navigate(pathTo('View', hashId));
                    sessionStorage.setItem('isAddFundsOpen', true.toString());
                  }}
                >
                  Add Funds
                </Button>
              </Tooltip>
            </div>
          )}
          {(!!count || isLoading) && (
            <>
              <div className="text-white lg:pt-[10px] md:pt-[0px] pt-0">
                {(isLoading
                  ? new Array(pageSize).fill(null)
                  : fundingHistory?.viewFundLog?.viewFundingLogs || []
                ).map((fund, index) => (
                  <FundDetail data={fund} key={index} tokenDecimals={tokenDecimals} />
                ))}
              </div>
              <PaginationControls
                count={count || 0}
                isLoading={isLoading}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                pageSize={pageSize}
                setPageSize={setPageSize}
                startPageIndex={startPageIndex}
              />
            </>
          )}
        </div>
      </Skeleton.Provider>
    </div>
  );
};

export default FundingActivity;
