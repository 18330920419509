import Tooltip from '@mui/material/Tooltip';
import { FormatBalance, Publisher, ResourceLogo, Skeleton } from 'components';
import { Link, LinkProps } from 'react-router-dom';
import { SearchResult as ResourceType } from 'types';
import { arrayOfSize, classes, getIsCollection } from 'utils';

import { ChainChip } from '../ChainChip';

import useResource from './useResource';

export interface ResourceProps<T extends ResourceType> {
  value?: T;
  linkProps?: Partial<LinkProps>;
  isList?: boolean;
}

export function Resource<T extends ResourceType>({ linkProps, value, isList }: ResourceProps<T>) {
  const {
    isCard,
    ref,
    isVisible,
    to,
    typeLabel,
    linkedContent,
    sortedTags,
    tooltipIsOpen,
    setTooltipIsOpen,
    windowSize,
    isReady,
    handleKeyDown,
  } = useResource({ value, isList });
  return (
    <div
      className={classes(
        'card h-full relative mb-6 flex w-full last:mb-0 lg:p-5 p-[20px] lg:gap-0 gap-4 border border-solid !border-[#1F1F1F] rounded-3xl !bg-gradient-to-b from-[#000_20.37%] to-[#000_79.34%] shadow-[0_8px_10px_0px_rgba(0,0,0,0)]',
        !isCard ? 'lg:flex-row' : 'flex-col'
      )}
      ref={ref}
    >
      <Skeleton.Provider isLoading={!value || !isVisible}>
        <Link to={to} {...linkProps}>
          <div
            className={classes(
              'relative lg:mr-4 mr-2 lg:h-32 h-fit w-full',
              !isCard ? 'lg:w-32' : 'w-full'
            )}
          >
            <Skeleton.Loader
              isDarkTheme
              className={classes(
                'h-[123px] w-[123px] !rounded-2xl',
                isCard && 'w-[100%] h-[128px] mb-4'
              )}
            >
              <div className="absolute top-2/4 left-2/4  rounded-[59px] bg-[linear-gradient(287deg,_var(--tw-gradient-stops))] from-[#342B49_3%] via-[#342238_45%] to-[#533838_95%] filter blur-[35px] h-1/2 w-20 -translate-y-2/4 -translate-x-2/4"></div>
              <ResourceLogo
                className={classes(
                  'relative mr-6 lg:h-unset min-h-auto w-full bg-[#d3cccc14] md:rounded-lg rounded-[20px]',
                  !isCard ? 'lg:w-32' : 'w-full m-0'
                )}
                value={value}
              />

              <div
                className={classes(
                  'absolute bottom-0  right-0 lg:w-fit w-fit',
                  isCard && 'sm:right-1/2 translate-x-2/4 sm:translate-y-2/4 bottom-0 right-[22px]',
                  value?.__typename === 'SmartContract' && isCard && 'right-[55px]'
                )}
              >
                {typeLabel}
              </div>
            </Skeleton.Loader>
          </div>
        </Link>
        <div
          className={classes(
            ' w-full  py-[11px]  overflow-hidden flex  justify-between  items-center flex-col',
            isCard && 'p-0'
          )}
        >
          {value ? (
            <Link to={to} {...linkProps} className="w-full">
              {linkedContent}
            </Link>
          ) : (
            <div className="w-full">{linkedContent}</div>
          )}
          <div
            className={classes(
              'flex w-full gap-[5px] text-xs text-neutral-500 pointer-events-auto break-all flex-row items-center  whitespace-pre-line flex-wrap'
            )}
          >
            <div
              className={classes(
                'flex gap-[5px] flex-[1_0_100%] md:flex-[0_0_auto] w-full justify-between items-center',
                isCard && 'justify-center'
              )}
            >
              {/* <Tags
                className={classes('m-0 flex-1 gap-2 space-x-0', isCard && 'justify-center')}
                isDarkTheme
                value={value?.tags}
                classNames={{
                  tagBase: 'h-[unset] m-0 first:m-0 ',
                  tagName: 'px-[8px] py-[4px]',
                }}
              /> */}
              <ul
                className={classes(
                  'flex flex-wrap items-center space-x-reverse text-[0.66rem] m-0 flex-1 gap-2 space-x-0',
                  isCard && 'justify-center'
                )}
              >
                {(!sortedTags ? arrayOfSize(3).map(() => undefined) : sortedTags).map(
                  (tag: any, index: number) => {
                    if (index < 3) return <ChainChip tag={tag} key={index} />;
                  }
                )}
                {!!sortedTags?.length && sortedTags?.length - 3 > 0 && (
                  <Tooltip
                    placement="top"
                    classes={{
                      tooltip: '!max-w-[420px]',
                    }}
                    open={tooltipIsOpen}
                    onOpen={() => setTooltipIsOpen(true)}
                    onClose={() => setTooltipIsOpen(false)}
                    title={
                      <div className="flex flex-wrap space-x-1">
                        {sortedTags?.map((tag: any, index: number) => {
                          if (index > 2) return <ChainChip tag={tag} key={index} />;
                        })}
                      </div>
                    }
                    arrow
                  >
                    <div
                      className={classes(
                        'text-sm bg-[linear-gradient(0deg,_#8D74F7_3%,_#D285F7_45%,_#FFAD97_95%)] bg-clip-text text-transparent cursor-pointer'
                      )}
                      {...(windowSize.width < 769 && {
                        onClick: () => setTooltipIsOpen(!tooltipIsOpen),
                        onKeyDown: handleKeyDown,
                        role: 'button',
                        tabIndex: 0,
                      })}
                    >
                      <Skeleton.Loader isDarkTheme>See More</Skeleton.Loader>
                    </div>
                  </Tooltip>
                )}
              </ul>
              {!isCard && (
                <div className="flex gap-[8px] items-center">
                  <Skeleton.Loader isDarkTheme className="w-64 h-[18px] !rounded-2xl">
                    <div className="whitespace-pre">Publisher:</div>{' '}
                    <Publisher
                      data-cy={`${value?.publisherAddress as string}`}
                      value={value?.publisherAddress as string}
                      withLink
                      isTruncated={windowSize.width <= 657}
                      className="md:text-xs !text-[#B15EBE]"
                    />
                  </Skeleton.Loader>
                </div>
              )}
            </div>
          </div>
          {value && isVisible && isReady && (
            <>
              {getIsCollection(value) && (
                <div className="lg:absolute  bottom-4 right-4 flex lg:items-baseline items-end lg:mt-0 mt-4 justify-start text-xs leading-none">
                  <Skeleton.Loader
                    isDarkTheme
                    containerClassName="flex flex-1"
                    className="h-full w-full"
                  >
                    Earnings:
                    <FormatBalance className="ml-2 text-sm font-medium leading-none" value={'0'} />
                  </Skeleton.Loader>
                </div>
              )}
            </>
          )}
        </div>
      </Skeleton.Provider>
    </div>
  );
}
