import { useMemo } from 'react';

import { CopyButton, Skeleton } from 'components';
import { useSmartContractFunctionsQuery } from 'gql';
import omitDeep from 'omit-deep-lodash';
import { useParams } from 'react-router-dom';

import { ContractAbi } from './ContractAbi';
import { Functions } from './Functions';
import ViewList from './ViewList';

export const Contract = ({ type }: { type: 'view' | 'contract' | 'function' }) => {
  const { id: address } = useParams();
  const { data: functionData, loading: isFunctionLoading } = useSmartContractFunctionsQuery({
    variables: { address: address as string },
    fetchPolicy: 'cache-first',
  });

  const abi = functionData?.smartContractFunctions
    ? JSON.stringify(
        omitDeep(functionData?.smartContractFunctions?.map((fn) => fn.contract?.abi) || [], [
          '__typename',
        ])
      )
    : '[]';

  const content = useMemo(() => {
    switch (type) {
      case 'contract':
        return (
          <div
            data-cy="contract-abi"
            className="!border-[#1F1F1F] border rounded-[24px] sm:p-6 p-4 sm:pt-4 sm:pb-6 pb-1 card  bg-gradient-to-b from-[#000_20.37%] to-[#000_79.34%]  !shadow-[0_8px_10px_0px_#000,0px_-2px_52px_0px_#c8c8c80f]"
          >
            <div className="flex items-center justify-between mb-4">
              <Skeleton.Loader isDarkTheme className="w-[99px] h-[27px]">
                <h5 className="m-0 md:text-lg text-lg font-normal  leading-7 text-white ">
                  Contract ABI
                </h5>
              </Skeleton.Loader>
              <Skeleton.Loader isDarkTheme className="w-10 h-10 !rounded-full">
                <CopyButton
                  value={abi}
                  className="text-white rounded-[61px] bg-[#141414] p-[10px]"
                />
              </Skeleton.Loader>
            </div>
            <ContractAbi abi={abi} isLoading={isFunctionLoading} />
          </div>
        );
      case 'view':
        return <ViewList />;

      default:
        return <Functions />;
    }
  }, [abi]);

  return <div className="flex flex-col space-y-8">{content}</div>;
};
