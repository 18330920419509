import { HTMLAttributes } from 'types';

export function StarGroup(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="317"
      height="152"
      viewBox="0 0 317 152"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M168.231 19.7438L178.131 39.5438C179.481 42.3001 183.081 44.9438 186.119 45.4501L204.062 48.4313C215.537 50.3438 218.237 58.6688 209.969 66.8813L196.019 80.8313C193.656 83.1938 192.362 87.7501 193.094 91.0126L197.087 108.281C200.237 121.95 192.981 127.238 180.887 120.094L164.069 110.138C161.031 108.338 156.025 108.338 152.931 110.138L136.112 120.094C124.075 127.238 116.762 121.894 119.912 108.281L123.906 91.0126C124.637 87.7501 123.344 83.1938 120.981 80.8313L107.031 66.8813C98.8186 58.6688 101.462 50.3438 112.937 48.4313L130.881 45.4501C133.862 44.9438 137.462 42.3001 138.812 39.5438L148.712 19.7438C154.112 9.0001 162.887 9.0001 168.231 19.7438Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M278.06 74.3085L284.733 87.6552C285.643 89.5131 288.07 91.2952 290.118 91.6364L302.213 93.646C309.948 94.9352 311.768 100.547 306.194 106.083L296.791 115.486C295.198 117.079 294.326 120.15 294.819 122.349L297.511 133.989C299.635 143.203 294.743 146.767 286.591 141.952L275.254 135.241C273.207 134.027 269.832 134.027 267.747 135.241L256.41 141.952C248.296 146.767 243.366 143.165 245.49 133.989L248.182 122.349C248.675 120.15 247.803 117.079 246.21 115.486L236.807 106.083C231.271 100.547 233.053 94.9352 240.788 93.646L252.883 91.6364C254.893 91.2952 257.32 89.5131 258.23 87.6552L264.903 74.3085C268.543 67.0664 274.458 67.0664 278.06 74.3085Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.0601 74.3085L58.7334 87.6552C59.6434 89.5131 62.0701 91.2952 64.1176 91.6364L76.213 93.646C83.948 94.9352 85.768 100.547 80.1943 106.083L70.7909 115.486C69.1984 117.079 68.3263 120.15 68.8193 122.349L71.5113 133.989C73.6347 143.203 68.7434 146.767 60.5913 141.952L49.2543 135.241C47.2068 134.027 43.8322 134.027 41.7468 135.241L30.4097 141.952C22.2955 146.767 17.3663 143.165 19.4897 133.989L22.1818 122.349C22.6747 120.15 21.8026 117.079 20.2101 115.486L10.8068 106.083C5.27093 100.547 7.05301 94.9352 14.788 93.646L26.8834 91.6364C28.893 91.2952 31.3197 89.5131 32.2297 87.6552L38.903 74.3085C42.543 67.0664 48.458 67.0664 52.0601 74.3085Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
