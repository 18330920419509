import { useEffect, useLayoutEffect, useMemo, useState } from 'react';

import { Popover } from '@headlessui/react';
import { useApi } from 'contexts';
import { desktopWallet, encodeWalletAddressToAnalogAddress, getSubstrateWalletType } from 'utils';

import { useModalState } from '../Modal';

import AccountMenuBody from './components/AccountMenuBody';
import { DisconnectModal } from './components/DisconnectModal';

export function AccountMenu() {
  const { account } = useApi();
  const disconnectModal = useModalState();
  const [clicked, setClicked] = useState(false);
  const [substrateWalletIcon, setSubstrateWalletIcon] = useState<string | undefined>();

  const analogAddress = useMemo(() => {
    if (account?.address) {
      return encodeWalletAddressToAnalogAddress(account.address);
    }
  }, [account?.address]);

  useEffect(() => {
    setClicked(false);
  }, [disconnectModal.isOpen]);

  useLayoutEffect(() => {
    (async () => {
      if (account?.address) {
        const walletType = await getSubstrateWalletType(account?.address);
        setSubstrateWalletIcon(desktopWallet.find((wallet) => wallet.value === walletType)?.img);
      }
    })();
  }, [account?.address]);

  if (!account) {
    return null;
  }

  return (
    <>
      <Popover className="relative flex lg:w-fit w-full lg:flex-col flex-col-reverse lg:gap-0 gap-3">
        {({ open }) => {
          return (
            <AccountMenuBody
              clicked={clicked}
              disconnectModal={disconnectModal}
              open={open}
              setClicked={setClicked}
              substrateWalletIcon={substrateWalletIcon}
              analogAddress={analogAddress}
            />
          );
        }}
      </Popover>
      <DisconnectModal isDarkTheme {...disconnectModal} className="bg-black rounded-3xl" />
    </>
  );
}
