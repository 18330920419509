import { useMemo, useRef, useState } from 'react';

import { Description, Skeleton, Status, TimeAgo } from 'components';
import { useApi } from 'contexts';
import { useWindowSize } from 'hooks';
import { SearchResult as ResourceType } from 'types';
import { useIntersectionObserver } from 'usehooks-ts';
import { classes, getIsCollection, getIsView, resourceLink, tagTypeOrder } from 'utils';

import { ResourceProps } from './Resource';

const useResource = ({ value, isList }: ResourceProps<ResourceType>) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { isReady } = useApi();
  const entry = useIntersectionObserver(ref, { freezeOnceVisible: true, threshold: 0.1 });
  const isVisible = !!entry?.isIntersecting;
  const { windowSize } = useWindowSize();

  const [tooltipIsOpen, setTooltipIsOpen] = useState<boolean>(false);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      setTooltipIsOpen(!tooltipIsOpen);
    }
  };

  const sortedTags = value?.tags?.toSorted((a, b) => {
    if (a?.tagType && b?.tagType) return tagTypeOrder[a?.tagType] - tagTypeOrder[b?.tagType];
    return -1;
  });

  const isCard = useMemo(() => !isList || windowSize.width < 769, [isList, windowSize.width]);

  const [to, linkedContent] = useMemo(() => {
    return [
      value ? resourceLink(value) : '#',
      <>
        <div
          className={classes(
            'lg:mb-1 mb-2 flex h-auto flex-col items-baseline md:space-x-2',
            isCard ? 'm-0 h-auto' : 'md:h-auto md:flex-row'
          )}
        >
          <div className=" flex flex-row justify-between w-full">
            <div className="gap-[6px] flex-[2] flex flex-col">
              <h4
                data-cy={`${value?.name}`}
                className={classes(
                  'hyperlink flex items-center lg:text-xl text-[16px] text-white',
                  isCard && 'justify-center truncate md:pt-6 mt-4 pb-3 m-0'
                )}
              >
                <Skeleton.Loader
                  isDarkTheme
                  containerClassName={classes('flex flex-1', isCard && 'justify-center')}
                  className={classes('w-[40%]', isCard && 'h-[26px] w-48')}
                >
                  {value?.name}
                  {value && getIsCollection(value) && false && (
                    <Status
                      color="green"
                      className="ml-3 text-xs font-normal text-green-500"
                      dotClassName="mr-1"
                    >
                      Indexed
                    </Status>
                  )}
                </Skeleton.Loader>
              </h4>
              {!isCard && (
                <div className="lg:mb-2 mb-4  w-full ">
                  <div className="flex">
                    <div
                      className={classes(
                        'max-w-full flex-1 leading-none lg:text-base text-[14px] text-[#B2B3B8] break-all '
                      )}
                    >
                      <Skeleton.Loader isDarkTheme className="w-[70%]">
                        <Description value={value?.description} className="line-clamp-1" />
                      </Skeleton.Loader>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {!isCard && (
              <div className="text-[#B2B3B8] flex flex-1 items-start justify-end">
                {/* TODO: Need to enable this when it is implemented from timegraph */}
                {/* <Skeleton.Loader isDarkTheme className="w-24 h-[18px] mr-5">
                  {
                    value?.__typename === 'SmartContract' ? (
                      <div className="flex items-center justify-center gap-[4px] text-xs">
                        <RefrenceSVG />
                        <div data-cy={`${value?.refsCount || 0}`} className="whitespace-nowrap">
                          {value?.refsCount || 0} {pluralize('Reference', value?.refsCount || 0)}
                        </div>
                        <span className="md:inline hidden ml-1">{' • '}</span>
                      </div>
                    ) : (
                      ''
                    )
                    // TODO: enable ViewStatus When new architecture is implemented
                    // (
                    //   value?.__typename === 'View' && (
                    //     <div className="flex items-center justify-center gap-[4px] text-xs">
                    //       <Status
                    //         color={value?.status === 'Active' ? 'green' : 'red'}
                    //         dotClassName="mr-1"
                    //       />
                    //       <div>{value.status || 'Inactive'}</div>
                    //     </div>
                    //   )
                    // )
                  }
                </Skeleton.Loader>
                {value?.__typename === 'View' ? (
                  <Skeleton.Loader isDarkTheme className="w-16 h-[18px]">
                    <div className="flex items-center justify-center gap-1 text-xs">
                      <StackIcon />
                      <div className="text-white">
                        {(value?.uniqueUserQueryPerView || 0) > 1
                          ? `${value.uniqueUserQueryPerView} Unique Queries`
                          : `${value.uniqueUserQueryPerView || 0} Unique Query`}
                      </div>
                      <span className="md:inline hidden ml-1">{' • '}</span>
                    </div>
                  </Skeleton.Loader>
                ) : (
                  ''
                )} */}
                <Skeleton.Loader isDarkTheme className="w-16 h-[18px]">
                  <div className="flex items-center ml-2 justify-center gap-2 text-xs">
                    <TimeAgo
                      date={value?.createAt}
                      showIcon
                      className="text-xs flex gap-1 whitespace-nowrap"
                    />
                  </div>
                </Skeleton.Loader>
              </div>
            )}
          </div>
        </div>
      </>,
    ];
  }, [value, isCard]);

  const typeLabel = useMemo(() => {
    let label;
    if (getIsView(value)) {
      label = 'View';
    } else {
      label = 'Smart Contract';
    }

    return (
      <div
        data-cy={`${label}-label`}
        className={classes(
          'md:px-[10px] md:py-[1px] py-1 px-[10px] uppercase text-[10px] lg:w-fit w-fit text-center min-h-[18px] leading-[18px] flex items-center justify-center md:text-[8px] font-[450] text-white bg-[linear-gradient(287deg,_var(--tw-gradient-stops))] from-[#342B49_3%] via-[#342238_45%] to-[#533838_95%] rounded-[16px_0]',
          isCard && ' sm:rounded-[20px] rounded-[16px_0] whitespace-pre'
        )}
        key={label}
      >
        {label}
      </div>
    );
  }, [value, isCard]);

  return {
    isCard,
    ref,
    isVisible,
    to,
    typeLabel,
    linkedContent,
    sortedTags,
    tooltipIsOpen,
    setTooltipIsOpen,
    windowSize,
    isReady,
    handleKeyDown,
  };
};

export default useResource;
