import { classes } from 'utils';

import { Props$TagButton } from './types';

export const tagClassName = classes(
  'flex h-6 h-full items-center justify-center bg-neutral-100 px-2 font-medium uppercase text-neutral-500'
);

export function TagButton({
  className,
  value,
  icon,
  classNames,
  toggleTag,
  isActive,
  ...props
}: Props$TagButton) {
  return (
    <li {...props} className={classes('text-xxs h-6', className, classNames?.base)}>
      <button
        data-tag={value}
        className={classes(
          tagClassName,
          'cursor-pointer rounded-2xl px-[8px] py-[4px] bg-[#f7f8f81f] text-[#F7F8F8]',
          icon && 'pl-1',
          classNames?.button,
          isActive && 'bg-white text-[#00010A]'
        )}
        onClick={() => toggleTag(value)}
      >
        {icon && (
          <div
            data-cy={`${value.name}`}
            className={classes(
              'flex mr-1 justify-center items-center h-5 w-5 rounded-full bg-[#343434]',
              classNames?.icon
            )}
          >
            {icon}
          </div>
        )}
        {value.name}
      </button>
    </li>
  );
}
