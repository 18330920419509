import { Download, Identicon, Input, Skeleton, Statistic, ToolTip } from 'components';
import { MyProfileFragment } from 'gql';
import { ClassNames, Maybe } from 'types';
import { classes } from 'utils';

const ProfileOverview = ({
  isDarkTheme,
  classNames,
  value,
  isLoading,
  sessionKey,
  onDownload,
}: {
  isDarkTheme?: boolean;
  classNames?: ClassNames<'input' | 'label' | 'identicon'>;
  value: Maybe<MyProfileFragment>;
  isLoading?: boolean;
  sessionKey?: string;
  onDownload?: () => void;
}) => {
  return (
    <div
      className={classes(
        'flex gap-[18px] flex-1',
        isDarkTheme &&
          'w-full lg:gap-[18px] md:gap-[18px] sm:gap-[12px] gap-[12px] lg:items-start md:items-start sm:items-center items-center'
      )}
    >
      <Identicon className={classes('h-20 w-20', classNames?.identicon)} value={value?.address} />
      <Statistic
        classNames={{
          base: 'flex-1',
          strings: classes(
            'w-fit',
            isDarkTheme && 'flex-col-reverse lg:gap-[8px] sm:gap-[3px] gap-[3px]',
            isLoading && 'w-full'
          ),
          label: classes(
            classNames?.label,
            isDarkTheme && '!text-[#B2B3B8] normal-case mb-0 text-[12px]'
          ),
          value: 'flex item-center gap-3',
        }}
        label="Wallet Address"
        isDarkTheme={isDarkTheme}
      >
        <Input
          className="w-full max-w-[500px] rounded bg-neutral-50"
          isCopyOnly
          isDarkTheme={isDarkTheme}
          copyValue={value?.address || ''}
          value={value?.address || ''}
          classNames={{
            input: classes(
              '',
              isDarkTheme &&
                'max-w-[280px] truncate p-0 lg:text-[28px] md:text-[28px] sm:text-[16px] text-[16px]'
            ),
          }}
        />
        {sessionKey && (
          <Skeleton.Loader isDarkTheme className="min-h-10 min-w-10 !rounded-full">
            <button
              onClick={onDownload}
              className="btn relative max-h-full plain h-auto hover:text-blue-300 text-white rounded-[61px] bg-[#141414] p-[10px]"
            >
              <ToolTip
                anchor="top-left"
                message={'Download Session Key'}
                classNames={{
                  arrow: 'rotate-[225deg] top-3 left-[20px] z-20',
                  message: 'w-fit whitespace-nowrap	-right-[50px] top-5 z-10',
                }}
              >
                <Download className="w-5 h-5" />
              </ToolTip>
            </button>
          </Skeleton.Loader>
        )}
      </Statistic>
    </div>
  );
};

export default ProfileOverview;
