import { createContext, useContext, useState } from 'react';

interface Value {
  tab?: number;
  setTab?: any;
}

export const TabContext = createContext<Value | undefined | null>({} as Value);

export function TabProvider({ children }: React.PropsWithChildren) {
  const [tab, setTab] = useState(0);

  const value = { tab, setTab };
  return <TabContext.Provider value={value}>{children}</TabContext.Provider>;
}

export const useTabCtx = (): Value => {
  const value = useContext(TabContext);

  if (!value) {
    return {} as Value;
  }

  return value;
};
