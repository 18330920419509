import { useCallback, useEffect, useMemo, useState } from 'react';

import { useApi } from 'contexts';
import { useOnboardingMutation } from 'gql';
import { useCookies } from 'react-cookie';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { Chain, DappType, ProjectType } from 'types';

import { Question } from './components/Question';
import { chains } from './utils';

const useOnBoarding = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { account } = useApi();
  const [didOnboarding, setDidOnboarding] = useState({});
  const [cookies, setCookie] = useCookies(['didOnboarding']);
  const [page, setPage] = useState(0);
  const [dappType, setDappType] = useState<DappType>('');
  const [chain, setChain] = useState<Chain[]>([]);
  const [projectType, setProjectType] = useState<ProjectType>('');
  // const [plan, setPlan] = useState<Response<Plan>>({});
  // const [billingDetails, setBillingDetails] = useState<BillingDetailsType>({
  //   firstName: '',
  //   lastName: '',
  //   cardNumber: '',
  //   expiryDate: '',
  //   cvv: '',
  //   address: '',
  // });

  // const handleBillingDetailsChange = (e: string, name: string) => {
  //   setBillingDetails((prevState) => {
  //     return {
  //       ...prevState,
  //       [name]: e,
  //     };
  //   });
  // };

  // const plans: ResponseOption<Plan>[] = useMemo(() => {
  //   return [
  //     ...([['free'], ['standard'], ['business']] as [Plan][]).map(([planOption]: [Plan]) => {
  //       switch (planOption) {
  //         case 'free':
  //           return {
  //             label: <PlanDetails planOption={planOption} selected={plan.value} />,
  //             value: planOption,
  //           };
  //         case 'standard':
  //           return {
  //             label: <PlanDetails planOption={planOption} selected={plan.value} />,
  //             value: planOption,
  //           };
  //         default:
  //           return {
  //             label: <PlanDetails planOption={planOption} selected={plan.value} />,
  //             value: planOption,
  //           };
  //       }
  //     }),
  //   ];
  // }, [plan]);

  const [submit] = useOnboardingMutation();

  const [contents, isAnswered]: [React.ReactNode, boolean] = useMemo(() => {
    switch (page) {
      case 0:
        return [
          <Question<ProjectType>
            key="project-type"
            header="What type of project are you building?"
            text="Select the category of project from the list below that perfectly describes your dApp."
            value={projectType}
            onChange={setProjectType}
            options={[
              { label: 'NFT', value: 'nft' },
              { label: 'DeFi', value: 'defi' },
              { label: 'Analytics', value: 'analytics' },
              { label: 'DAO', value: 'dao' },
              { label: 'Other', value: 'other' },
            ]}
          />,
          !!projectType,
        ];

      case 1:
        return [
          <Question<Chain[]>
            key="chain"
            header="What chains are you building on?"
            text="Which blockchains do you prefer to use for your dApp?"
            value={chain}
            onChange={setChain}
            isMultiChain
            options={chains as any}
          />,
          !!chain.length, // && (chain.value !== 'other' || !!chain.extra),
        ];

      default: // TODO : change this to case 2 when implementing
        return [
          <Question<DappType>
            key="dapp-type"
            header="What kind of dApp do you intend to build?"
            text="Solo chain dApps are designed to run on a single chain. Cross-chain dApps, in contrast, share data and messages across multiple blockchains."
            value={dappType}
            onChange={setDappType}
            options={[
              { label: 'Solo-Chain', value: 'solo-chain' },
              { label: 'Cross-Chain', value: 'cross-chain' },
            ]}
          />,
          !!dappType,
        ];
      // case 3:
      //   return [
      //     <Question<Plan>
      //       key="plans"
      //       header="Choose your plan"
      //       text="Get access to the entire Analog Watch platform, start querying any data from any connected chain, and pay only for what you use."
      //       value={plan}
      //       onChange={setPlan}
      //       options={plans}
      //       classNames={{
      //         optionContainer:
      //           'sm:h-[135px] !sm:w-[153px] !w-full h-full p-0 justify-start items-start py-[9px] px-4 relative',
      //       }}
      //     />,
      //     !!plan.value,
      //   ];
      // default:
      //   return [
      //     <div className="mt-[24px]">
      //       <h4 className="text-left text-2xl font-normal leading-[32px]">Add payment details</h4>
      //       <div className="text-base leading-6 font-normal text-[#B2B3B8] text-left w-full mt-2">
      //         Add a payment method that you’ll use to curate and query data from your favorite
      //         blockchains.
      //       </div>
      //       <div className="flex sm:flex-row flex-col mt-[24px] gap-4">
      //         <Input
      //           className="border border-solid  w-full h-12 border-[#1F1F1F] rounded-full text-white px-2"
      //           placeholder="First Name"
      //           classNames={{ input: 'text-white ' }}
      //           type="text"
      //           name="firstName"
      //           label={<span className="mx-2">First Name</span>}
      //           labelType="interior"
      //           value={billingDetails.firstName}
      //           onChange={(e) => {
      //             // let value = e;
      //             // value = value.replace(/[^A-Za-z]/gi, '');
      //             handleBillingDetailsChange(e, 'firstName');
      //           }}
      //           filter={/[^A-Za-z]/gi}
      //         />
      //         <Input
      //           className="border border-solid  w-full h-12 border-[#1F1F1F] rounded-full text-white px-2"
      //           placeholder="Last Name"
      //           type="text"
      //           name="lastName"
      //           label={<span className="mx-2">Last Name</span>}
      //           labelType="interior"
      //           classNames={{ input: 'text-white ' }}
      //           value={billingDetails.lastName}
      //           onChange={(e) => handleBillingDetailsChange(e, 'lastName')}
      //           filter={/[^A-Za-z]/gi}
      //         />
      //       </div>
      //       <div className="flex sm:flex-row flex-col mt-4 gap-4">
      //         <Input
      //           className="border border-solid w-full h-12 border-[#1F1F1F] rounded-full text-white px-2"
      //           placeholder="Card Number"
      //           type="text"
      //           name="cardNumber"
      //           label={<span className="mx-2">Card Number</span>}
      //           labelType="interior"
      //           classNames={{ input: 'text-white' }}
      //           value={billingDetails.cardNumber}
      //           onChange={(e) => {
      //             if (e.replaceAll(' ', '').length < 17) {
      //               let str = e.replaceAll(' ', '');

      //               if (str.length > 0) {
      //                 str = str.split('').reduce((seed, next, index) => {
      //                   if (index !== 0 && !(index % 4)) seed += ' ';
      //                   return seed + next;
      //                 });
      //               }
      //               handleBillingDetailsChange(str, 'cardNumber');
      //             }
      //           }}
      //           filter={/[^\d]/}
      //         />
      //         <div className="flex gap-4 w-full">
      //           <Input
      //             className="border border-solid h-12 w-full border-[#1F1F1F] rounded-full text-white px-2"
      //             placeholder="MM/YY"
      //             type="text"
      //             name="expiryDate"
      //             label={<span className="mx-2">MM/YY</span>}
      //             labelType="interior"
      //             classNames={{ input: 'text-white ' }}
      //             value={billingDetails.expiryDate}
      //             onChange={(e) => {
      //               if (e.length < 5) {
      //                 let str;
      //                 if (e.length < 3) {
      //                   str = e;
      //                 } else {
      //                   str = e.slice(0, 2) + '/' + e.slice(2);
      //                 }

      //                 handleBillingDetailsChange(str, 'expiryDate');
      //               }
      //             }}
      //             filter={/[^\d]/g}
      //           />
      //           <Input
      //             className="border border-solid h-12 w-full border-[#1F1F1F] rounded-full text-white px-2"
      //             placeholder="CVV"
      //             type="text"
      //             name="cvv"
      //             label={<span className="mx-2">CVV</span>}
      //             labelType="interior"
      //             classNames={{ input: 'text-white' }}
      //             value={billingDetails.cvv}
      //             onChange={(e) => {
      //               if (e.length < 4) handleBillingDetailsChange(e, 'cvv');
      //             }}
      //             filter={/[^\d]/g}
      //           />
      //         </div>
      //       </div>
      //       <Input
      //         className="border border-solid w-full h-12 border-[#1F1F1F] rounded-full text-white px-2 mt-4"
      //         placeholder="Billing Address"
      //         type="text"
      //         name="address"
      //         label={<span className="mx-2">Billing Address</span>}
      //         labelType="interior"
      //         classNames={{ input: 'text-white ' }}
      //         value={billingDetails.address}
      //         onChange={(e) => handleBillingDetailsChange(e, 'address')}
      //       />
      //     </div>,
      //     // !!plan.value,
      //     !Object.values(billingDetails).some((el) => el === ''),
      //   ];
    }
  }, [chain, dappType, page, projectType]);

  const onSubmit = useCallback(() => {
    if (page < 2) {
      // TODO: change to 4
      setPage((prev) => prev + 1);
    } else {
      if (account?.address) {
        submit({
          variables: { address: account?.address, answers: { projectType, dappType, chain } },
        }).then(() => {
          setDidOnboarding((prev) => ({ ...prev, [account.address]: true }));
        });
      }
      searchParams?.get('redirect') ? navigate(searchParams?.get('redirect') || '') : navigate(-1);
    }
  }, [
    account?.address,
    chain,
    dappType,
    navigate,
    page,
    projectType,
    searchParams,
    setDidOnboarding,
    submit,
  ]);

  useEffect(() => {
    if (!account?.address || didOnboarding[account.address as keyof object]) {
      navigate(routes.base);
    }
  }, [account?.address, didOnboarding, navigate]);

  useEffect(() => {
    setDidOnboarding(cookies.didOnboarding || {});
  }, []);

  useEffect(() => {
    setCookie('didOnboarding', didOnboarding, {
      maxAge: 34560000,
      sameSite: 'strict',
      secure: false,
    });
  }, [didOnboarding]);

  const goBack = useCallback(() => {
    if (account?.address) {
      setDidOnboarding((prev) => ({ ...prev, [account.address]: true }));
    }
    navigate(-1);
  }, [account]);

  useEffect(() => {
    if (chain.length >= 2) {
      setDappType('cross-chain');
    }
  }, [chain]);

  return {
    page,
    contents,
    setPage,
    goBack,
    isAnswered,
    onSubmit,
  };
};

export default useOnBoarding;
