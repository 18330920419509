import { ChevronLeftIcon } from 'components/icons';
import { useApi } from 'contexts';
import { classes } from 'utils';

const ConnectWalletHeader = () => {
  const {
    isConnecting,
    selectedWallet,
    setSelectedWallet,
    isConfirming,
    setIsConfirming,
    setIsConnecting,
  } = useApi();

  return (
    <>
      {!selectedWallet && !isConnecting && (
        <div className="flex justify-between ">
          <p data-cy="connect-wallet-popup" className="text-2xl">
            Connect a Wallet
          </p>
        </div>
      )}
      {selectedWallet && (
        <div
          className={classes(
            'flex flex-row items-center gap-[6px]',
            isConnecting && 'px-[32px] pt-[26px]'
          )}
        >
          <ChevronLeftIcon
            width={24}
            height={24}
            className="text-white cursor-pointer"
            onClick={() => {
              if (!isConfirming && selectedWallet) setSelectedWallet('');
              setIsConfirming(false);
              setIsConnecting(false);
            }}
          />
          <p className="text-white text-2xl font-normal leading-8">Connect Account</p>
        </div>
      )}
      {!selectedWallet && !isConnecting && (
        <p className="text-sm mt-2 text-[#B2B3B8]">
          Select the subtrate based account you want to connect.
        </p>
      )}
    </>
  );
};

export default ConnectWalletHeader;
