import { BreadCrumbs, ProfileDetails, ProfileResources, Skeleton } from 'components';
import { useMyProfile } from 'contexts';
import { useTabCtx } from 'contexts/Tab';
import { routes } from 'routes/routesConst';

import { ApiKeys } from './ApiKeys/ApiKeys';
import AdditionalButton from './components/AdditionalButton';
import DepositFund from './components/DepositFund';
import WithdrawFund from './components/WithdrawFund';
import { FundedView } from './FundedView/FundedView';
import { useMyProfile as useMyProfileHook } from './useMyProfile';

export const MyProfile = () => {
  const profile = useMyProfile();
  const tabCtx = useTabCtx();
  const {
    account,
    isLoading,
    handleClick,
    tokenDecimals,
    sessionKey,
    clickHandler,
    countData,
    refreshSearchQuery,
    isCountDataLoading,
    sortBy,
    isOpen,
    isWithdraw,
    setIsOpen,
  } = useMyProfileHook(profile, tabCtx);

  if (!account) {
    return null;
  }

  return (
    <Skeleton.Provider isLoading={isLoading}>
      <div className="flex flex-col space-y-12 md:space-y-[50px] lg:p-16 !pt-0 px-0 ">
        <BreadCrumbs />
        <ProfileDetails
          value={profile.myProfile}
          className="!bg-black rounded-[24px] !border-[#1F1F1F] md:!border-0 lg:p-6 md:p-6 sm:p-4 p-4 !mt-0"
          showIcon={false}
          classNames={{
            identicon: 'rounded-[8px] lg:w-20 md:w-20 sm:w-12 w-12 lg:h-20 md:h-20 sm:h-12 h-12',
          }}
          isDarkTheme
          showAccountOptions
          handleClick={handleClick}
          accountBalance={profile.accountBalance}
          tokenDecimals={tokenDecimals}
          sessionKey={sessionKey}
          onDownload={clickHandler}
          totalRewardEarned={profile.myProfile?.totalRewards as string}
          totalSpent={profile.myProfile?.totalSpent as string}
          isLoading={isLoading}
          countData={countData}
          RefreshUserBalance={profile?.RefreshUserBalance}
          isUserBalanceLoading={profile.isUserBalanceLoading}
        />
        <ProfileResources
          setTab={tabCtx.setTab!}
          tab={tabCtx.tab}
          value={profile.myProfile}
          countData={countData}
          refreshCountHandler={refreshSearchQuery}
          isDarkTheme
          isLoading={isLoading}
          isCountDataLoading={isCountDataLoading}
          apiComponent={<ApiKeys sortBy={sortBy} />}
          fundedViewComponent={<FundedView />}
          additionalButton={<AdditionalButton />}
        />
        {isOpen &&
          (isWithdraw ? (
            <WithdrawFund
              accountBalance={profile.accountBalance}
              setIsOpen={setIsOpen}
              successModalSubmitTo={routes.profile}
            />
          ) : (
            <DepositFund
              accountBalance={profile.accountBalance}
              setIsOpen={setIsOpen}
              successModalSubmitTo={routes.profile}
            />
          ))}
      </div>
    </Skeleton.Provider>
  );
};

export default MyProfile;
