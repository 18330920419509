import { HTMLAttributes } from 'types';

export function PolygonLogo(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <div
      className={`rounded-3xl flex items-center justify-center w-6 h-6 shrink-0 bg-[#8247E5] ${props.className}`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="13"
        viewBox="0 0 15 13"
        fill="none"
      >
        <g clipPath="url(#clip0_4787_9113)">
          <path
            d="M11.2382 4.042C10.9755 3.8919 10.6378 3.8919 10.3376 4.042L8.23606 5.28038L6.81005 6.06844L4.74609 7.30682C4.4834 7.45693 4.14566 7.45693 3.84545 7.30682L2.2318 6.33113C1.96911 6.18102 1.78148 5.88081 1.78148 5.54307V3.66674C1.78148 3.36652 1.93159 3.06631 2.2318 2.87868L3.84545 1.94051C4.10813 1.7904 4.44587 1.7904 4.74609 1.94051L6.35973 2.9162C6.62242 3.06631 6.81005 3.36652 6.81005 3.70426V4.94264L8.23606 4.11706V2.84115C8.23606 2.54094 8.08596 2.24072 7.78575 2.05309L4.78361 0.289338C4.52093 0.139231 4.18319 0.139231 3.88297 0.289338L0.805789 2.09062C0.505575 2.24072 0.355469 2.54094 0.355469 2.84115V6.36866C0.355469 6.66887 0.505575 6.96908 0.805789 7.15672L3.84545 8.92047C4.10813 9.07058 4.44587 9.07058 4.74609 8.92047L6.81005 7.71962L8.23606 6.89403L10.3 5.69318C10.5627 5.54307 10.9005 5.54307 11.2007 5.69318L12.8143 6.63134C13.077 6.78145 13.2646 7.08166 13.2646 7.4194V9.29574C13.2646 9.59595 13.1145 9.89616 12.8143 10.0838L11.2382 11.022C10.9755 11.1721 10.6378 11.1721 10.3376 11.022L8.72391 10.0838C8.46122 9.93369 8.27359 9.63348 8.27359 9.29574V8.09488L6.84758 8.92047V10.1588C6.84758 10.4591 6.99769 10.7593 7.2979 10.9469L10.3376 12.7107C10.6002 12.8608 10.938 12.8608 11.2382 12.7107L14.2779 10.9469C14.5405 10.7968 14.7282 10.4966 14.7282 10.1588V6.59382C14.7282 6.2936 14.5781 5.99339 14.2779 5.80576L11.2382 4.042Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_4787_9113">
            <rect
              width="14.4102"
              height="12.5714"
              fill="white"
              transform="translate(0.355469 0.214355)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}
