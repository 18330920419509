import { Loader } from 'components/Loader';
import { MagicPen } from 'components/svg';
import { useApi } from 'contexts';

const ConnectWalletLoading = () => {
  const { isConnecting } = useApi();
  return (
    <div className={'text-center text-base'}>
      <div className="py-14 px-8">
        <Loader className="flex  items-center text-white mb-4" />
        <div className="text-center text-white text-base font-normal leading-relaxed">
          Loading...
        </div>
      </div>
      {isConnecting && (
        <div className="border-t border-stone-900 text-sm text-[#B2B1B8] leading-[normal] p-8 flex gap-3 items-center justify-start">
          <div className="w-10 h-10 p-2.5 bg-white bg-opacity-10 rounded-md justify-start items-start ">
            <MagicPen />
          </div>
          <div className=" text-zinc-400 text-sm font-normal  leading-tight text-left">
            To proceed, please connect a Polkadot (Substrate) account and follow the necessary steps
            within the browser extension.
          </div>
        </div>
      )}
    </div>
  );
};

export default ConnectWalletLoading;
