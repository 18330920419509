import { useEffect, useMemo, useState } from 'react';

import { Tooltip } from '@mui/material';
import { Button } from 'components';
import { useApi } from 'contexts';
import toast from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';
import { Setter } from 'types';
import { classes } from 'utils';

import { useListSmartContract } from '../../hooks/useListSmartContracts';

const useSelectFunctions = ({
  isAddFunctions,
  handleSelectedTab,
  setIsSmartContractExist,
  isSmartContractAlreadyListed,
}: {
  isAddFunctions?: boolean;
  handleSelectedTab?: (tabIndex: number) => void;
  setIsSmartContractExist?: Setter<boolean>;
  isSmartContractAlreadyListed?: boolean;
}) => {
  const [searchString, setSearchString] = useState('');
  const [selectedFunctionsSpecs, setSelectedFunctionsSpecs] = useState<string[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { account } = useApi();
  const {
    isDryRunContractLoading,
    isMergeContractLoading,
    functionOptions,
    isDryRunContractError,
    listSmartContract,
    mergeContractResult,
    isMergeContractError,
    smartContractIdentifier,
    editTags,
    dryRunContract,
    smartContractAddress,
    selectedChain,
  } = useListSmartContract();
  const { sessionKey } = useApi();

  useEffect(() => {
    if (isAddFunctions && smartContractIdentifier && smartContractAddress) {
      dryRunContract({
        variables: {
          data: {
            address: smartContractAddress,
            chain: 'mainnet',
            identifier: smartContractIdentifier,
            network: selectedChain?.slug as string,
          },
          sessionKey: sessionKey as string,
        },
      });
    }
  }, [
    smartContractIdentifier,
    isAddFunctions,
    smartContractAddress,
    dryRunContract,
    selectedChain?.slug,
    sessionKey,
  ]);

  const isLoading = useMemo(
    () => isDryRunContractLoading || isMergeContractLoading,
    [isDryRunContractLoading, isMergeContractLoading]
  );

  useEffect(() => {
    if (
      mergeContractResult &&
      !isMergeContractError &&
      mergeContractResult?.MergeContract?.status === 'Completed'
    ) {
      handleSelectedTab && handleSelectedTab(3);
      setIsSmartContractExist && setIsSmartContractExist(!!isSmartContractAlreadyListed);
    } else if (isMergeContractError) {
      mergeContractResult?.MergeContract?.error &&
        toast.error(mergeContractResult?.MergeContract.error, {
          position: 'top-right',
          duration: 5000,
        });
      handleSelectedTab && handleSelectedTab(1);
    }
  }, [isMergeContractError, mergeContractResult]);

  const handleClick = (functionHashId: string) => {
    if (selectedFunctionsSpecs.includes(functionHashId)) {
      setSelectedFunctionsSpecs((prev) => prev.filter((item) => item !== functionHashId));
    } else {
      setSelectedFunctionsSpecs((prev) => [...prev, functionHashId]);
    }
  };

  const handleListSmartContract = () => {
    if (account) {
      listSmartContract(selectedFunctionsSpecs);
    } else {
      searchParams.set('connect', 'true');
      setSearchParams(searchParams);
    }
  };

  const isAllFunctionListed = useMemo(
    () =>
      functionOptions?.filter((el) => el?.status === 'Untouched').length ===
      functionOptions?.length,
    [functionOptions]
  );

  const button = useMemo(() => {
    const Listbutton = (
      <Button
        className={classes(
          'px-5 rounded-full h-12 mx-auto block mt-6 w-32',
          selectedFunctionsSpecs.length > 0 ? 'bg-white' : 'bg-[#7a7a7a]'
        )}
        variant="plain"
        onClick={handleListSmartContract}
        isDisabled={selectedFunctionsSpecs.length === 0}
      >
        <span data-cy="list-btn-sc" className="text-base text-black">
          List
        </span>
      </Button>
    );

    return isAllFunctionListed ? (
      <Tooltip
        arrow
        placement="top"
        title={
          functionOptions?.length
            ? 'All the function for this Smart contract are already listed.'
            : 'There is no available functions for this smart contract.'
        }
        classes={{ tooltip: '!text-sm text-center' }}
      >
        {Listbutton}
      </Tooltip>
    ) : (
      Listbutton
    );
  }, [handleListSmartContract, selectedFunctionsSpecs, isAllFunctionListed]);

  return {
    button,
    functionOptions,
    handleClick,
    isAllFunctionListed,
    searchString,
    selectedFunctionsSpecs,
    setSearchString,
    setSelectedFunctionsSpecs,
    smartContractIdentifier,
    isLoading,
    isDryRunContractError,
  };
};

export default useSelectFunctions;
