import { HTMLAttributes } from 'types';

export function CustomInfoIcon(props: HTMLAttributes<HTMLOrSVGElement> & { stroke?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M8.00021 1.33464C4.33354 1.33464 1.33354 4.33463 1.33354 8.0013C1.33354 11.668 4.33354 14.668 8.0002 14.668C11.6669 14.668 14.6669 11.668 14.6669 8.0013C14.6669 4.33464 11.6669 1.33464 8.00021 1.33464Z"
        stroke={props?.stroke || '#B2B3B8'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 10.668L8 7.33464"
        stroke={props?.stroke || '#B2B3B8'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00378 5.33203L7.9978 5.33203"
        stroke={props?.stroke || '#B2B3B8'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
