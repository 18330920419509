import { default as BigNumber } from 'bignumber.js';
import { Button, FormatBalance, GradientRefreshButton, Skeleton } from 'components';
import { Link } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { VoidFn } from 'types';
import { classes, formatBalance } from 'utils';

const WalletOverview = ({
  accountBalance,
  tokenDecimals,
  isUserBalanceLoading,
  RefreshUserBalance,
  handleClick,
}: {
  accountBalance?: BigNumber;
  tokenDecimals?: number;
  isUserBalanceLoading?: boolean;
  RefreshUserBalance?: VoidFn;
  handleClick?: (_: boolean) => void;
}) => {
  return (
    <div className="flex-1 pt-3 md:pt-6 lg:border-none border-t border-[#1F1F1F] border-solid  lg:pt-0 flex lg:items-start md:items-center sm:items-start items-start w-full   font-normal lg:flex-row md:flex-row sm:flex-col flex-col md:ml-6 ml-0 gap-3">
      <div className="flex items-center flex-1 2xl:justify-center justify-start">
        <div className="flex flex-col lg:gap-[10px] sm:gap-[8px] gap-[8px] lg:mb-[0px] md:mb-[0px] sm:mb-[16px] mb-[16px] ">
          <div
            className={classes(
              'flex items-baseline flex-wrap',
              (!accountBalance || !tokenDecimals) && 'items-center'
            )}
          >
            <Skeleton.Loader
              isLoading={!accountBalance || !tokenDecimals || isUserBalanceLoading}
              isDarkTheme
              className="sm:w-[200px] w-[30px] sm:h-[36px]"
            >
              {/* <span className="text-white text-[28px] leading-10 mr-2">
                      {formatBalance(accountBalance, {
                        decimalPlaces: 2,
                        symbol: '',
                        tokenDecimals,
                      })}
                    </span>
                    <span className="text-white text-[22px] leading-10 mr-[10px]">$TANLOG</span> */}
              <FormatBalance
                data-cy={`${formatBalance(accountBalance, {
                  symbol: '',
                  tokenDecimals,
                  decimalPlaces: 5,
                })}`}
                value={accountBalance || ''}
                options={{ decimalPlaces: 5 }}
                className="text-white text-[22px] leading-10 mr-[10px]"
              />
            </Skeleton.Loader>
            <Skeleton.Loader
              isLoading={!accountBalance || !tokenDecimals || isUserBalanceLoading}
              isDarkTheme
              className="sm:w-[98px] w-[55px] ml-2 sm:h-[16px] !self-baseline"
            >
              <Link to={routes.balanceHistory}>
                <span
                  data-cy="Balance-history-btn"
                  className="text-purple-500 text-sm leading-none"
                >
                  Balance History
                </span>
              </Link>
            </Skeleton.Loader>
          </div>
          <div className="flex gap-2">
            <Skeleton.Loader isDarkTheme className="w-20">
              <div className="text-xs font-medium normal-case text-[#B2B3B8]">Account Balance</div>
            </Skeleton.Loader>
            <Skeleton.Loader isDarkTheme className="h-4 w-4">
              <div className="h-5 w-5 -mt-[3px]">
                <GradientRefreshButton
                  onClick={() => {
                    RefreshUserBalance && RefreshUserBalance();
                  }}
                  isLoading={isUserBalanceLoading}
                  baseClassName="w-3 h-3"
                  iconClasses={'w-3 h-3'}
                />
              </div>
            </Skeleton.Loader>
          </div>
        </div>
      </div>
      <div className="lg:max-w-40 flex items-center flex-1 w-full justify-end">
        <div className="flex lg:flex-col md:flex-col flex-row  w-full gap-[10px] items-end ">
          <Skeleton.Loader isDarkTheme className="h-10 w-full" containerClassName="w-full">
            <Button
              variant="darkThemeFilled"
              className="md:min-w-[160px] flex-1 !font-normal"
              onClick={() => handleClick && handleClick(false)}
            >
              Deposit
            </Button>
          </Skeleton.Loader>
          <Skeleton.Loader isDarkTheme className="w-full h-10" containerClassName="w-full">
            <Button
              variant="darkThemeOutlined"
              className="md:min-w-[160px] flex-1 !font-normal"
              onClick={() => handleClick && handleClick(true)}
            >
              Withdraw
            </Button>
          </Skeleton.Loader>
        </div>
      </div>
    </div>
  );
};

export default WalletOverview;
