export function AnnouncementBanner() {
  return (
    <div className="relative overflow-hidden bg-[#ffffff0d] lg:text-sm text-xs text-white text-center xs:tracking-[1.4px] tracking-normal lg:py-[10px] mg:py-[6px] py-1 px-4 mb-[2px] lg:-top-8 md:-top-5 top-[-30px] -left-6 sm:w-[calc(100vw_-_32px)] w-screen max-w-[1392px] box-border  ">
      <div>
        We are engaged in resolving critical issues to improve your experience. We apologize for any
        inconvenience caused.
      </div>
    </div>
  );
}
