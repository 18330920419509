/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import BigNumber from 'bignumber.js';
import { useApi, useMyProfile } from 'contexts';
import { useWidthdrawTokensMutation } from 'gql';
import { useCookies } from 'react-cookie';

const useWithdrawFund = () => {
  const LOWER_LIMIT = 0.2;
  const [amount, setAmount] = useState<number | string>('');
  const [fundRefAddress, setFundRefAddress] = useState<string | null | undefined>(null);
  const [error, setError] = useState<string | undefined>('');
  const [amountError, setAmountError] = useState('');
  const {
    account,
    chainProps: { tokenDecimals },
  } = useApi();
  const { refetchAccountBalance, accountBalance } = useMyProfile();
  const [{ sessionKey: sessionKeyCookie }] = useCookies(['sessionKey']);

  useEffect(() => {
    const isExceeds = new BigNumber(amount || 0)
      .multipliedBy(10 ** tokenDecimals)
      .isGreaterThan(accountBalance || 0);
    if (Number(accountBalance) <= 0) {
      setAmountError('Insufficient balance');
    } else if (isExceeds) {
      setAmountError('Amount exceeds your account balance');
    } else if (Number(amount) && Number(amount)! < LOWER_LIMIT) {
      setAmountError('Amount is below the limit');
    } else {
      setAmountError('');
    }
    if (error) {
      setError('');
    }
    if (fundRefAddress) {
      setFundRefAddress(null);
    }
  }, [amount, tokenDecimals]);

  const [WithdrawToken, { loading: isWithdrawLoading }] = useWidthdrawTokensMutation();

  const handleSubmit = () => {
    WithdrawToken({
      variables: {
        data: { amount: new BigNumber(amount).multipliedBy(10 ** tokenDecimals).toString() },
        address: account?.address,
        sessionKey: sessionKeyCookie,
      },
      onCompleted: (data) => {
        setFundRefAddress(data.widthdrawTokensMutation.status as string);
        refetchAccountBalance();
      },
      onError: () => {
        setError(
          'An error occurred with the provided withdraw amount. Please try again with a valid amount.'
        );
      },
    });
  };

  return {
    amount,
    setAmount,
    amountError,
    fundRefAddress,
    error,
    isWithdrawLoading,
    handleSubmit,
    LOWER_LIMIT,
  };
};

export default useWithdrawFund;
