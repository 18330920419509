import BigNumber from 'bignumber.js';
import { DepositIcon, Identicon, Skeleton } from 'components';
import { ViewFundingLogsType } from 'gql';
import { useWindowSize } from 'hooks';
import moment from 'moment';
import { formatBalance } from 'utils';

export const FundDetail = ({
  data,
  tokenDecimals = 12,
}: {
  data: ViewFundingLogsType | null;
  tokenDecimals: number;
  key: number;
}) => {
  const {
    windowSize: { width },
  } = useWindowSize();

  if (width < 992) {
    return (
      <div className="w-full p-4 rounded-2xl mb-4 last:pb-0 bg-[#0E0E0E]">
        <div className="flex justify-between border-[#1F1F1F] border-b pb-4 mb-4">
          <div className="flex sm:w-1/2 w-1/2 flex-row gap-[6px] items-center text-sm">
            <Skeleton.Loader isDarkTheme className="h-8 w-[50px] !rounded-lg">
              <DepositIcon style={{ height: '32px', width: '32px' }} />
              Deposit
            </Skeleton.Loader>
            {/* <div className="text-sm">
              <Skeleton.Loader isDarkTheme className="h-4 md:w-20 w-16">
                {data?.fund}
              </Skeleton.Loader>
            </div> */}
          </div>
          <div className="flex sm:w-1/2 w-1/2 flex-row gap-[6px] items-center">
            <Skeleton.Loader isDarkTheme className="h-8 w-8 !rounded-lg">
              <Identicon value={data?.sponsor as string} className="h-5 w-5" />
            </Skeleton.Loader>
            <div className="text-sm truncate w-full">
              <Skeleton.Loader isDarkTheme className="h-4 md:w-52 w-20">
                {data?.sponsor}
              </Skeleton.Loader>
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-between pb-3">
          {width > 991 && (
            <div className="flex sm:w-1/2 w-1/2 flex-col gap-[6px]">
              <div className="text-xs text-[#B2B3B8]">
                <Skeleton.Loader isDarkTheme className="w-[50px] h-4">
                  Type:
                </Skeleton.Loader>
              </div>

              {/* <div className="text-sm">
              <Skeleton.Loader isDarkTheme className="w-[80px] h-4">
                {data?.fund}
              </Skeleton.Loader>
            </div> */}
            </div>
          )}
          <div className="flex sm:w-1/2 w-1/2 flex-col gap-[6px]">
            <div className="text-xs  text-[#B2B3B8]">
              <Skeleton.Loader isDarkTheme className="w-[60px] h-4">
                Timestamp:
              </Skeleton.Loader>
            </div>
            <div className="text-sm">
              <Skeleton.Loader isDarkTheme className="w-[80px] h-4">
                {moment(data?.timestamp).format('DD/MM/YYYY・hh:mmA')}
              </Skeleton.Loader>
            </div>
          </div>
          <div className="flex sm:w-1/2 w-1/2 flex-col gap-[6px]">
            <div className="text-xs  text-[#B2B3B8]">
              <Skeleton.Loader isDarkTheme className="w-[70px] h-4">
                Total Amounts:
              </Skeleton.Loader>
            </div>
            <div className="text-sm truncate">
              <Skeleton.Loader isDarkTheme className="w-[85px] h-4">
                {formatBalance(new BigNumber(data?.amount as string), {
                  symbol: '$TANLOG',
                  tokenDecimals,
                  decimalPlaces: 5,
                })}
              </Skeleton.Loader>
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-between pb-4">
          <div className="flex sm:w-1/2 w-1/2 flex-col gap-[6px]">
            <div className="text-xs  text-[#B2B3B8]">
              <Skeleton.Loader isDarkTheme className="w-[70px] h-4">
                Total Rewards:
              </Skeleton.Loader>
            </div>
            <div className="text-sm">
              <Skeleton.Loader isDarkTheme className="w-[101px] h-4">
                {'0 $TANLOG'}
              </Skeleton.Loader>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="grid lg:grid-cols-[15%_30%_25%_20%_10%] md:grid-cols-[100%] grid-cols-[100%] lg:h-[60px] md:h-full h-full bg-[#0E0E0E] items-center rounded-2xl mb-2 md:px-4 px-4 lg:pr-6 lg:pl-5 md:py-[14px] py-[14px] w-full ">
      <div className="flex flex-row gap-[6px] items-center lg:pb-0 md:pb-4 pb-4 text-sm lg:border-0 md:border-b border-b border-[#1F1F1F]">
        <Skeleton.Loader isDarkTheme className="h-8 w-8 !rounded-lg">
          <DepositIcon style={{ height: '32px', width: '32px' }} />
        </Skeleton.Loader>
        <Skeleton.Loader isDarkTheme className="h-4 w-12">
          Deposit
        </Skeleton.Loader>
        {/* <div className="text-sm truncate w-[calc(100%-45px)] lg:pr-2">
          <Skeleton.Loader isDarkTheme className="h-4 w-[80%]">
            {data?.fund}
          </Skeleton.Loader>
        </div> */}
      </div>
      <div
        data-cy={`${data?.sponsor}`}
        className="flex flex-row gap-[6px] items-center lg:pb-0 md:pb-4 pb-4  lg:border-0 md:border-b border-b border-[#1F1F1F]"
      >
        <Skeleton.Loader isDarkTheme className="h-8 w-8 !rounded-lg">
          <Identicon value={data?.sponsor as string} className="h-5 w-5" />
        </Skeleton.Loader>
        <div data-cy={`${data?.sponsor}`} className="text-sm truncate w-[calc(100%-45px)] lg:pr-2">
          <Skeleton.Loader isDarkTheme className="h-4 w-[80%]">
            {data?.sponsor}
          </Skeleton.Loader>
        </div>
      </div>
      <div data-cy={`${data?.timestamp}`} className="lg:pt-0 md:pt-[5px] pt-[5px] text-sm">
        <Skeleton.Loader isDarkTheme className="w-[170px] h-4">
          {moment(data?.timestamp).format('DD/MM/YYYY・hh:mmA')}
        </Skeleton.Loader>
      </div>
      <div
        data-cy={`${formatBalance(new BigNumber(data?.amount as string), {
          symbol: '',
          tokenDecimals,
          decimalPlaces: 5,
        })}`}
        className="lg:pt-0 md:pt-[5px] pt-[5px] text-sm"
      >
        <Skeleton.Loader isDarkTheme className="w-[63px] h-4">
          {formatBalance(new BigNumber(data?.amount as string), {
            symbol: '$TANLOG',
            tokenDecimals,
            decimalPlaces: 5,
          })}
        </Skeleton.Loader>
      </div>
      <div className="lg:pt-0 md:pt-[5px] pt-[5px] text-sm whitespace-nowrap">
        <Skeleton.Loader isDarkTheme className="w-[101px] h-4">
          {'0 $TANLOG'}
        </Skeleton.Loader>
      </div>
    </div>
  );
};

export default FundDetail;
