import { Tab } from '@headlessui/react';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { Button, ConnectAccountPrompt, Skeleton } from 'components';
import { HTMLAttributes, Setter } from 'types';
import { classes } from 'utils';

import useQueryDisplay from './useQueryDisplay';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  selectedTab: number;
  setSelectedTab: Setter<number>;
}

export const QueryDisplay = (props: Props) => {
  const {
    Component,
    isManualMode,
    setIsDeployOpen,
    validate,
    isDryRunValid,
    isTestable,
    isDryRunning,
    duplicateColumn,
    statusClassName,
    statusIcon,
    statusMessage,
    untouched,
    functionSpecs,
    isGraphIdValid,
    isGraphIdTouched,
    setIsGraphIdTouched,
    dryRunError,
    setScroll,
    tabSelector,
    tabPanels,
    account,
    searchParams,
    setSearchParams,
    isOpen,
    setIsOpen,
  } = useQueryDisplay(props);

  return (
    <Component
      {...props}
      className={classes(
        `flex h-full w-full flex-1 flex-col lg:pt-4 pt-4 overflow-x-hidden transition-colors shrink-0 bg-[linear-gradient(180deg,#000_20.37%,#000_79.34%)] border shadow-[0px_-2px_52px_0px_rgba(200,200,200,0.06)_inset] rounded-[20px] border-solid border-[#ffffff1f] scrollbar-white`,
        duplicateColumn && 'error',
        props.className
      )}
    >
      <div className="lg:px-6 px-4 lg:flex hidden justify-between items-center">
        <Skeleton.Loader isDarkTheme className="w-[89px] h-[22px]">
          <h5 className="lg:text-[20px] text-[18px] lg:flex hidden">Console</h5>
        </Skeleton.Loader>
      </div>

      <Tab.Group
        selectedIndex={props.selectedTab}
        onChange={(index) => {
          if (index === 1 && !isManualMode) {
            if (validate() && isTestable) {
              props.setSelectedTab(1);
            } else {
              setScroll(true);
              return;
            }
          } else {
            setIsGraphIdTouched(true);
            if (isGraphIdTouched && isGraphIdValid) props.setSelectedTab(1);
          }
          props.setSelectedTab(index);
        }}
      >
        <div className={'relative lg:mt-4 flex h-full w-full flex-1 flex-col  '}>
          <div className="lg:px-6 px-3 flex h-10 w-full justify-start space-x-3">{tabSelector}</div>
          <div
            className={classes(
              'xl:h-[calc(100vh_-_427px)] h-[calc(100vh_-_427px)] mt-4 overflow-y-auto scrollbar-white',
              props.selectedTab === 1 &&
                'lg:h-[calc(100vh_-_420px)] xl:h-[calc(100vh_-_420px)] h-[calc(100vh_-_450px)]'
            )}
          >
            <div
              className={`${
                duplicateColumn ||
                (account &&
                  !isDryRunning &&
                  statusMessage !== 'Success!' &&
                  props.selectedTab === 1) ||
                !!dryRunError
                  ? 'bg-[#ff5c5c14] dryRun-error'
                  : ''
              } lg:px-6 lg:pl-1 px-0 flex-1`}
            >
              {tabPanels}
            </div>
          </div>
          <div className="xl:max-h-[80px] max-h-[120px] bg-white flex shrink-0 justify-end mt-auto">
            <div
              className={`lg:px-6 lg:py-4 p-4 flex  xl:flex-row flex-col flex-1 gap-3 bg-black  border-0 border-t border-solid border-[#ffffff1f] ${
                duplicateColumn || (!isDryRunning && statusMessage && props.selectedTab === 1)
                  ? 'justify-between'
                  : 'justify-end'
              } `}
            >
              {isGraphIdTouched && !isGraphIdValid && props.selectedTab === 0 && (
                <div className="flex items-center text-[#DD0050] flex-1 gap-2 lg:hidden">
                  <div
                    key="circle-icon"
                    className="bg-[#DD0050] h-10 w-10 flex items-center justify-center rounded-full"
                  >
                    <ExclamationCircleIcon color="white" className="w-5 h-5" />
                  </div>
                  <span className="text-xs"> Please enter name of the view</span>
                </div>
              )}
              {(statusMessage?.toString().startsWith('Please ensure') ||
                props.selectedTab === 1) && (
                <div
                  className={classes(
                    'justify-start items-center flex-row flex',
                    !untouched && 'flex-1'
                  )}
                >
                  {statusMessage && (
                    <div className={classes(statusClassName)}>
                      {statusIcon}
                      <div
                        data-cy="view-dryRun-result"
                        className="flex-1 pl-[10px] text-xs [word-break:break-word] line-clamp-2"
                      >
                        {statusMessage}
                      </div>
                    </div>
                  )}
                </div>
              )}
              {props.selectedTab === 1 ? (
                <Button
                  data-cy="deploy-view-btn"
                  className="h-[40px] sm:max-w-[311px] w-full text-black px-5 rounded-full block sm:w-32 bg-white"
                  variant="plain"
                  isDisabled={!isDryRunValid || !account}
                  onClick={() => {
                    if (isManualMode) {
                      if (isDryRunValid) {
                        setIsDeployOpen(true);
                      }
                    } else {
                      if (validate()) {
                        setIsDeployOpen(true);
                      }
                    }
                  }}
                >
                  Deploy View
                </Button>
              ) : (
                !duplicateColumn && (
                  <Button
                    variant="plain"
                    className="text-black px-5 rounded-full h-[40px] block sm:w-32 w-full bg-white"
                    isDisabled={
                      isManualMode ? false : duplicateColumn || functionSpecs?.length === 0
                    }
                    onClick={() => {
                      if (!isManualMode) {
                        if (!account?.address) {
                          searchParams.set('connect', 'true');
                          setSearchParams(searchParams);
                          return;
                        }
                        if (validate() && isTestable) {
                          props.setSelectedTab(1);
                        } else {
                          setScroll(true);
                        }
                      } else {
                        setIsGraphIdTouched(true);
                        if (isGraphIdTouched && isGraphIdValid) props.setSelectedTab(1);
                      }
                    }}
                  >
                    <span className="text-base">Test Query</span>
                  </Button>
                )
              )}
            </div>
          </div>
        </div>
        <ConnectAccountPrompt isOpen={isOpen} setIsOpen={setIsOpen} />
      </Tab.Group>
    </Component>
  );
};
