import { Tag } from 'components';
import { TimegraphTag } from 'gql';
import { Maybe } from 'types';

export const ChainChip = ({ tag }: { tag: Maybe<TimegraphTag> | undefined }) => {
  return (
    <Tag
      isDarkTheme
      {...(tag?.tagType === 'chain'
        ? {
            icon: <img src={`/logos/chain/${tag.tagName?.toLowerCase()}.svg`} alt={tag?.tagName} />,
          }
        : {})}
      classNames={{ name: 'text-[10px] pl-0', base: 'list-none' }}
    >
      {tag?.tagName}
    </Tag>
  );
};
