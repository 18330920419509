import { Tab } from '@headlessui/react';
import { BreadCrumbs, Header, Skeleton } from 'components';

import { useApiKey } from './useApiKey';

export function ApiKey() {
  const { apiKey, selectedTab, setSelectedTab, tabPanels, tabSelector } = useApiKey();

  return (
    <Skeleton.Provider isLoading={!apiKey}>
      <div className="sm:mb-20 max-w-[1200px] m-auto w-full justify-start">
        <BreadCrumbs />
        <Tab.Group selectedIndex={selectedTab} onChange={setSelectedTab}>
          <div className="flex w-full flex-col">
            <Header
              className="mb-8 "
              tag="h2"
              accessory={!!apiKey && tabSelector}
              hClassName="text-white sm:text-[2rem] !leading-10 w-full text-[24px] truncate"
              accessoryClassname="items-center"
            >
              API Key: {apiKey?.key}
            </Header>
            {tabPanels}{' '}
          </div>
        </Tab.Group>
      </div>
    </Skeleton.Provider>
  );
}

export default ApiKey;
