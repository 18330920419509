import Tooltip from '@mui/material/Tooltip';
import { Button, Description, Skeleton } from 'components';
import { ChainChip } from 'components/ChainChip';
import { Link } from 'react-router-dom';
import { arrayOfSize, classes } from 'utils';

import HeaderImage from './components/HeaderImage';
import ScStatistics from './components/ScStatistics';
import useSmartContractDetails from './useSmartContractDetails';

export const SmartContractDetails = () => {
  const {
    smartContract,
    isResourceLoading,
    sortedTags,
    tooltipIsOpen,
    setTooltipIsOpen,
    width,
    handleKeyDown,
    setFunctionSpecs,
    setGraphId,
    setIsGraphIdTouched,
  } = useSmartContractDetails();

  return (
    <div className="card !mb-8 sm:mb-12 relative sm:p-6 p-4 !bg-[#000] rounded-[24px] border !border-[#1F1F1F] sm:!border-transparent">
      <div className="flex w-full items-start gap-3 sm:gap-6 sm:flex-row flex-col">
        <div className="flex justify-start sm:flex-row flex-col gap-3 sm:gap-6 items-start w-full">
          <HeaderImage />
          <div className="flex w-full flex-row items-start justify-between gap-3 sm:gap-6 text-white">
            <div>
              <Skeleton.Loader
                isDarkTheme
                containerClassName="w-full"
                className="sm:w-[70%] w-full sm:h-7 h-[18px] mt-3"
              >
                <h2 data-cy={`${smartContract?.name}`} className="hidden sm:flex text-2xl">
                  {smartContract?.name}
                </h2>
              </Skeleton.Loader>
              <div className="w-full text-sm sm:text-base text-[#B2B3B8] sm:!mt-[6px] !mt-0 whitespace-pre-line break-words">
                <Skeleton.Loader isDarkTheme className="h-[19px] sm:w-[35%] w-[50%]">
                  <Description value={smartContract?.description} />
                </Skeleton.Loader>
              </div>
              <div
                className={classes(
                  'flex-wrap items-center text-[0.66rem] text-sm flex sm:!mt-4 !mt-3 gap-2',
                  isResourceLoading && 'flex-row'
                )}
              >
                {/* Display loader with 3 chips if loading else show first 5 chips */}
                {(isResourceLoading
                  ? arrayOfSize(3).map(() => undefined)
                  : sortedTags?.slice(0, 5)
                )?.map((tag, index) => {
                  return <ChainChip tag={tag} key={index} />;
                })}
                {/* Display extra chips inside see more tooltip */}
                {!!sortedTags?.length && sortedTags?.length - 5 > 0 && (
                  <Tooltip
                    placement="bottom"
                    classes={{ tooltip: '!max-w-[420px]' }}
                    open={tooltipIsOpen}
                    onOpen={() => setTooltipIsOpen(true)}
                    onClose={() => setTooltipIsOpen(false)}
                    title={
                      <div className="flex flex-wrap space-x-1">
                        {sortedTags?.slice(5)?.map((tag, index) => {
                          return <ChainChip tag={tag} key={index} />;
                        })}
                      </div>
                    }
                    arrow
                  >
                    <div
                      className="mb-2 bg-[linear-gradient(0deg,_#8D74F7_3%,_#D285F7_45%,_#FFAD97_95%)] bg-clip-text text-transparent cursor-pointer select-none"
                      {...(width < 769 && {
                        onClick: () => setTooltipIsOpen(!tooltipIsOpen),
                        onKeyDown: handleKeyDown,
                        role: 'button',
                        tabIndex: 0,
                      })}
                    >
                      <Skeleton.Loader isDarkTheme>See More</Skeleton.Loader>
                    </div>
                  </Tooltip>
                )}
              </div>
            </div>

            {/*  Create View button */}
            <div>
              <Skeleton.Loader
                isDarkTheme
                containerClassName="sm:w-[auto] w-full"
                className="sm:w-[150px] w-full h-[40px]"
              >
                <Link
                  className="sm:w-auto w-full min-w-[150px]"
                  to={`/view-builder?address=${smartContract?.address}`}
                >
                  <Button
                    data-cy="Create-view-btn"
                    className="w-full"
                    variant="darkThemeFilled"
                    onClick={() => {
                      setFunctionSpecs([]);
                      setGraphId('');
                      setIsGraphIdTouched(false);
                    }}
                  >
                    Create View
                  </Button>
                </Link>
              </Skeleton.Loader>
            </div>
          </div>
        </div>
      </div>

      {/* Publisher, Contract address, Date Created */}
      <ScStatistics />
    </div>
  );
};
