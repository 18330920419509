import { useEffect, useMemo, useState } from 'react';

export type UseInfiniteScrollProps = {
  fetchNextPage: () => void;
  options?: IntersectionObserverInit;
};

/**
 * A custom hook that enables infinite scrolling functionality using IntersectionObserver.
 * Automatically fetches the next page when the threshold element is in view.
 */
const useInfiniteScroll = <ThresholdElement extends Element = Element>({
  fetchNextPage,
  options,
}: UseInfiniteScrollProps) => {
  const [thresholdElement, thresholdElementRef] = useState<ThresholdElement | null>(null);

  // Memoized observer instance to avoid unnecessary re-creation on every render
  const observer = useMemo(
    () =>
      new IntersectionObserver(
        ([entry]) => {
          // Trigger fetchNextPage only when the element is intersecting the viewport
          if (entry.isIntersecting) {
            fetchNextPage();
          }
        },
        { ...options }
      ),
    [fetchNextPage, options]
  );

  useEffect(() => {
    if (!thresholdElement) return;

    observer.observe(thresholdElement);

    return () => {
      observer.unobserve(thresholdElement);
    };
  }, [observer, thresholdElement]);

  return { thresholdElementRef };
};

export default useInfiniteScroll;
