import { Tooltip } from '@mui/material';
import {
  FormatBalance,
  Icon,
  InformationIcon,
  ResourceLogo,
  RewardsIcon,
  SpentIcon,
  Statistics,
} from 'components';
import { SearchLibraryCountQuery } from 'gql';
import { Maybe } from 'types';
import { classes } from 'utils';

const ProfileDetailsStatistics = ({
  isDarkTheme,
  showIcon,
  countData,
  totalRewardEarned,
  totalSpent,
}: {
  isDarkTheme?: boolean;
  showIcon?: boolean;
  countData?: Maybe<SearchLibraryCountQuery>;
  totalRewardEarned?: string;
  totalSpent?: string;
}) => {
  return (
    <Statistics
      withBorder
      classNames={{
        stat: classes('space-x-3', isDarkTheme && 'm-0 mr-auto h-full'),
        strings: 'flex-col-reverse justify-between space-y-2 h-full',
        value: 'text-2xl font-medium !m-0',
        base: classes(
          'lg:h-full  h-full ',
          isDarkTheme && 'sm:flex grid auto-cols-max grid-cols-2 md:grid-cols-4'
        ),
        label: 'whitespace-pre-line !text-[#B2B3B8] normal-case',
      }}
      isDarkTheme={isDarkTheme}
      value={[
        {
          icon: showIcon && (
            <ResourceLogo className="h-12 w-12" value={{ __typename: 'SmartContract' }} />
          ),
          label: 'Smart Contracts',
          value: countData?.searchLibraryCount?.smartContracts || 0,
        },
        {
          icon: showIcon && <ResourceLogo className="h-12 w-12" value={{ __typename: 'View' }} />,
          label: 'Created Views',
          value: countData?.searchLibraryCount?.views || 0,
        },
        {
          icon: showIcon && <Icon className="h-12 w-12" icon={RewardsIcon} />,
          label: 'Total Rewards',
          value: (
            <div className="flex flex-row items-center gap-1">
              <FormatBalance value={totalRewardEarned || ''} />
              <Tooltip
                title={
                  <FormatBalance value={totalRewardEarned || '0'} options={{ decimalPlaces: 12 }} />
                }
                arrow
                placement="top"
                classes={{ tooltip: '!min-w-[200px] justify-center !text-sm' }}
              >
                <div>
                  <InformationIcon />
                </div>
              </Tooltip>
            </div>
          ),
        },
        {
          icon: showIcon && <Icon className="h-12 w-12" icon={SpentIcon} />,
          label: 'Total Spent',
          value: (
            <div className="flex flex-row items-center gap-1">
              <FormatBalance value={totalSpent || '0'} />
              <Tooltip
                title={<FormatBalance value={totalSpent || '0'} options={{ decimalPlaces: 12 }} />}
                arrow
                placement="top"
                classes={{ tooltip: '!min-w-[200px] justify-center !text-sm' }}
              >
                <div>
                  <InformationIcon />
                </div>
              </Tooltip>
            </div>
          ),
        },
      ]}
    />
  );
};

export default ProfileDetailsStatistics;
