import { HTMLAttributes } from 'types';

export function CustomExcalmationMark(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      {...props}
    >
      <path
        d="M49.9987 91.6663C72.9154 91.6663 91.6654 72.9163 91.6654 49.9997C91.6654 27.083 72.9154 8.33301 49.9987 8.33301C27.082 8.33301 8.33203 27.083 8.33203 49.9997C8.33203 72.9163 27.082 91.6663 49.9987 91.6663Z"
        stroke="white"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50 33.333V54.1663"
        stroke="white"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.9766 66.667H50.014"
        stroke="white"
        strokeWidth="1.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
