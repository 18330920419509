import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { CustomInfoIcon, Skeleton } from 'components';
import { classes } from 'utils';

import useViewBuilderHook from './useViewBuilderHook';

export const ViewBuilder = () => {
  const { isClicked, setIsClicked, errorFunctionSpecs, scrollToError, errorSpec, content } =
    useViewBuilderHook();

  return (
    <div className="dark mb-6 text-white">
      <Skeleton.Provider>
        <div className="flex lg:flex-row flex-col justify-between items-start">
          <div className="flex flex-col w-full lg:w-auto">
            <Skeleton.Loader isDarkTheme className="w-[173px] h-[42px]">
              <div
                data-cy="View-builder-page-title"
                className="lg:text-[32px] leading-10 text-[24px] bg-[radial-gradient(100%_100%_at_50%_100%,_var(--tw-gradient-stops))] from-[#ffffffd9_0%] to-[#fff_100%] bg-clip-text text-transparent w-fit"
              >
                View Builder
              </div>
            </Skeleton.Loader>
            <div className="text-[#ffffffe6] lg:text-lg text-sm mt-1 mb-8 flex flex-row gap-[2px]">
              <Skeleton.Loader
                isDarkTheme
                className="h-[17px] !rounded-full"
                containerClassName="w-full max-w-[621px]"
              >
                <div className={classes('sm:w-[unset]', !isClicked && 'truncate w-[313px]')}>
                  View Builder is the most powerful and optimized environment for publishing views.
                </div>
                {!isClicked && (
                  <button
                    className="cursor-pointer sm:hidden flex whitespace-pre"
                    onClick={() => setIsClicked(true)}
                  >
                    See More
                  </button>
                )}
              </Skeleton.Loader>
            </div>
          </div>
          {!!errorFunctionSpecs?.length && (
            <div className="bg-[linear-gradient(180deg,_#000_20.37%,_#000_79.34%)] rounded-3xl items-center flex px-3 leading-[48px] mb-4 lg:mb-0  text-[#FF6666]">
              <div className="flex flex-row items-center gap-2">
                <CustomInfoIcon stroke="#FF6666" className="w-6 h-6" />
                <div className="text-xs lg:text-sm">
                  {`${
                    errorFunctionSpecs.findIndex((el) => el?.identifier === errorSpec?.identifier) +
                    1
                  }/${errorFunctionSpecs?.length}`}{' '}
                  View Error
                </div>
              </div>
              <div className="min-w-[1px] w-[1px] h-[48px] bg-[#1F1F1F] mx-3 "></div>
              <div className="text-xs lg:flex  flex-row gap-1 items-center">
                <button onClick={() => scrollToError('up')}>
                  <ChevronUpIcon className="w-5 h-5" />
                </button>
                <button onClick={() => scrollToError('down')}>
                  <ChevronDownIcon className="w-5 h-5" />
                </button>
              </div>
            </div>
          )}
        </div>
        {content}
      </Skeleton.Provider>
    </div>
  );
};

export default ViewBuilder;
