import { DepositIcon, Skeleton } from 'components';
import { BalanceHistoryStatsType } from 'gql';
import moment from 'moment';

const MobileBalanceHistory = ({ row }: { row: BalanceHistoryStatsType | null }) => {
  return (
    <div className="w-full md:p-0 p-4 md:rounded-none rounded-2xl md:mb-0 mb-4 md:bg-transparent bg-[#0E0E0E]">
      <div className="flex justify-between border-[#1F1F1F] border-b pb-4 mb-4">
        <div className="flex sm:w-1/2 w-1/2 flex-row gap-[6px] items-center">
          <Skeleton.Loader isDarkTheme className="h-8 w-8 !rounded-lg ">
            <DepositIcon style={{ height: '32px', width: '32px' }} />
          </Skeleton.Loader>
          <div className="text-sm">
            <Skeleton.Loader isDarkTheme className="h-4 md:w-20 w-16">
              {row?.changeType}
            </Skeleton.Loader>
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-between pb-3">
        <div className="flex sm:w-1/2 w-1/2 flex-col gap-[6px]">
          <div className="text-xs text-[#B2B3B8]">
            <Skeleton.Loader isDarkTheme className="w-[50px] h-4">
              Type:
            </Skeleton.Loader>
          </div>
          <div className="text-sm capitalize">
            <Skeleton.Loader isDarkTheme className="w-[80px] h-4">
              {row?.changeType}
            </Skeleton.Loader>
          </div>
        </div>
        <div className="flex sm:w-1/2 w-1/2 flex-col gap-[6px]">
          <div className="text-xs  text-[#B2B3B8]">
            <Skeleton.Loader isDarkTheme className="w-[60px] h-4">
              Timestamp:
            </Skeleton.Loader>
          </div>
          <div className="text-sm">
            <Skeleton.Loader isDarkTheme className="w-[80px] h-4">
              {moment(row?.timestamp).format('DD/MM/YYYY・hh:mmA')}
            </Skeleton.Loader>
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-between md:pb-4 pb-0">
        <div className="flex sm:w-1/2 w-1/2 flex-col gap-[6px]">
          <div className="text-xs  text-[#B2B3B8]">
            <Skeleton.Loader isDarkTheme className="w-[70px] h-4">
              Total Amounts:
            </Skeleton.Loader>
          </div>
          <div className="text-sm">
            <Skeleton.Loader isDarkTheme className="w-[85px] h-4">
              {/* <FormatBalance
                  value={row?.amount as string}
                  options={{ decimalPlaces: 5 }}
                  setIsFormatLoading={setIsFormatLoading}
                /> */}
            </Skeleton.Loader>
          </div>
        </div>
        <div className="flex sm:w-1/2 w-1/2 flex-col gap-[6px]">
          <div className="text-xs  text-[#B2B3B8]">
            <Skeleton.Loader isDarkTheme className="w-[70px] h-4">
              Total Rewards:
            </Skeleton.Loader>
          </div>
          <div className="text-sm">
            <Skeleton.Loader isDarkTheme className="w-[101px] h-4">
              0
            </Skeleton.Loader>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileBalanceHistory;
