import { HTMLAttributes } from 'types';

export function MagicPen(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.9167 17.0835C3.60837 17.7752 4.72503 17.7752 5.4167 17.0835L16.25 6.2502C16.9417 5.55853 16.9417 4.44186 16.25 3.7502C15.5584 3.05853 14.4417 3.05853 13.75 3.7502L2.9167 14.5835C2.22503 15.2752 2.22503 16.3919 2.9167 17.0835Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0083 7.4917L12.5083 4.9917"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.0835 2.03317L8.3335 1.6665L7.96683 2.9165L8.3335 4.1665L7.0835 3.79984L5.8335 4.1665L6.20016 2.9165L5.8335 1.6665L7.0835 2.03317Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.75 7.03317L5 6.6665L4.63333 7.9165L5 9.1665L3.75 8.79984L2.5 9.1665L2.86667 7.9165L2.5 6.6665L3.75 7.03317Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.25 11.2002L17.5 10.8335L17.1333 12.0835L17.5 13.3335L16.25 12.9668L15 13.3335L15.3667 12.0835L15 10.8335L16.25 11.2002Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
