import { HTMLAttributes } from 'types';

export function InformationIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      {...props}
    >
      <path
        d="M10.0013 18.8334C14.6037 18.8334 18.3346 15.1025 18.3346 10.5001C18.3346 5.89771 14.6037 2.16675 10.0013 2.16675C5.39893 2.16675 1.66797 5.89771 1.66797 10.5001C1.66797 15.1025 5.39893 18.8334 10.0013 18.8334Z"
        stroke="#9D9D9D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 13.8333V10.4999M10 7.16659H10.0083"
        stroke="#9D9D9D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
