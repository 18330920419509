import { classes } from 'utils';

import { Button } from './Button';

export interface RefreshButtonProps {
  baseClassName?: string;
  iconClasses?: string;
  onClick: () => void;
  isLoading?: boolean;
}

export const GradientRefreshButton = ({
  baseClassName,
  iconClasses,
  onClick,
  isLoading,
}: RefreshButtonProps) => {
  return (
    <Button
      data-cy="refresh-btn"
      className={classes(
        'flex justify-center btn max-h-full plain w-10 h-10 border-[1px] border-[#ffffff1a] rounded-full p-[10px] relative bg-gradient-to-b from-[#ffad971a] via-[#d285f71a] to-[#8d74f71a]',
        baseClassName
      )}
      onClick={onClick}
    >
      <div
        className={classes(
          `flex items-center justify-center whitespace-pre text-sm ${isLoading && ' animate-spin'}`,
          iconClasses
        )}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M17.3327 8.99935C17.3327 13.5993 13.5993 17.3327 8.99935 17.3327C4.39935 17.3327 1.59102 12.6993 1.59102 12.6993M1.59102 12.6993H5.35768M1.59102 12.6993V16.866M0.666016 8.99935C0.666016 4.39935 4.36602 0.666016 8.99935 0.666016C14.5577 0.666016 17.3327 5.29935 17.3327 5.29935M17.3327 5.29935V1.13268M17.3327 5.29935H13.6327"
            stroke="url(#paint0_linear_2018_305096)"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <defs>
            <linearGradient
              id="paint0_linear_2018_305096"
              x1="8.99935"
              y1="17.3327"
              x2="9"
              y2="-4"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.03" stopColor="#8D74F7" />
              <stop offset="0.45" stopColor="#D285F7" />
              <stop offset="0.95" stopColor="#FFAD97" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </Button>
  );
};
