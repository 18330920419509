/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { default as BigNumber } from 'bignumber.js';
import { MyProfileFragment, useUserBalanceLazyQuery } from 'gql';
import { useBalance, useMyProfileLazyQuery } from 'hooks';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { Maybe, Setter, VoidFn } from 'types';
import { useLocalStorage } from 'usehooks-ts';

import { useApi } from './Api';

interface Value {
  myProfile?: Maybe<MyProfileFragment>;
  myBalance?: BigNumber;
  isActive: boolean;
  isLoading: boolean;
  setIsActive: Setter<boolean>;
  isCreateVisible: boolean;
  setIsCreateVisible: Setter<boolean>;
  accountBalance: BigNumber;
  refetchAccountBalance: () => void;
  RefreshUserBalance?: VoidFn;
  isUserBalanceLoading?: boolean;
  tab: number;
  setTab: Setter<number>;
}

export const MyProfileContext = createContext<Value>({
  myBalance: undefined,
} as unknown as Value);

export function MyProfileProvider({ children }: React.PropsWithChildren) {
  const navigate = useNavigate();
  const { account, sessionKey, isReady } = useApi();
  const [isActive, setIsActive] = useLocalStorage('my-profile-isActive', true);
  const [isCreateVisible, setIsCreateVisible] = useState(false);
  const [tab, setTab] = useState(0);
  const [{ account: accountCookie, sessionKey: sessionKeyCookie }] = useCookies([
    'account',
    'sessionKey',
  ]);

  const [fetchMyProfile, { data: myProfileQuery, loading: isMyProfileLoading }] =
    useMyProfileLazyQuery({ fetchPolicy: 'cache-first' });

  const [
    getUserBalance,
    { data: accountBalance, refetch: refetchAccountBalance, loading: isUserBalanceLoading },
  ] = useUserBalanceLazyQuery({
    variables: { sessionKey: sessionKeyCookie },
    fetchPolicy: 'cache-first',
  });

  const RefreshUserBalance = useCallback(() => {
    getUserBalance({
      variables: { sessionKey: sessionKeyCookie },
      fetchPolicy: 'cache-and-network',
    });
  }, [getUserBalance, sessionKeyCookie]);

  const myBalance = useBalance(account?.address);

  const value = useMemo(() => {
    return {
      myProfile: myProfileQuery?.myProfile,
      isActive,
      isLoading: isMyProfileLoading || !myBalance || !isReady,
      myBalance,
      setIsActive,
      isCreateVisible,
      setIsCreateVisible,
      accountBalance: new BigNumber(Number(accountBalance?.userBalance?.balance)),
      refetchAccountBalance,
      RefreshUserBalance,
      isUserBalanceLoading,
      tab,
      setTab,
    };
  }, [
    myProfileQuery?.myProfile,
    isActive,
    isMyProfileLoading,
    myBalance,
    isReady,
    setIsActive,
    isCreateVisible,
    accountBalance?.userBalance?.balance,
    refetchAccountBalance,
    RefreshUserBalance,
    isUserBalanceLoading,
    tab,
  ]);

  useEffect(() => {
    if (account?.address && sessionKey) {
      fetchMyProfile({ variables: { sessionKey } });
      getUserBalance({
        variables: { sessionKey: sessionKey },
        fetchPolicy: 'cache-and-network',
      });
    } else if (!accountCookie?.address && !sessionKeyCookie) {
      navigate(routes.base);
    }
  }, [account?.address, sessionKey, fetchMyProfile]);

  return <MyProfileContext.Provider value={value}>{children}</MyProfileContext.Provider>;
}

export const useMyProfile = () => useContext(MyProfileContext);
