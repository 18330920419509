import { useContext } from 'react';

import { LibraryContext, LibraryState } from 'modules/Library/context/Library';

export const useLibrary = (): LibraryState => {
  const context = useContext(LibraryContext);

  if (!context) {
    throw new Error('Error: The useLibrary hook must be used within a LibraryProvider component.');
  }

  return context;
};
