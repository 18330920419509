export function AnalogLogo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 126 40" width={126} height={40}>
      <title>{'Group-svg'}</title>
      <defs>
        <linearGradient
          id="g1"
          x2={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(0,-28.678,35.278,0,18.49,29.342)"
        >
          <stop offset={0.03} stopColor="#8d74f7" />
          <stop offset={0.45} stopColor="#d285f7" />
          <stop offset={0.95} stopColor="#ffad97" />
        </linearGradient>
        <linearGradient
          id="g2"
          x2={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(0,-28.678,35.282,0,18.49,39.336)"
        >
          <stop offset={0.03} stopColor="#8d74f7" />
          <stop offset={0.45} stopColor="#d285f7" />
          <stop offset={0.95} stopColor="#ffad97" />
        </linearGradient>
      </defs>
      <style>
        {
          '\n\t\t.s0 { fill: #ffffff } \n\t\t.s1 { fill: url(#g1) } \n\t\t.s2 { fill: url(#g2) } \n\t'
        }
      </style>
      <path
        id="Layer"
        fillRule="evenodd"
        className="s0"
        d="m76.8 20.6l5.5 12.7q0.2 0.4 0.2 0.7c0 0.8-0.6 1.4-1.3 1.4q-1 0-1.3-1l-1.1-2.6h-7.4l-1.1 2.6c-0.2 0.6-0.7 1-1.3 1-0.7 0-1.4-0.6-1.4-1.4q0-0.3 0.2-0.7l5.6-12.7c0.3-0.6 1-1 1.7-1 0.7 0 1.4 0.4 1.7 1zm-1.7 2.5l-2.6 6.1h5.2z"
      />
      <path
        id="Layer"
        className="s0"
        d="m68.7 20.9c0-0.8-0.7-1.3-1.4-1.3q-1 0-1.3 0.9l-4.1 11.3-4-11.3q-0.4-0.9-1.3-0.9-0.1 0-0.1 0h-0.1q-0.9 0-1.3 0.9l-4 11.3-4.1-11.3q-0.3-0.9-1.3-0.9c-0.7 0-1.3 0.6-1.3 1.3q0 0.3 0.2 0.7l4.8 12.7c0.3 0.7 1 1.1 1.7 1.1 0.7 0 1.4-0.4 1.7-1.1l3.7-9.8 3.7 9.8c0.3 0.7 1 1.1 1.7 1.1 0.7 0 1.4-0.4 1.7-1.1l4.8-12.7q0.3-0.4 0.3-0.7z"
      />
      <path
        id="Layer"
        className="s0"
        d="m93.7 19.6h-5.4q0 0-0.1 0h-5.7c-0.7 0-1.3 0.5-1.3 1.2 0 0.7 0.6 1.2 1.3 1.2h4.4v11.7c0 0.8 0.6 1.4 1.4 1.4 0.8 0 1.4-0.6 1.4-1.4v-11.7h4c0.8 0 1.3-0.5 1.3-1.2 0-0.7-0.5-1.2-1.3-1.2z"
      />
      <path
        id="Layer"
        className="s0"
        d="m109 31.8c-0.5-0.5-1.2-0.5-1.8-0.1-0.9 0.7-2 1.1-3.3 1.1-3.1 0-5.3-2.2-5.3-5.4 0-3.1 2.2-5.3 5.3-5.3 1.3 0 2.4 0.4 3.3 1.1 0.6 0.4 1.3 0.4 1.8-0.1 0.2-0.3 0.4-0.6 0.4-1-0.1-0.4-0.3-0.7-0.6-1-1.4-1-3.1-1.5-4.9-1.5-4.6 0-8.2 3.4-8.2 7.9 0 4.4 3.6 7.9 8.2 7.9 1.8 0 3.5-0.6 4.9-1.6 0.3-0.2 0.5-0.6 0.6-1 0-0.4-0.1-0.7-0.4-1z"
      />
      <path
        id="Layer"
        className="s0"
        d="m123.8 19.8c-0.8 0-1.4 0.6-1.4 1.4v5h-7.8v-5.2c0-0.7-0.6-1.3-1.4-1.3-0.7 0-1.4 0.6-1.4 1.3v12.9c0 0.7 0.7 1.3 1.4 1.3 0.8 0 1.4-0.6 1.4-1.3v-4.9h7.8v5c0 0.8 0.6 1.4 1.4 1.4 0.7 0 1.4-0.6 1.4-1.4v-12.8c0-0.8-0.7-1.4-1.4-1.4z"
      />
      <path
        id="Layer"
        className="s0"
        d="m60.8 4.3c-0.4 0-0.7 0.3-0.7 0.7v5.5l-4.6-5.8c-0.2-0.3-0.3-0.4-0.7-0.4-0.4 0-0.7 0.3-0.7 0.7v7.6c0 0.4 0.3 0.7 0.7 0.7 0.4 0 0.8-0.3 0.8-0.7v-5.5l4.5 5.8c0.2 0.3 0.4 0.4 0.7 0.4 0.4 0 0.7-0.3 0.7-0.7v-7.6c0-0.4-0.3-0.7-0.7-0.7z"
      />
      <path
        id="Layer"
        className="s0"
        d="m77.9 11.9h-3.5v-6.9c0-0.4-0.3-0.7-0.7-0.7-0.5 0-0.8 0.3-0.8 0.7v7.5c0 0.4 0.4 0.7 0.8 0.7h4.2c0.4 0 0.6-0.3 0.6-0.6 0-0.4-0.2-0.7-0.6-0.7z"
      />
      <path
        id="Layer"
        fillRule="evenodd"
        className="s0"
        d="m83.5 13.3c-2.6 0-4.7-2-4.7-4.5 0-2.5 2.1-4.5 4.7-4.5 2.6 0 4.7 2 4.7 4.5 0 2.5-2.1 4.5-4.7 4.5zm3.2-4.5c0-1.8-1.5-3.2-3.2-3.2-1.8 0-3.2 1.4-3.2 3.2 0 1.8 1.4 3.2 3.2 3.2 1.7 0 3.2-1.4 3.2-3.2z"
      />
      <path
        id="Layer"
        className="s0"
        d="m97.5 8.6h-2.1c-0.4 0-0.7 0.2-0.7 0.6 0 0.3 0.3 0.6 0.7 0.6h1.4v1c-0.6 0.8-1.6 1.3-2.7 1.3-1.9 0-3.3-1.4-3.3-3.3 0-1.9 1.4-3.2 3.2-3.2 1 0 1.7 0.3 2.5 1.1 0.2 0.2 0.4 0.3 0.6 0.3 0.4 0 0.6-0.3 0.6-0.6q0-0.3-0.3-0.7c-0.7-0.9-2-1.4-3.4-1.4-2.6 0-4.7 1.9-4.7 4.5 0 2.6 2.1 4.5 4.7 4.5 1.6 0 3.1-0.7 4-1.9 0.2-0.2 0.2-0.5 0.2-0.8v-1.3c0-0.4-0.3-0.7-0.7-0.7z"
      />
      <path
        id="Layer"
        fillRule="evenodd"
        className="s0"
        d="m49.5 4.8l3.2 7.5q0.1 0.2 0.1 0.4c0 0.3-0.3 0.6-0.7 0.6q-0.5 0-0.7-0.5l-0.6-1.5h-4.5l-0.6 1.5q-0.2 0.5-0.7 0.5c-0.4 0-0.7-0.3-0.7-0.6q0-0.2 0.1-0.4l3.3-7.5c0.1-0.3 0.5-0.5 0.9-0.5 0.3 0 0.7 0.2 0.9 0.5zm0.7 5.1l-1.6-3.9-1.7 3.9z"
      />
      <path
        id="Layer"
        fillRule="evenodd"
        className="s0"
        d="m68.1 4.8l3.2 7.5q0.1 0.2 0.1 0.4c0 0.3-0.3 0.6-0.7 0.6q-0.5 0-0.6-0.5l-0.7-1.5h-4.4l-0.7 1.5q-0.2 0.5-0.7 0.5c-0.4 0-0.7-0.3-0.7-0.6q0-0.2 0.1-0.4l3.3-7.5c0.2-0.3 0.5-0.5 0.9-0.5 0.4 0 0.7 0.2 0.9 0.5zm0.7 5.1l-1.6-3.9-1.7 3.9z"
      />
      <path
        id="Layer"
        fillRule="evenodd"
        className="s1"
        d="m18.6 29.3c-1.8 0-3.6-0.4-5.2-1.3l-9-5.1-2.7-0.6c-0.6-0.3-0.9-1.8-0.9-2.5v-6.7c0-2.1 1.2-3.9 2.9-5l11.9-6.7c1.8-1 4-1 5.8 0l11.8 6.7c1.8 1.1 2.9 2.9 2.9 5v6.9c0 0.6-0.3 1.2-0.9 1.5l-11.3 6.5c-1.6 0.9-3.4 1.3-5.3 1.3zm-12.5-9.5l9.1 5.1c2.1 1.2 4.8 1.2 6.9 0l10.4-5.9v-5.9c0-0.8-0.4-1.5-1.1-1.9l-11.8-6.7c-0.7-0.4-1.5-0.4-2.2 0l-11.9 6.7c-0.6 0.4-1.1 1.1-1.1 1.9v3.8c0 1.2 0.7 2.3 1.7 2.9z"
      />
      <path
        id="Layer"
        className="s2"
        d="m35.2 18.6l-11.6-6.6c-3.2-1.8-7.3-1.8-10.5 0l-8.7 4.9v1.6c0 0.2 0.2 0.4 0.3 0.5l1.7 0.9 8.5-4.8c2.1-1.2 4.8-1.2 6.9 0l10.6 6q0.1 0.1 0.1 0.3v5.5c0 0.8-0.4 1.5-1.1 1.9l-11.8 6.7c-0.7 0.4-1.5 0.4-2.2 0l-11.9-6.7c-0.6-0.4-1.1-1.1-1.1-1.9v-4l-2.7-1.5c-0.5-0.3-0.8-0.9-0.8-1.5q0 0-0.1 0.1v6.9c0 2.1 1.2 3.9 2.9 5l11.9 6.7c0.9 0.5 1.9 0.7 2.9 0.7 1 0 2-0.2 2.9-0.7l11.8-6.7c1.8-1.1 2.9-2.9 2.9-5v-6.7c0-0.7-0.3-1.2-0.9-1.6z"
      />
    </svg>
  );
}
