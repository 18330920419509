export function SearchTagIcon({
  fill = 'black',
  stroke = 'black',
}: {
  fill?: string;
  stroke?: string;
}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
      <path
        d="M6.36119 0.225098L12.1359 1.05005L12.9608 6.82476L7.59862 12.187C7.37083 12.4148 7.00152 12.4148 6.77367 12.187L0.998982 6.41228C0.771173 6.18449 0.771173 5.81512 0.998982 5.58733L6.36119 0.225098ZM8.01115 5.17486C8.46673 5.63044 9.20541 5.63044 9.66105 5.17486C10.1166 4.71923 10.1166 3.98054 9.66105 3.52493C9.20541 3.06931 8.46673 3.06931 8.01115 3.52493C7.55551 3.98054 7.55551 4.71923 8.01115 5.17486Z"
        fill={fill}
        stroke={stroke}
      />
    </svg>
  );
}
