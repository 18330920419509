import BigNumber from 'bignumber.js';
import { DepositIcon, FormatBalance, Skeleton } from 'components';
import { BalanceHistoryStatsType } from 'gql';
import moment from 'moment';
import { formatBalance } from 'utils';

const DesktopBalanceHistory = ({ row }: { row: BalanceHistoryStatsType | null }) => {
  return (
    <div className="grid lg:grid-cols-[18%_26%_26%_18%_12%] md:grid-cols-[100%] grid-cols-[100%] lg:h-[60px] md:h-full h-full bg-[#0E0E0E] items-center rounded-2xl mb-2 md:px-4 px-4 lg:pr-6 lg:pl-5 md:py-[14px] py-[14px] w-full ">
      <div className="flex flex-row gap-[6px] items-center lg:pb-0 md:pb-4 pb-4  lg:border-0 md:border-b border-b border-[#1F1F1F]">
        <Skeleton.Loader isDarkTheme className="h-8 w-8 !rounded-lg">
          <DepositIcon style={{ height: '32px', width: '32px' }} />
        </Skeleton.Loader>
        <div
          data-cy={`${row?.changeType}`}
          className="text-sm truncate w-[calc(100%-45px)] lg:pr-2 capitalize"
        >
          <Skeleton.Loader isDarkTheme className="h-4 w-[80%]">
            {row?.changeType}
          </Skeleton.Loader>
        </div>
      </div>
      <div
        data-cy={`${moment(row?.timestamp).format('DD/MM/YYYY')}`}
        className="lg:pt-0 md:pt-[5px] pt-[5px] text-sm"
      >
        <Skeleton.Loader isDarkTheme className="w-[170px] h-4">
          {moment(row?.timestamp).format('DD/MM/YYYY・hh:mmA')}
        </Skeleton.Loader>
      </div>
      <div
        data-cy={`${formatBalance(new BigNumber(row?.amount as string), {
          symbol: '',
          tokenDecimals: 12,
          decimalPlaces: 5,
        })}`}
        className="lg:pt-0 md:pt-[5px] pt-[5px] text-sm"
      >
        <Skeleton.Loader isDarkTheme className="w-[63px] h-4">
          <FormatBalance value={row?.amount as string} options={{ decimalPlaces: 5 }} />
        </Skeleton.Loader>
      </div>
      <div className="lg:pt-0 md:pt-[5px] pt-[5px] text-sm">
        <Skeleton.Loader isDarkTheme className="w-[101px] h-4">
          {/* static value */}
          <FormatBalance value={'0'} />
        </Skeleton.Loader>
      </div>
    </div>
  );
};

export default DesktopBalanceHistory;
