import { HTMLAttributes } from 'types';

export function TelegramLogo(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_1666_1397)">
        <path
          d="M7.46327 11.7864L7.16553 15.9744C7.59153 15.9744 7.77602 15.7914 7.99727 15.5717L9.99452 13.6629L14.133 16.6937C14.892 17.1167 15.4268 16.8939 15.6315 15.9954L18.348 3.26644L18.3488 3.26569C18.5895 2.14369 17.943 1.70494 17.2035 1.98019L1.23602 8.09344C0.146274 8.51644 0.162774 9.12394 1.05077 9.39919L5.13302 10.6689L14.6153 4.73569C15.0615 4.44019 15.4673 4.60369 15.1335 4.89919L7.46327 11.7864Z"
          fill="url(#paint0_radial_1666_1397)"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_1666_1397"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(8.12002 3.6916) rotate(90.5254) scale(16.7236 20.0673)"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.2" />
        </radialGradient>
        <clipPath id="clip0_1666_1397">
          <rect width="18" height="18" fill="white" transform="translate(0.400391 0.400391)" />
        </clipPath>
      </defs>
    </svg>
  );
}
