import {
  SetStateAction,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Maybe } from '@watch/common';
import {
  OrderType,
  SortField,
  useSearchLibraryCountLazyQuery,
  useSearchLibraryLazyQuery,
} from 'gql';
import { useParamState, useTagsQuery } from 'hooks';
import { useLocation } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { SearchFilters, SearchResult, SearchType, Setter, Tag, VoidFn } from 'types';
import { pathTo } from 'utils';

type ResourceCount = {
  [SearchType.SmartContracts]: number;
  [SearchType.Views]: number;
};

export interface LibraryState {
  tags: Tag[];
  chains: Maybe<Tag[]>;
  isLoading: boolean;
  isTagsLoading: boolean;
  searchTerm: string | undefined;
  searchType: SearchType;
  sortBy: SortField | undefined;
  pageIndex: number;
  hasNext?: boolean | null;
  setPage: (_: number | SetStateAction<number>, __?: 'next' | 'previous') => void;
  pageSize: number;
  setPageSize: (_: number) => void;
  currentPage: Maybe<SearchResult[]>;
  reset: VoidFn;
  toggleTag: (_: Tag) => void;
  setSearchTerm: React.Dispatch<string | undefined>;
  setSearchType: React.Dispatch<SearchType>;
  setSortBy: Setter<SortField>;
  clearFilters: VoidFn;
  resourcesCount: ResourceCount;
  refreshSearchQuery: VoidFn;
  isLoadingCount: boolean;
}

interface Props extends React.PropsWithChildren {
  initialValue?: Partial<Pick<SearchFilters, 'searchType' | 'tags'>>;
}

export const LibraryContext = createContext<LibraryState>({
  searchType: 'smartContracts',
  suggestions: [],
  tags: [],
} as unknown as LibraryState);

export function LibraryProvider({
  children,
  initialValue = { searchType: SearchType.SmartContracts, tags: [] },
}: Props) {
  const hasMounted = useRef(false);
  const { pathname } = useLocation();
  const [searchTermString, setSearchTerm] = useParamState<string>('searchTerm', '');
  const [searchType, setSearchType] = useParamState<SearchType>(
    'searchType',
    initialValue?.searchType || SearchType.SmartContracts
  );
  const [tags, setTags] = useState<Tag[]>([]);

  const searchTerm = searchTermString?.trim().length ? searchTermString.trim() : undefined;

  const [executeSearch, { data: searchQuery, loading: isSearchLoading }] =
    useSearchLibraryLazyQuery({ fetchPolicy: 'cache-first' });

  const [sortBy, setSortBy] = useParamState<SortField>('sortBy', SortField.Recommended);

  const [before, __, setMultipleParams] = useParamState<number>('before', 0);
  const [after] = useParamState<number>('after', 0);
  const [pageIndex] = useParamState<number>('page', 1);
  const [pageSize, setPageSize] = useParamState<number>('limit', 3);
  const [pageChange, setPageChange] = useState(false);

  const { data: tagsQuery, loading: isTagsLoading } = useTagsQuery({
    skip: pathname !== routes.library,
  });

  const [_, { data: resourceQueryCount, loading: isLoadingCount }] = useSearchLibraryCountLazyQuery(
    {
      variables: {
        filters: {
          searchTerm,
          searchType,
          tags: tags.map(({ slug }) => slug),
        },
      },
      fetchPolicy: 'cache-first',
    }
  );

  // TODO: Should be implemented when it will be ready from timegraph
  // useEffect(() => {
  //   if (pathname === routes.library) {
  //     getCount({
  //       variables: {
  //         filters: {
  //           searchTerm,
  //           tags: tags.map(({ slug }) => slug),
  //         },
  //       },
  //       fetchPolicy: 'cache-first',
  //     });
  //   }
  // }, [tags, getCount, pathname, searchTerm]);

  const resourcesCount: ResourceCount = useMemo(() => {
    return {
      smartContracts: resourceQueryCount?.searchLibraryCount?.smartContracts || 0,
      views: resourceQueryCount?.searchLibraryCount?.views || 0,
    };
  }, [resourceQueryCount]);

  const chains = useMemo<Maybe<Tag[]> | null>(
    () => (isTagsLoading ? null : tagsQuery?.chainTags),
    [isTagsLoading, tagsQuery?.chainTags]
  );
  // TODO: Should be implemented when it will be ready from timegraph
  // const maxPageIndex = useMemo(
  //   () =>
  //     searchQuery?.searchLibrary.data?.length && resourcesCount[searchType]
  //       ? Math.ceil(resourcesCount[searchType] / pageSize)
  //       : 0,
  //   [searchQuery, pageSize, searchType, resourcesCount]
  // );

  const isLoading = useMemo(
    () => isTagsLoading || isSearchLoading,
    [isTagsLoading, isSearchLoading]
  );

  const refreshSearchQuery = useCallback(() => {
    if (searchType) {
      // TODO: Should be implemented when it will be ready from timegraph
      // getCount({
      //   variables: {
      //     filters: {
      //       searchTerm,
      //       searchType,
      //       tags: tags.map(({ slug }) => slug),
      //     },
      //   },
      //   fetchPolicy: 'cache-and-network',
      // });
      executeSearch({
        variables: {
          filters: {
            searchTerm,
            searchType,
            tags: tags.map(({ slug }) => slug),
          },
          after: Number(after),
          before: Number(before),
          limit: pageSize,
          sort: sortBy && {
            field: sortBy,
            order: sortBy == SortField.Recommended ? OrderType.Asc : OrderType.Desc,
          },
        },
        fetchPolicy: 'cache-and-network',
      });
    }
  }, [searchType, searchTerm, tags, executeSearch, after, before, pageSize, sortBy]);

  const search = useCallback(
    (after?: number, before?: number) => {
      executeSearch({
        variables: {
          filters: {
            searchTerm,
            searchType,
            tags: tags.map(({ slug }) => slug),
          },
          after: Number(after || 0),
          before: Number(before || 0),
          limit: pageSize,
          sort: sortBy && {
            field: sortBy,
            order: sortBy == SortField.Recommended ? OrderType.Asc : OrderType.Desc,
          },
        },
        fetchPolicy: 'cache-first',
        onCompleted() {
          setPageChange(false);
        },
      });
    },
    [executeSearch, pageSize, searchTerm, searchType, sortBy, tags]
  );

  useEffect(() => {
    if (searchType && pathname === pathTo('Library')) {
      if (hasMounted.current) {
        setPage(1, undefined, false);
        search();
      } else {
        search(after, before);
        hasMounted.current = true;
      }
    }
  }, [searchTerm, pageSize, searchType, tags, sortBy]);

  useEffect(() => {
    if (pageChange) {
      search(after, before);
    }
  }, [pageChange]);

  const toggleTag = useCallback((tag: Tag) => {
    setTags((prev) => {
      if (prev.find((t) => t.slug === tag.slug)) {
        return prev.filter((t) => t.slug !== tag.slug);
      } else {
        return [...prev, tag];
      }
    });
  }, []);

  const clearFilters = useCallback(() => {
    setTags([]);
  }, []);

  const reset = useCallback(() => {
    clearFilters();
    setSearchType(SearchType.SmartContracts);
    setSearchTerm(undefined);
  }, [clearFilters]);

  const setPage = useCallback(
    (page: number | SetStateAction<number>, movement?: 'next' | 'previous', isSearch = true) => {
      let _after: number | undefined = 0,
        _before: number | undefined = 0;
      if (movement === 'next') {
        _after = searchQuery?.searchLibrary.data[searchQuery.searchLibrary.data.length - 1].id;
      } else if (movement === 'previous') {
        _before = searchQuery?.searchLibrary.data[0].id;
      }
      setMultipleParams({ page, after: _after, before: _before });
      if (isSearch) {
        setPageChange(true);
      }
    },
    [searchQuery?.searchLibrary.data, setMultipleParams]
  );

  const value = useMemo(
    () => ({
      chains,
      clearFilters,
      isLoading,
      isTagsLoading,
      reset,
      currentPage: searchQuery?.searchLibrary.data,
      searchTerm,
      searchType,
      sortBy,
      pageIndex,
      pageSize,
      hasNext: searchQuery?.searchLibrary.hasNext,
      setPage,
      setPageSize,
      setSearchTerm,
      setSearchType,
      setSortBy,
      tags,
      toggleTag,
      resourcesCount,
      refreshSearchQuery,
      isLoadingCount,
    }),
    [
      chains,
      clearFilters,
      isLoading,
      isTagsLoading,
      reset,
      searchQuery?.searchLibrary.data,
      searchQuery?.searchLibrary.hasNext,
      searchTerm,
      searchType,
      sortBy,
      pageIndex,
      pageSize,
      setPage,
      setPageSize,
      setSearchTerm,
      setSearchType,
      setSortBy,
      tags,
      toggleTag,
      resourcesCount,
      refreshSearchQuery,
      isLoadingCount,
    ]
  );

  return <LibraryContext.Provider value={value}>{children}</LibraryContext.Provider>;
}

// export const useLibrary = () => useContext(LibraryContext);
