import { HTMLAttributes } from 'types';

export function CardIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M18.3333 7.09935V3.31602C18.3333 2.14102 17.8 1.66602 16.475 1.66602H13.1083C11.7833 1.66602 11.25 2.14102 11.25 3.31602V7.09102C11.25 8.27435 11.7833 8.74102 13.1083 8.74102H16.475C17.8 8.74935 18.3333 8.27435 18.3333 7.09935Z"
        stroke="#B2B3B8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3333 16.475V13.1083C18.3333 11.7833 17.8 11.25 16.475 11.25H13.1083C11.7833 11.25 11.25 11.7833 11.25 13.1083V16.475C11.25 17.8 11.7833 18.3333 13.1083 18.3333H16.475C17.8 18.3333 18.3333 17.8 18.3333 16.475Z"
        stroke="#B2B3B8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.74935 7.09935V3.31602C8.74935 2.14102 8.21602 1.66602 6.89102 1.66602H3.52435C2.19935 1.66602 1.66602 2.14102 1.66602 3.31602V7.09102C1.66602 8.27435 2.19935 8.74102 3.52435 8.74102H6.89102C8.21602 8.74935 8.74935 8.27435 8.74935 7.09935Z"
        stroke="#B2B3B8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.74935 16.475V13.1083C8.74935 11.7833 8.21602 11.25 6.89102 11.25H3.52435C2.19935 11.25 1.66602 11.7833 1.66602 13.1083V16.475C1.66602 17.8 2.19935 18.3333 3.52435 18.3333H6.89102C8.21602 18.3333 8.74935 17.8 8.74935 16.475Z"
        stroke="#B2B3B8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
