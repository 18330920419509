import { GroupBase, Props as ReactSelectProps } from 'react-select';
import { SelectOption } from 'types';

export function isGroupedOptions<T>(
  options: ReactSelectProps<SelectOption<T>, false>['options']
): options is GroupBase<SelectOption<T>>[] {
  try {
    return !!options && (options as GroupBase<SelectOption<T>>[])[0].options !== undefined;
  } catch (e) {
    return false;
  }
}
