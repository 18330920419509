import { ChevronDownIcon } from 'components/icons';
import { DropdownIndicatorProps, components } from 'react-select';
import { SelectOption } from 'types';
import { classes } from 'utils';

export function DropdownIndicator<T>(props: DropdownIndicatorProps<SelectOption<T>, false>) {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDownIcon
        data-cy="sort-profile-dropdown"
        className={classes(
          'h-4 w-4 transition-transform',
          props.selectProps.menuIsOpen && 'rotate-180'
        )}
      />
    </components.DropdownIndicator>
  );
}
