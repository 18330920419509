import { HTMLAttributes } from 'types';

export function ViewIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="58"
      height="58"
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1965_57233)">
        <path
          d="M7.12485 30.6675C6.32551 29.7019 6.32551 28.3044 7.12485 27.3387C13.4019 19.7556 21.2197 16 29.0009 16C36.7822 16 44.6 19.7556 50.877 27.3387C51.6764 28.3044 51.6764 29.7019 50.877 30.6675C44.6 38.2507 36.7822 42.0062 29.0009 42.0063C21.2197 42.0063 13.4019 38.2507 7.12485 30.6675ZM19.3176 29.0031C19.3176 34.3543 23.6498 38.6865 29.0009 38.6865C34.3521 38.6865 38.6843 34.3543 38.6843 29.0031C38.6843 23.652 34.3521 19.3198 29.0009 19.3198C23.6498 19.3198 19.3176 23.652 19.3176 29.0031Z"
          stroke="white"
        />
        <path
          d="M32.8523 28.9965C32.8523 31.137 31.1429 32.8465 29.0023 32.8465C27.4519 32.8465 26.0286 31.8677 25.4277 30.4732C25.4112 30.4348 25.4154 30.4197 25.416 30.4177C25.416 30.4176 25.416 30.4175 25.416 30.4175C25.4174 30.412 25.4243 30.3908 25.4574 30.3592C25.5306 30.2894 25.6809 30.2215 25.8607 30.2215C28.0702 30.2215 29.8648 28.4268 29.8648 26.2173C29.8648 25.9651 29.9634 25.7113 30.0968 25.5604C30.2171 25.4244 30.3088 25.4169 30.4006 25.4536C31.8523 26.0346 32.8523 27.3616 32.8523 28.9965Z"
          stroke="white"
        />
        <rect
          x="44.2225"
          y="7.45898"
          width="8.94286"
          height="8.94286"
          rx="1.78585"
          transform="rotate(-45 44.2225 7.45898)"
          stroke="white"
        />
        <path
          d="M47.4705 11.8748C47.7258 11.6245 47.7278 11.214 47.475 10.9611L47.3337 10.8199C47.0844 10.5705 46.6808 10.5686 46.429 10.8154L45.2979 11.9244C45.0426 12.1747 45.0406 12.5853 45.2934 12.8381L45.4347 12.9793C45.684 13.2287 46.0876 13.2307 46.3394 12.9838L47.4705 11.8748Z"
          stroke="white"
        />
        <rect
          x="-0.707107"
          width="1.48571"
          height="2.96869"
          rx="0.642927"
          transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 13.165 44.9297)"
          stroke="white"
        />
        <rect
          x="1.13447"
          y="50.541"
          width="8.94286"
          height="8.94286"
          rx="1.78585"
          transform="rotate(-45 1.13447 50.541)"
          stroke="white"
        />
        <rect
          x="24.5282"
          y="0.498047"
          width="8.94286"
          height="8.94286"
          rx="1.78585"
          stroke="white"
        />
        <path
          d="M28.2575 10.3246C28.2575 9.96949 28.5454 9.68164 28.9004 9.68164H29.1003C29.4554 9.68164 29.7432 9.96949 29.7432 10.3246V12.1101C29.7432 12.4652 29.4554 12.753 29.1003 12.753H28.9004C28.5454 12.753 28.2575 12.4652 28.2575 12.1101V10.3246Z"
          stroke="white"
        />
        <rect x="24.529" y="48.5586" width="8.94286" height="8.94286" rx="1.78585" stroke="white" />
        <path
          d="M28.2582 45.889C28.2582 45.5339 28.546 45.2461 28.9011 45.2461H29.101C29.456 45.2461 29.7439 45.5339 29.7439 45.889V47.6723C29.7439 48.0274 29.456 48.3153 29.101 48.3153H28.9011C28.546 48.3153 28.2582 48.0274 28.2582 47.6723V45.889Z"
          stroke="white"
        />
        <rect
          x="1.13447"
          y="7.45898"
          width="8.94286"
          height="8.94286"
          rx="1.78585"
          transform="rotate(-45 1.13447 7.45898)"
          stroke="white"
        />
        <rect
          x="10.2344"
          y="11.4551"
          width="1.48571"
          height="2.96869"
          rx="0.642927"
          transform="rotate(-45 10.2344 11.4551)"
          stroke="white"
        />
        <rect
          x="44.2225"
          y="50.541"
          width="8.94286"
          height="8.94286"
          rx="1.78585"
          transform="rotate(-45 44.2225 50.541)"
          stroke="white"
        />
        <rect
          x="44.3743"
          y="45.4297"
          width="1.48571"
          height="2.96869"
          rx="0.642927"
          transform="rotate(-45 44.3743 45.4297)"
          stroke="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1965_57233">
          <rect width="58" height="58" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
