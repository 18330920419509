import { HTMLAttributes } from 'types';

export function RewardsIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg width="25" height="25" {...props}>
      <g clipPath="url(#a)">
        <path
          fill="#91D172"
          d="M5.245 3.837H19.61c.16 1.911.078 4.195-.523 6.338-.69 2.444-1.881 4.533-3.073 5.325l-.464.31.004 4.742H9.306l-.038-4.746-.46-.31c-1.166-.77-2.352-2.975-3.015-5.495-.581-2.195-.66-4.428-.548-6.168v.004ZM.398 8.007h2.859c.132.842.293 1.691.514 2.527.253.958.592 1.86.98 2.712-1.54-.861-3.17-2.346-4.353-5.24Zm21.27 0h2.73c-1.143 2.787-2.699 4.257-4.198 5.133.349-.757.66-1.548.899-2.399.25-.892.43-1.812.569-2.734Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M.398.5h24v24h-24z" />
        </clipPath>
      </defs>
    </svg>
  );
}
