import React from 'react';

import { PageControl } from './PageControl';
import { PageSizeControl } from './PageSizeControl';

interface PaginationControlsProps {
  count: number;
  isLoading: boolean;
  pageIndex: number;
  setPageIndex: React.Dispatch<React.SetStateAction<number>>;
  pageSize: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  startPageIndex: number;
}

export const PaginationControls: React.FC<PaginationControlsProps> = ({
  count,
  isLoading,
  pageIndex,
  setPageIndex,
  pageSize,
  setPageSize,
  startPageIndex,
}) => {
  const maxPageIndex = React.useMemo(
    () => (count ? (count < pageSize ? 1 : Math.ceil(count / pageSize)) : 1),
    [count, pageSize]
  );

  return (count && count > 3) || isLoading ? (
    <div className="flex justify-between md:items-center items-start mt-5 text-sm md:flex-row flex-col md:gap-0 gap-3">
      <PageControl
        onPrevious={() => setPageIndex((prev) => Math.max(1, prev - 1))}
        onNext={() => setPageIndex((prev) => Math.min(maxPageIndex, prev + 1))}
        pageIndex={pageIndex - 1}
        setPageIndex={setPageIndex}
        pageCount={maxPageIndex}
        totalItems={count || 0}
        pageSize={pageSize}
      />
      <PageSizeControl
        pageSize={pageSize}
        setPageIndex={setPageIndex}
        setPageSize={setPageSize}
        startPageIndex={startPageIndex}
        className="flex items-center justify-center gap-[6px]"
      />
    </div>
  ) : null;
};
