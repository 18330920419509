import { HTMLAttributes } from 'types';

export function DisconnectMobileIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      {...props}
    >
      <path
        d="M14.6354 3.36436C13.1483 1.87725 10.7419 1.87725 9.25654 3.36436L7.55322 5.06768L8.44971 5.96416L10.153 4.26084C11.0987 3.31514 12.6948 3.21494 13.739 4.26084C14.7849 5.30674 14.6847 6.90107 13.739 7.84678L12.0356 9.5501L12.9339 10.4483L14.6372 8.74502C16.1208 7.25791 16.1208 4.85146 14.6354 3.36436ZM7.84854 13.739C6.90283 14.6847 5.30674 14.7849 4.2626 13.739C3.2167 12.6931 3.31689 11.0987 4.2626 10.153L5.96592 8.44971L5.06768 7.55147L3.36436 9.25479C1.87725 10.7419 1.87725 13.1483 3.36436 14.6337C4.85146 16.119 7.25791 16.1208 8.74326 14.6337L10.4466 12.9304L9.5501 12.0339L7.84854 13.739ZM4.57549 3.68076C4.54906 3.65459 4.51337 3.63991 4.47617 3.63991C4.43898 3.63991 4.40329 3.65459 4.37686 3.68076L3.68076 4.37686C3.65459 4.40329 3.63991 4.43898 3.63991 4.47617C3.63991 4.51337 3.65459 4.54906 3.68076 4.57549L13.4261 14.3208C13.4806 14.3753 13.5702 14.3753 13.6247 14.3208L14.3208 13.6247C14.3753 13.5702 14.3753 13.4806 14.3208 13.4261L4.57549 3.68076Z"
        fill="white"
      />
    </svg>
  );
}
