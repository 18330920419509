import { Button } from './../../../Button';
import { CustomExcalmationMark } from './../../../svg';

const ErrorRefresh = () => {
  return (
    <div className="flex flex-col items-center justify-center text-center p-6">
      <CustomExcalmationMark />
      <span className="text-2xl mt-[31px] mb-5">Something went wrong</span>
      <Button
        className="p-[12px_24px] flex rounded-full h-[46px]"
        variant="plain"
        style={{
          background:
            'linear-gradient(0deg, #FFF 0%, #FFF 100%), linear-gradient(95deg, #5D3EF8 -12.23%, #BA4CD6 117.24%)',
        }}
        onClick={() => {
          window.location?.reload();
        }}
      >
        <span className="text-[#010314] text-base leading-[22px]">Refresh</span>
      </Button>
    </div>
  );
};

export default ErrorRefresh;
