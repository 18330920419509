import { useState } from 'react';

import { PaginationControls, Skeleton, ViewIcon } from 'components';
import { useApi, useMyProfile } from 'contexts';
import { SearchType, useUserFundedViewsQuery } from 'gql';
import { Link } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { classes } from 'utils';

import { FundDetails } from './FundDetails';

export const FundedView = () => {
  const { myProfile } = useMyProfile();
  const {
    chainProps: { tokenDecimals },
  } = useApi();
  const startPageIndex = 1;
  const [pageIndex, setPageIndex] = useState<number>(startPageIndex);
  const [pageSize, setPageSize] = useState<number>(3);

  const { data, loading: isLoading } = useUserFundedViewsQuery({
    variables: {
      userId: myProfile?.id || 0,
      limit: pageSize,
      page: pageIndex,
    },
  });

  const count = data?.userFundedViews?.count;

  return count !== 0 ? (
    <Skeleton.Provider isLoading={isLoading}>
      <div className="card mb-6 sm:p-6 p-0 rounded-3xl lg:!border lg:!border-solid !border-[#1F1F1F] !border-none bg-gradient-to-b from-[#000_20.37%] to-[#000_79.34%]">
        <div className="lg:block md:hidden hidden">
          {/* TODO change: grid-cols-[35%_18%_8%_10%_14%_7%_8%] */}
          <div className="grid grid-cols-[20%_20%_20%_20%_20%] text-white text-xs lg:px-2 md:px-4 px-4 lg:pl-5 ">
            <Skeleton.Loader isDarkTheme className="w-14 h-3">
              <div>View name</div>
            </Skeleton.Loader>
            <Skeleton.Loader isDarkTheme className="w-[81px] h-3 !rounded-full">
              <div>Amount</div>
            </Skeleton.Loader>
            {/* <Skeleton.Loader isDarkTheme className="w-[35px] h-3 !rounded-full">
              <div>Cycles</div>
            </Skeleton.Loader> */}
            <Skeleton.Loader isDarkTheme className="w-[24px] h-3 !rounded-full">
              <div>Date</div>
            </Skeleton.Loader>
            <Skeleton.Loader isDarkTheme className="w-[68px] h-3 !rounded-full">
              <div>Rewards</div>
            </Skeleton.Loader>
            {/* <Skeleton.Loader isDarkTheme className="w-[37px] h-3 !rounded-full">
              <div>Status</div>
            </Skeleton.Loader> */}
            <Skeleton.Loader isDarkTheme className="w-[37px] h-3 !rounded-full">
              <div>Balance</div>
            </Skeleton.Loader>
          </div>
        </div>
        <div data-cy="funded-view-table" className="text-white lg:pt-[10px] md:pt-[0px] pt-0">
          {(isLoading
            ? new Array(pageSize).fill(null)
            : data?.userFundedViews?.fundedViewLogs || []
          ).map((view, index) => {
            return <FundDetails view={view} tokenDecimals={tokenDecimals} key={index} />;
          })}
        </div>
        <PaginationControls
          count={count || 0}
          isLoading={isLoading}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          pageSize={pageSize}
          setPageSize={setPageSize}
          startPageIndex={startPageIndex}
        />
      </div>
    </Skeleton.Provider>
  ) : (
    <div className="flex flex-col items-center justify-center pb-6">
      <div className="relative w-full py-[81px] justify-center items-center inline-flex flex-col text-center text-white border border-solid rounded-[20px] border-[#1F1F1F] bg-gradient-to-b from-[#000_20.37%] to-[#000_79.34%]">
        <div className="relative mb-4 lg:h-[108px] h-full w-fit lg:w-[108px] overflow-hidden">
          <div className="absolute top-2/4 left-2/4  rounded-[59px] bg-[linear-gradient(287deg,_var(--tw-gradient-stops))] from-[#342B49_3%] via-[#342238_45%] to-[#533838_95%] filter blur-[35px] h-1/2 w-20 -translate-y-2/4 -translate-x-2/4"></div>
          <ViewIcon
            className={classes(
              'relative lg:h-[108px] md:w-[108px] lg:min-h-fit min-h-[108px] p-6 w-full bg-[#d3cccc14] md:rounded-lg rounded-[20px]'
            )}
          />
        </div>
        <div className="text-xl mb-3 text-center">
          <Skeleton.Loader className="w-[440px]">
            {"You don't have any funded Views"}
          </Skeleton.Loader>
        </div>

        <div className="text-sm mb-3 text-[#B2B3B8]">
          <Skeleton.Loader className="w-50">Browse Views in the Library</Skeleton.Loader>
        </div>
        <Skeleton.Loader className="h-12 sm:w-48">
          <Link
            className="flex items-center justify-center font-normal text-base text-black bg-white px-4 h-[40px] rounded-full text-center normal-case m-0 w-fit lg:w-auto"
            to={routes.library}
            state={{ tab: SearchType.Views }}
          >
            Library
          </Link>
        </Skeleton.Loader>
      </div>
    </div>
  );
};
