import { Fragment } from 'react';

import { RadioGroup } from '@headlessui/react';
import { CardIcon, GradientRefreshButton, ListIcon, Select, Skeleton } from 'components';
import { SearchType } from 'gql';
import { classes, SORT_OPTIONS } from 'utils';

import { FiltersProps } from './types';
import useFilters from './useFilters';

const TYPE_OPTIONS = [
  { label: 'Smart Contracts', value: SearchType.SmartContracts },
  { label: 'Views', value: SearchType.Views },
];

const VIEW_TYPE = [
  { svg: <ListIcon />, value: 'list' },
  { svg: <CardIcon />, value: 'card' },
];

export const Filters = ({ handleRefresh, isLoading: isFakeLoading, setIsList, isList }: FiltersProps) => {
  const {
    width,
    sortBy,
    setSortBy,
    isListLoading,
    isLoading,
    isLoadingCount,
    searchType,
    setSearchType,
  } = useFilters();

  return (
    <div className="w-full flex mt-6 md:items-center items-start relative flex-wrap">
      <RadioGroup
        className="flex flex-1 gap-2 items-start w-full sm:overflow-visible overflow-auto no-scrollbar"
        value={searchType}
        onChange={setSearchType}
      >
        {TYPE_OPTIONS.map(({ label, value }) => {
          return (
            <RadioGroup.Option as={Fragment} key={value} value={value}>
              {({ checked }) => (
                <div
                  data-cy={`${value}-tab`}
                  className={classes(
                    'cursor-pointer md:text-base text-sm text-[#f7f8f8] border-y-2 py-2 pr-2 pl-3 border-transparent border-solid border-0 whitespace-pre',
                    checked && !isLoading && 'rounded-[32px] bg-[#ffffff1c]'
                  )}
                >
                  <Skeleton.Loader
                    isDarkTheme
                    className="w-20 h-6 !mr-1 !rounded-[32px]"
                    isLoading={isLoadingCount || isFakeLoading}
                  >
                    {label}{' '}
                    {/* TODO: Implement It when Timegraph is ready with efficient way to show counts */}
                    {/* <span className="ml-1 md:text-sm text-xs px-2 py-1.5 bg-[#f7f8f814] rounded-full">
                      {resourcesCount[value]}
                    </span> */}
                  </Skeleton.Loader>
                </div>
              )}
            </RadioGroup.Option>
          );
        })}
      </RadioGroup>
      {width > 768 && (
        <div className="flex items-center flex-row">
          <Skeleton.Loader isDarkTheme className="w-36 h-10 !rounded-[32px]">
            <div className="flex items-center whitespace-pre justify-end md:gap-2 gap-5">
              <div className="flex flex-row shrink-0 pl-4 md:justify-end justify-start items-center text-sm border border-[#ffffff14] rounded-3xl">
                <span className="text-[#B2B3B8]">
                  Sort by <span className="ml-1">·</span>
                </span>
                <Select
                  value={sortBy}
                  placeholder="Select..."
                  onChange={setSortBy}
                  options={SORT_OPTIONS}
                  isLibrary
                  classNames={{
                    valueContainer: () => '',
                    menu: () => '!w-44',
                  }}
                />
              </div>
              <div>
                <GradientRefreshButton
                  onClick={() => {
                    handleRefresh && handleRefresh();
                  }}
                  isLoading={isListLoading || isFakeLoading}
                />
              </div>
            </div>
          </Skeleton.Loader>
          <RadioGroup
            className="flex flex-row gap-[2px] ml-3"
            defaultChecked
            defaultValue={isList ? 'list' : 'card'}
          >
            {VIEW_TYPE.map(({ svg, value }) => (
              <RadioGroup.Option value={value} key={value}>
                {({ checked }) => {
                  return (
                    <Skeleton.Loader isDarkTheme className="h-10 w-10 !rounded-[32px]">
                      <button
                        data-cy={`${value}-view-btn-library`}
                        className={classes(
                          'w-10 h-10 p-[10px] flex items-center justify-center',
                          checked &&
                            'bg-[#ffffff14] rounded-3xl border border-solid border-[#FFFFFF33]'
                        )}
                        onClick={() => {
                          setIsList(value === 'list');
                        }}
                      >
                        {svg}
                      </button>
                    </Skeleton.Loader>
                  );
                }}
              </RadioGroup.Option>
            ))}
          </RadioGroup>
        </div>
      )}
    </div>
  );
};
