import { Image, ResourceList, Skeleton } from 'components';
import { useParamState } from 'hooks';

import { Filters } from '../Filters/Filters';
import { ActiveTags } from '../Search/Components/ActiveTags';
import { TagFilters } from '../Search/Components/TagFilters';
import { Search } from '../Search/Search';

import NoDataFound from './Components/NoDataFound';
import useLibraryList from './useLibraryList';

const LibraryList = () => {
  const {
    isLoading,
    isTagsLoading,
    reset,
    searchTerm,
    searchType,
    throttleLoading,
    currentPage,
    handleRefresh,
    controls,
  } = useLibraryList();

  const [isList, setIsList] = useParamState<boolean>('isList', true);

  return (
    <Skeleton.Provider isLoading={isTagsLoading}>
      <div className="flex w-full flex-col justify-start">
        <div className="relative p-1 flex w-full h-12 my-2 rounded-3xl border z-30 border-white border-opacity-[15%] backdrop-blur-lg bg-[linear-gradient(90deg,rgba(255,255,255,0.15)_0%,rgba(255,255,255,0.00)_100%)] focus-within:border-white">
          <TagFilters />
          <Search />
        </div>
        <ActiveTags />
        <Filters
          handleRefresh={handleRefresh}
          isLoading={throttleLoading}
          setIsList={setIsList}
          isList={isList}
        />
        <div className="w-full flex-1 md:min-w-0 mt-4 flex flex-col">
          <ResourceList
            isEmptyView={
              <div className="w-full">
                <div className="flex flex-col items-center justify-center[&>*]:mb-3">
                  <Image alt="No results" src="/empty-library.svg" className="mb-6" />
                  <h3 className="text-center">Oops</h3>
                  <NoDataFound reset={reset} searchTerm={searchTerm} searchType={searchType} />
                </div>
              </div>
            }
            isLoading={isLoading || throttleLoading}
            value={currentPage}
            isList={isList}
          />
          <Skeleton.Provider isLoading={isLoading || throttleLoading}>{controls}</Skeleton.Provider>
        </div>
      </div>
    </Skeleton.Provider>
  );
};

export default LibraryList;
