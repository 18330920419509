import BigNumber from 'bignumber.js';
import { Skeleton, ViewIcon } from 'components';
import { UserFundedViewsStatsType } from 'gql';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { formatBalance } from 'utils';

export const FundDetails = ({
  view,
  tokenDecimals,
}: {
  tokenDecimals: number;
  view: UserFundedViewsStatsType | null;
}) => {
  return (
    // TODO change: lg:grid-cols-[35%_18%_8%_10%_14%_7%_8%]
    <div className="grid lg:grid-cols-[20%_20%_20%_20%_20%] md:grid-cols-[100%] grid-cols-[100%] lg:h-[60px] md:h-full h-full bg-[#0E0E0E] items-center rounded-2xl mb-2 lg:px-2 md:px-4 px-4 lg:pl-5 md:py-[14px] py-[14px]  w-full ">
      <div className="flex flex-row gap-2 items-center lg:pb-0 md:pb-4 pb-4  lg:border-0 md:border-b border-b border-[#1F1F1F]">
        <Skeleton.Loader isDarkTheme className="h-8 w-8 !rounded-lg">
          <div className="bg-[#222121] p-[6px] rounded-md">
            <ViewIcon className="h-5 w-5" />
          </div>
        </Skeleton.Loader>
        <div className="truncate w-[calc(100%-45px)] lg:pr-2">
          <Skeleton.Loader isDarkTheme className="h-4">
            {view?.name}
          </Skeleton.Loader>
        </div>
      </div>
      <div className="lg:pt-0 md:pt-3 pt-3 flex items-center gap-[5px] text-sm">
        <Skeleton.Loader isDarkTheme className="w-[113px] h-4">
          <div className="lg:hidden flex text-xs text-[#B2B3B8]">Deposit amount:</div>
          {formatBalance(new BigNumber(view?.funded as string), {
            symbol: '$TANLOG',
            tokenDecimals,
            decimalPlaces: 5,
          })}
        </Skeleton.Loader>
      </div>
      {/* <div className="lg:pt-0 md:pt-[5px] pt-[5px] flex items-center gap-[5px] text-sm">
        <Skeleton.Loader isDarkTheme className="lg:w-4 w-[80px] h-4">
          <div className="lg:hidden flex text-xs text-[#B2B3B8]">Cycles:</div> {cycle}
        </Skeleton.Loader>
      </div> */}
      <div className="lg:pt-0 md:pt-[5px] pt-[5px] flex items-center gap-[5px] text-sm">
        <Skeleton.Loader isDarkTheme className="w-[63px] h-4">
          <div className="lg:hidden flex text-xs text-[#B2B3B8]">Date:</div>
          {moment(view?.fundedAt || '').format('DD.MM.yyyy')}
        </Skeleton.Loader>
      </div>
      <div className="lg:pt-0 md:pt-[5px] pt-[5px] flex items-center gap-[5px] text-sm">
        <Skeleton.Loader isDarkTheme className="w-[101px] h-4">
          <div className="lg:hidden flex text-xs text-[#B2B3B8]">Earnings:</div>
          {'0'} $TANLOG
        </Skeleton.Loader>
      </div>

      {/* <div className="flex gap-2 justify-start items-center lg:pt-0 md:pt-[5px] pt-[5px] text-sm">
        <Skeleton.Loader isDarkTheme className="w-[50px] h-4">
          <div className="lg:hidden flex text-xs text-[#B2B3B8]">Status:</div>
          <Status
            color={isActive ? 'green' : 'red'}
            dotClassName={classes(
              "bg-[#3FFF2E] w-[5px] h-[5px] rounded-full flex justify-center items-center relative after:'#' after:absolute after:w-[11px] after:h-[11px] after:rounded-full  after:border-[#3FFF2E33] after:border top-0",
              !isActive && 'bg-[#B2B3B8] after:border-[#B2B3B833] outline-[#B2B3B833]'
            )}
          >
            {isActive ? 'Active' : 'Inactive'}
          </Status>
        </Skeleton.Loader>
      </div> */}

      <Link
        to={`${routes.fundingActivity}/${view?.hash || ''}`}
        className="flex justify-start items-center gap-2 "
      >
        <Skeleton.Loader isDarkTheme className="w-[50px] h-4">
          <div className="lg:hidden flex text-xs text-[#B2B3B8]">Balance:</div>
          <div
            data-cy="funding-activity-btn"
            className="text-sm  bg-[linear-gradient(0deg,_#8D74F7_3%,_#D285F7_45%,_#FFAD97_95%)] bg-clip-text text-transparent cursor-pointer"
          >
            Funding Activity
          </div>
        </Skeleton.Loader>
      </Link>
    </div>
  );
};
