import { useCallback, useState } from 'react';

import { CheckIcon } from 'components';
import { useApi, useApiKey } from 'contexts';
import { ApiKeysDocument, ApiKeysQuery, useRevokeApiKeyMutation } from 'gql';
import { useNavigate } from 'react-router-dom';
import { notify } from 'utils';

const useDetails = () => {
  const { account, sessionKey } = useApi();
  const { apiKey } = useApiKey();
  const navigate = useNavigate();
  const [isDeactivateVisible, setIsDeactivateVisible] = useState(false);
  const [deactivateString, setDeactivateString] = useState('');

  const [revokeApiKey] = useRevokeApiKeyMutation({
    update(cache, { data }) {
      const variables = { address: account?.address, sessionKey };
      const prev = cache.readQuery<ApiKeysQuery>({
        query: ApiKeysDocument,
        variables,
      });

      prev?.apiKeys &&
        data?.revokeApiKey?.key &&
        cache.writeQuery({
          query: ApiKeysDocument,
          variables,
          data: {
            ...prev,
            apiKeys: (prev.apiKeys || []).map((a: any) =>
              a.key === data?.revokeApiKey?.key ? data.revokeApiKey : a
            ),
          },
        });
    },
  });

  const onDeactivate = useCallback(async () => {
    if (deactivateString === 'deactivate' && sessionKey && account?.address && apiKey?.key) {
      await revokeApiKey({
        variables: {
          data: { key: apiKey.key },
          address: account?.address,
          sessionKey,
        },
      });

      navigate('/profile');
      notify('API key deactivated', CheckIcon);
    }
  }, [deactivateString, sessionKey, account?.address, apiKey?.key, revokeApiKey, navigate]);
  return {
    apiKey,
    setIsDeactivateVisible,
    isDeactivateVisible,
    onDeactivate,
    deactivateString,
    setDeactivateString,
  };
};

export default useDetails;
