import { HTMLAttributes } from 'types';

export function SpentIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg width="27" height="26" {...props}>
      <path
        fill="#F45D5D"
        d="M12.716 20.773h1.49v-1.408c1.1-.127 1.959-.465 2.572-1.016.614-.55.921-1.286.921-2.207 0-.92-.262-1.67-.785-2.248-.524-.578-1.409-1.128-2.654-1.652-1.048-.433-1.806-.822-2.275-1.165a1.625 1.625 0 0 1-.704-1.38c0-.56.203-1.003.609-1.328.406-.325.961-.487 1.665-.487.542 0 1.012.126 1.409.379s.731.632 1.002 1.137l1.3-.623c-.307-.632-.713-1.128-1.219-1.49-.505-.36-1.101-.577-1.787-.65v-1.38h-1.49v1.38c-.92.127-1.647.466-2.18 1.016-.533.551-.8 1.233-.8 2.045 0 .885.272 1.589.813 2.113.542.523 1.355 1.01 2.438 1.462 1.21.506 2.04.962 2.492 1.368.45.406.677.907.677 1.503 0 .578-.24 1.043-.718 1.395-.479.352-1.079.528-1.801.528a3.137 3.137 0 0 1-1.882-.596c-.551-.397-.935-.939-1.151-1.625l-1.382.46c.38.831.844 1.486 1.395 1.964.55.479 1.232.826 2.045 1.043v1.462Zm.785 3.06c-1.48 0-2.88-.284-4.198-.852a10.988 10.988 0 0 1-3.453-2.33 10.989 10.989 0 0 1-2.329-3.453A10.482 10.482 0 0 1 2.668 13c0-1.498.284-2.907.853-4.225a10.827 10.827 0 0 1 2.33-3.44A11.105 11.105 0 0 1 9.302 3.02a10.482 10.482 0 0 1 4.198-.853c1.499 0 2.907.284 4.225.853a10.94 10.94 0 0 1 3.44 2.316 10.94 10.94 0 0 1 2.316 3.44c.568 1.317.853 2.726.853 4.224 0 1.48-.285 2.88-.854 4.198a11.105 11.105 0 0 1-2.315 3.453 10.826 10.826 0 0 1-3.44 2.33 10.548 10.548 0 0 1-4.225.853Z"
      />
    </svg>
  );
}
