export function ArbitrumLogo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
      <circle cx="13" cy="12.5" r="12" fill="#28A0F0" stroke="#28A0F0" />
      <path
        d="M14.3008 11.9746L15.224 10.408L17.7125 14.2839L17.7137 15.0277L17.7055 9.90921C17.6997 9.78405 17.6332 9.66956 17.5272 9.60203L13.047 7.02502C12.9422 6.97351 12.8093 6.97413 12.7047 7.02665C12.6905 7.03377 12.6773 7.04142 12.6646 7.04986L12.649 7.05968L8.30017 9.57975L8.28331 9.58749C8.26157 9.59739 8.23968 9.61015 8.21903 9.62485C8.13657 9.68395 8.0818 9.77144 8.06409 9.86945C8.06146 9.8843 8.05944 9.89946 8.05859 9.91478L8.0654 14.0858L10.3833 10.4932C10.6751 10.0169 11.311 9.86334 11.9013 9.87177L12.594 9.89003L8.5122 16.436L8.99335 16.7131L13.124 9.89668L14.9498 9.89003L10.8298 16.8784L12.5467 17.866L12.7519 17.9839C12.8387 18.0192 12.9409 18.021 13.0284 17.9893L17.5716 15.3565L16.703 15.8598L14.3008 11.9746ZM14.653 17.0479L12.9189 14.3262L13.9775 12.5298L16.2549 16.1195L14.653 17.0479Z"
        fill="#2D374B"
      />
      <path
        d="M12.918 14.3261L14.6521 17.0478L16.254 16.1195L13.9766 12.5298L12.918 14.3261Z"
        fill="#28A0F0"
      />
      <path
        d="M17.7137 15.0279L17.7125 14.2841L15.224 10.4082L14.3008 11.9748L16.7031 15.86L17.5717 15.3567C17.6569 15.2875 17.7085 15.186 17.7139 15.0764L17.7137 15.0279Z"
        fill="#28A0F0"
      />
      <path
        d="M7.28516 15.7294L8.51177 16.4362L12.5936 9.89016L11.9009 9.8719C11.3106 9.86355 10.6748 10.017 10.3829 10.4934L8.06497 14.086L7.28516 15.2841V15.7294Z"
        fill="white"
      />
      <path
        d="M14.9487 9.89014L13.1229 9.89679L8.99219 16.7132L10.436 17.5444L10.8286 16.8785L14.9487 9.89014Z"
        fill="white"
      />
      <path
        d="M18.4826 9.88052C18.4674 9.49869 18.2606 9.1492 17.9368 8.94568L13.3978 6.33542C13.0775 6.17413 12.6773 6.1739 12.3564 6.33534C12.3185 6.35445 7.94236 8.89246 7.94236 8.89246C7.8818 8.92147 7.82347 8.95605 7.76862 8.99535C7.47955 9.20258 7.30333 9.52453 7.28516 9.8782V15.284L8.06497 14.0858L8.05816 9.91471C8.05909 9.89947 8.06103 9.88439 8.06366 9.86961C8.08129 9.77145 8.13614 9.68396 8.2186 9.62478C8.23925 9.61009 12.69 7.0337 12.7042 7.02658C12.8088 6.97406 12.9417 6.97344 13.0465 7.02496L17.5267 9.60196C17.6327 9.66949 17.6991 9.78398 17.705 9.90914V15.0761C17.6996 15.1856 17.6563 15.2872 17.5711 15.3563L16.7025 15.8597L16.2543 16.1194L14.6524 17.0478L13.0279 17.9892C12.9404 18.0208 12.8381 18.019 12.7514 17.9838L10.8293 16.8783L10.4367 17.5442L12.164 18.5386C12.2211 18.5711 12.272 18.5999 12.3138 18.6234C12.3784 18.6597 12.4225 18.6839 12.4381 18.6915C12.5608 18.7511 12.7374 18.7858 12.8966 18.7858C13.0425 18.7858 13.1849 18.759 13.3195 18.7063L18.0381 15.9737C18.3089 15.7638 18.4683 15.4474 18.4826 15.1046V9.88052Z"
        fill="white"
      />
    </svg>
  );
}
