import { BreadCrumbs, ProfileDetails, ProfileResources, Skeleton } from 'components';

import usePublisher from './usePublisher';

const Publisher = () => {
  const {
    isLoading,
    publisher,
    countData,
    publisherStats,
    refreshSearchQuery,
    isCountDataLoading,
  } = usePublisher();

  return (
    <Skeleton.Provider isLoading={isLoading || !publisher}>
      <div className="sm:mb-20 mb-0 w-full justify-start px-0 lg:px-16">
        <BreadCrumbs />
        <div className="[&>*]:mb-8 sm:[&>*]:mb-16">
          <ProfileDetails
            countData={countData}
            value={publisher}
            isDarkTheme
            isLoading={!publisher}
            className="!bg-black rounded-[24px] !border-[#1F1F1F] md:!border-0 lg:p-6 md:p-6 sm:p-4 p-4"
            classNames={{
              identicon:
                'rounded-[8px] lg:w-20 md:w-20 sm:w-12 w-12 lg:h-20 md:h-20 sm:h-12 h-12   ',
            }}
            showIcon={false}
            totalRewardEarned={publisherStats?.totalUserRewards}
            totalSpent={publisherStats?.totalUserSponsor}
          />
          <ProfileResources
            refreshCountHandler={refreshSearchQuery}
            isCountDataLoading={isCountDataLoading}
            value={publisher}
            isLoading={!publisher}
            isDarkTheme
            countData={countData}
          />
        </div>
      </div>
    </Skeleton.Provider>
  );
};
export default Publisher;
