import { HTMLAttributes } from 'types';

export function MethodGearIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" rx="6" fill="#222121" />
      <path
        d="M16 19C17.6569 19 19 17.6569 19 16C19 14.3431 17.6569 13 16 13C14.3431 13 13 14.3431 13 16C13 17.6569 14.3431 19 16 19Z"
        stroke="white"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 16.8814V15.1214C6 14.0814 6.85 13.2214 7.9 13.2214C9.71 13.2214 10.45 11.9414 9.54 10.3714C9.02 9.4714 9.33 8.3014 10.24 7.7814L11.97 6.7914C12.76 6.3214 13.78 6.6014 14.25 7.3914L14.36 7.5814C15.26 9.1514 16.74 9.1514 17.65 7.5814L17.76 7.3914C18.23 6.6014 19.25 6.3214 20.04 6.7914L21.77 7.7814C22.68 8.3014 22.99 9.4714 22.47 10.3714C21.56 11.9414 22.3 13.2214 24.11 13.2214C25.15 13.2214 26.01 14.0714 26.01 15.1214V16.8814C26.01 17.9214 25.16 18.7814 24.11 18.7814C22.3 18.7814 21.56 20.0614 22.47 21.6314C22.99 22.5414 22.68 23.7014 21.77 24.2214L20.04 25.2114C19.25 25.6814 18.23 25.4014 17.76 24.6114L17.65 24.4214C16.75 22.8514 15.27 22.8514 14.36 24.4214L14.25 24.6114C13.78 25.4014 12.76 25.6814 11.97 25.2114L10.24 24.2214C9.33 23.7014 9.02 22.5314 9.54 21.6314C10.45 20.0614 9.71 18.7814 7.9 18.7814C6.85 18.7814 6 17.9214 6 16.8814Z"
        stroke="white"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
