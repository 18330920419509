import { useApiKeysQuery } from 'gql';
import { useCookies } from 'react-cookie';
const useApiKeys = () => {
  const [{ account, sessionKey: sessionKeyCookie }] = useCookies(['account', 'sessionKey']);
  const { data, loading, refetch } = useApiKeysQuery({
    variables: {
      address: account?.address,
      sessionKey: sessionKeyCookie,
    },
  });

  const apiKeysRefetch = () => refetch({ address: account?.address, sessionKey: sessionKeyCookie });
  const apiKeys = data?.apiKeys;
  return { apiKeys, hasKeys: !!apiKeys && apiKeys?.length > 0, loading, apiKeysRefetch };
};

export default useApiKeys;
