import { HTMLAttributes } from 'types';

export function GradientCircle(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="0.5" y="0.5" width="35" height="35" rx="17.5" stroke="#1F1F1F" />
      <rect
        x="0.5"
        y="0.5"
        width="35"
        height="35"
        rx="17.5"
        stroke="url(#paint0_linear_4001_15563)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4001_15563"
          x1="18"
          y1="36"
          x2="18"
          y2="0.000454897"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.03" stopColor="#8D74F7" />
          <stop offset="0.45" stopColor="#D285F7" />
          <stop offset="0.95" stopColor="#FFAD97" />
        </linearGradient>
      </defs>
    </svg>
  );
}
