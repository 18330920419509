import { HTMLAttributes } from 'types';

export function Cycle(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="32" height="32" rx="6" fill="#222121" />
      <path
        d="M18.125 24.0596C21.7 23.118 24.3333 19.868 24.3333 16.0013C24.3333 11.4013 20.6333 7.66797 16 7.66797C10.4416 7.66797 7.66663 12.3013 7.66663 12.3013M7.66663 12.3013V8.5013M7.66663 12.3013H9.34163H11.3666"
        stroke="white"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.66663 16C7.66663 20.6 11.4 24.3333 16 24.3333"
        stroke="white"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3 3"
      />
    </svg>
  );
}
