import { Dispatch, SetStateAction } from 'react';

import { TruncateString } from '@analog/ui/src';
// eslint-disable-next-line import/order
import { Button, CopyButton, Publisher, Statistics } from 'components';
import { useView } from 'contexts';
import { useWindowSize } from 'hooks';

const DesktopStatistics = ({
  setIsQueryModalOpen,
}: {
  setIsQueryModalOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const {
    windowSize: { width },
    isMobile,
  } = useWindowSize();
  const { resource: view } = useView();

  return (
    <Statistics
      value={[
        ...(!isMobile
          ? [
              {
                value: (
                  <Button
                    variant="darkThemeOutlined"
                    className=" lg:min-w-[77px] flex-1 !font-normal"
                    onClick={() => setIsQueryModalOpen(true)}
                  >
                    Query
                  </Button>
                ),
              },
            ]
          : []),
        {
          label: 'Hash',
          value: (
            <div
              data-cy={`${view?.hashId}`}
              className="flex items-center md:text-base leading-none sm:text-xl text-[18px] !text-white"
            >
              <TruncateString text={view?.hashId as string} textLength={width > 1190 ? 14 : 7} />
              <CopyButton className="ml-2" value={view?.hashId} />
            </div>
          ),
        },
        {
          label: 'Publisher',
          value: view && (
            <Publisher
              isTruncated
              withLink
              value={view?.publisherAddress as string}
              className="sm:text-xl text-[18px] !text-white hover:underline"
            />
          ),
        },
        // TODO: enable ViewStatus When new architecture is implemented
        // {
        //   label: 'Index Status',
        //   value: (
        //     <Skeleton.Loader isDarkTheme containerClassName="w-1/2 h-5" isLoading={loading}>
        //       <Status
        //         color={status?.status === 'Active' ? 'green' : 'red'}
        //         dotClassName="mr-3 top-[unset]"
        //         className="flex flex-row-reverse gap-2 text-lg"
        //       >
        //         {status?.status || 'Inactive'}
        //       </Status>
        //     </Skeleton.Loader>
        //   ),
        // },
      ]}
      isDarkTheme
      withBorder={width >= 640 ? true : false}
      classNames={{
        base: 'lg:h-auto h-full sm:flex grid auto-cols-max grid-cols-2 md:grid-cols-4 sm:!overflow-auto !overflow-visible !overflow-y-visible border-[#1F1F1F] border border-solid py-4 lg:px-6 md:px-3 rounded-2xl xl:mt-4 mt-2 min-h-[88px] [grid-area:footer]',
        strings: 'flex-col-reverse gap-1 ',
        label:
          '!text-sm !text-[#B2B3B8] capitalize !overflow-visible break-words md:whitespace-pre whitespace-pre-line sm:max-w-[max-content] ',
        separator: 'h-[61px]',
        stat: 'sm:mr-auto m-0 break-words first:m-0',
      }}
    />
  );
};

export default DesktopStatistics;
