import { HTMLAttributes } from 'types';

export function PlusGradientIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M6 12H18"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 12H18"
        stroke="url(#paint0_linear_2226_3005)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 18V6"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 18V6"
        stroke="url(#paint1_linear_2226_3005)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2226_3005"
          x1="12"
          y1="14.5"
          x2="12"
          y2="8.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.03" stopColor="#8D74F7" />
          <stop offset="0.45" stopColor="#D285F7" />
          <stop offset="0.95" stopColor="#FFAD97" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2226_3005"
          x1="12.5"
          y1="18"
          x2="12.5"
          y2="3"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.03" stopColor="#8D74F7" />
          <stop offset="0.45" stopColor="#D285F7" />
          <stop offset="0.95" stopColor="#FFAD97" />
        </linearGradient>
      </defs>
    </svg>
  );
}
