import { HTMLAttributes } from 'types';

export function QuestionIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <circle cx="8" cy="8" r="7" stroke="#B2B3B8" strokeWidth="0.7" />
      <circle cx="7.9382" cy="11.8034" r="0.391327" fill="#B2B3B8" />
      <path
        d="M7.88737 10.3914V9.80827C7.88737 9.3222 8.13029 8.8683 8.53473 8.59867L8.94202 8.32715C9.55865 7.91606 9.92904 7.22399 9.92904 6.48288V6.30802C9.92904 5.18044 9.01495 4.26636 7.88737 4.26636V4.26636C6.75979 4.26636 5.8457 5.18044 5.8457 6.30802V6.30802"
        stroke="#B2B3B8"
        strokeWidth="0.8"
      />
    </svg>
  );
}
