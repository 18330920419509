export function EthereumLogo() {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none">
      <circle cx="12" cy="12.5" r="12" fill="#343434" />
      <path
        d="M12.0007 4.78564L11.8945 5.13724V15.339L12.0007 15.4422L16.8557 12.6431L12.0007 4.78564Z"
        fill="white"
        fillOpacity="0.7"
      />
      <path d="M11.9997 4.78564L7.14453 12.6431L11.9997 15.4422V10.4906V4.78564Z" fill="#8C8C8C" />
      <path
        d="M12.0012 16.3387L11.9414 16.4099V20.0439L12.0012 20.2142L16.8592 13.541L12.0012 16.3387Z"
        fill="white"
        fillOpacity="0.3"
      />
      <path d="M11.9997 20.2142V16.3387L7.14453 13.541L11.9997 20.2142Z" fill="#8C8C8C" />
      <path d="M12 15.4419L16.855 12.6427L12 10.4902V15.4419Z" fill="white" fillOpacity="0.6" />
      <path
        d="M7.14453 12.6427L11.9997 15.4419V10.4902L7.14453 12.6427Z"
        fill="white"
        fillOpacity="0.5"
      />
    </svg>
  );
}
