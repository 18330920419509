import { Dispatch, SetStateAction } from 'react';

import Tooltip from '@mui/material/Tooltip';
// eslint-disable-next-line import/order
import { Button, FormatBalance, GradientArrow, Skeleton } from 'components';
import { useApi, useView } from 'contexts';
import { Link } from 'react-router-dom';
import { routes } from 'routes/routesConst';

const AddFundsCard = ({
  loading,
  setIsFundModalOpen,
  balance,
}: {
  loading?: boolean;
  setIsFundModalOpen: Dispatch<SetStateAction<boolean>>;
  balance?: string;
}) => {
  const { resource: view } = useView();
  const {
    account,
    chainProps: { tokenDecimals },
  } = useApi();

  return (
    <div className="md:mt-2 bg-[#0E0E0E] border border-[#1F1F1F] rounded-2xl overflow-hidden flex flex-col [grid-area:right]">
      <div className="md:px-6 px-4 xl:pt-5 pt-[10px] h-full flex justify-center flex-col">
        <div className="flex md:gap-[65px] gap-[10px]  md:items-center items-start md:mb-5 mb-4 md:flex-row flex-col">
          <div className="flex flex-col gap-[5px] flex-1 md:w-[unset] w-full">
            <Skeleton.Loader isDarkTheme className="h-[35px]" isLoading={loading || !tokenDecimals}>
              <h4 className="text-sm font-normal leading-[22px] text-[#B2B3B8]">
                <Skeleton.Loader isDarkTheme>View balance</Skeleton.Loader>
              </h4>
              <h5 className="text-white text-2xl font-normal leading-normal">
                <Skeleton.Loader isDarkTheme>
                  <FormatBalance
                    value={balance || ''}
                    options={{ decimalPlaces: 5, tokenDecimals: 0 }}
                  />
                </Skeleton.Loader>
              </h5>
            </Skeleton.Loader>
          </div>
          <Skeleton.Loader isDarkTheme className="w-[150px] h-6">
            <Tooltip
              placement="top"
              classes={{ tooltip: '!max-w-[200px]' }}
              title={
                !account?.address && (
                  <div
                    data-cy="To fund a view, please connect your wallet."
                    className="text-sm  text-center text-[#F7F8F8] font-normal"
                  >
                    To fund a view, please connect your wallet.
                  </div>
                )
              }
              arrow
            >
              <Button
                data-cy="add-funds-btn"
                variant="darkThemeFilled"
                className="w-full flex-1 !font-normal"
                onClick={() => setIsFundModalOpen(true)}
                isDisabled={!account?.address}
              >
                Add funds
              </Button>
            </Tooltip>
          </Skeleton.Loader>
        </div>
      </div>
      <Link
        to={`${routes.fundingActivity}/${view?.hashId}`}
        className="bg-[linear-gradient(271deg,_#8d74f729_3%,_#ffad9700_95%)] md:h-[56px] h-[42px] md:px-6 px-[15px] md:py-4 py-3 flex items-center justify-between border-solid border-0 border-t border-[#1F1F1F] mt-auto"
      >
        <Skeleton.Loader isDarkTheme containerClassName="w-full">
          <h4
            data-cy="funding-activity-btn"
            className="bg-[linear-gradient(0deg,_#8D74F7_3%,_#D285F7_45%,_#FFAD97_95%)] text-transparent bg-clip-text text-sm font-normal leading-snug"
          >
            Funding Activity
          </h4>
          <GradientArrow />
        </Skeleton.Loader>
      </Link>
    </div>
  );
};

export default AddFundsCard;
