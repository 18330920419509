import { createRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Tab } from '@headlessui/react';
import { Card, Header } from 'components';
import { useViewBuilder } from 'contexts';
import { useTabs, useWindowSize } from 'hooks';
import { useBreadCrumbsRoot } from 'hooks/useBreadCrumbs';
import { classes } from 'utils';

import { QueryDisplay } from './components/QueryDisplay/QueryDisplay';
import { SpecControl } from './components/SpecControl/SpecControl';
import { SmartContractList } from './SmartContractList/SmartContractList';

const HEIGHT = 'calc(100vh - 236px)';

const useViewBuilderHook = () => {
  useBreadCrumbsRoot([{ page: 'ViewBuilder' }]);

  const {
    selectedTab,
    setSelectedTab,
    functionOptions,
    errorFunctionSpecs,
    scroll,
    setScroll,
    errorSpec,
    setErrorSpec,
    functionSpecs,
  } = useViewBuilder();
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    setIsClicked(false);
  }, []);

  const { windowSize } = useWindowSize();

  const myRef = useRef([]);

  const { tabPanels, tabSelector, tabs } = useTabs(
    [
      {
        child: <SmartContractList itemRef={myRef.current[0]} />,
        label: `Smart Contracts`,
        value: 'Smart Contracts',
      },
      {
        child: <SpecControl />,
        label: `Your Query`,
        value: 'yourQuery',
      },
      {
        child: (
          <QueryDisplay
            style={windowSize.width > 991 ? { height: HEIGHT } : {}}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        ),
        label: `Console`,
        value: 'console',
      },
    ],
    undefined,
    {
      classNames: {
        base: classes(
          'w-full test flex items-center bg-transparent overflow no-scrollbar',
          !!errorFunctionSpecs?.length && '[&>*:nth-child(2)]:text-[#F66]'
        ),
        tab: (selected) =>
          classes(
            'whitespace-pre py-[8px] md:px-[16px] px-[12px] flex-1 lg:text-[12px] text-sm',
            selected && 'bg-[#FFFFFF1A] text-white rounded-[32px] px-4'
          ),
      },
    }
  );

  myRef.current = useMemo(() => tabs.map((el, i) => myRef.current[i] ?? createRef()), []);

  const content = useMemo(() => {
    if (windowSize.width > 991) {
      return (
        <div className="flex h-full w-full min-w-full lg:flex-row flex-col gap-6">
          <Card
            style={{ height: HEIGHT }}
            className="relative flex lg:flex-row bg-[linear-gradient(180deg,#000_20.37%,#000_79.34%)] border shadow-[0px_-2px_52px_0px_rgba(200,200,200,0.06)_inset] rounded-[20px] border-solid border-[#ffffff1f] overflow-hidden"
          >
            <SmartContractList />
            <SpecControl />
          </Card>
          <QueryDisplay
            style={{ height: HEIGHT }}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        </div>
      );
    }
    return (
      <div className="">
        <Tab.Group onChange={(tab) => tab === 1 && setScroll(true)}>
          <Header
            accessory={tabSelector}
            accessoryClassname=" w-full max-w-[350px] "
            className="mb-[16px] items-start justify-start block lg:overscroll-none overflow-auto no-scrollbar"
          />
          <Card
            className="bg-[#000] rounded-[25px] overflow-hidden
          border-[#1F1F1F]"
          >
            {tabPanels}
          </Card>
        </Tab.Group>
      </div>
    );
  }, [windowSize.width, tabSelector, tabPanels, functionOptions, selectedTab, setSelectedTab]);

  const scrollToError = useCallback(
    (type: 'up' | 'down') => {
      const tempIndex = errorFunctionSpecs.findIndex(
        (el) => el?.identifier === errorSpec?.identifier
      );

      const index = type === 'up' ? tempIndex - 1 : tempIndex + 1;
      if (errorFunctionSpecs[index]) {
        setErrorSpec(errorFunctionSpecs[index]);
      }
      setScroll(true);
    },
    [errorFunctionSpecs, errorSpec?.identifier]
  );

  useEffect(() => {
    if (errorFunctionSpecs) {
      const index = functionSpecs.findIndex((spec) => spec.identifier === errorSpec?.identifier);

      if (scroll) {
        const top = document.getElementById(`${index}-details`)?.offsetTop;
        document.getElementById('spec-wrapper')?.scrollTo({
          top: top && top - 14,
          behavior: 'smooth',
        });
        setScroll(false);
      }
    }
  }, [errorFunctionSpecs, scroll]);

  return {
    isClicked,
    setIsClicked,
    errorFunctionSpecs,
    scrollToError,
    errorSpec,
    content,
  };
};

export default useViewBuilderHook;
