import { BalanceHistoryStatsType } from 'gql';
import { useWindowSize } from 'hooks';

import DesktopBalanceHistory from './components/DesktopBalanceHistory';
import MobileBalanceHistory from './components/MobileBalanceHistory';

export const BalanceHistoryRow = ({ row }: { row: BalanceHistoryStatsType | null }) => {
  const {
    windowSize: { width },
  } = useWindowSize();

  if (width < 992) {
    return <MobileBalanceHistory row={row} />;
  }

  return <DesktopBalanceHistory row={row} />;
};
