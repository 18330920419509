import { HTMLAttributes } from 'types';

export function Download(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7 8.39258L3.25 8.39258C2.65326 8.39258 2.08097 8.59012 1.65901 8.94175C1.23705 9.29338 1 9.7703 1 10.2676L1 15.2676C1 15.7649 1.23705 16.2418 1.65901 16.5934C2.08097 16.945 2.65326 17.1426 3.25 17.1426L16.75 17.1426C17.3467 17.1426 17.919 16.945 18.341 16.5934C18.7629 16.2418 19 15.7649 19 15.2676L19 10.2676C19 9.7703 18.7629 9.29338 18.341 8.94175C17.919 8.59012 17.3467 8.39258 16.75 8.39258L13 8.39258M7 11.5176L10 14.0176M10 14.0176L13 11.5176M10 14.0176L10 3.39258"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
