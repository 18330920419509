import { Button, Card } from 'components';
import { arrayOfSize, classes } from 'utils';

import useOnBoarding from './useOnBoarding';

export const OnBoarding = () => {
  const { page, contents, setPage, goBack, isAnswered, onSubmit } = useOnBoarding();
  return (
    <div className="w-full h-full flex flex-col items-center flex-1">
      <Card className="md:p-10 px-4 py-5 max-w-[573px] flex flex-col rounded-3xl border border-solid border-[#1F1F1F] bg-gradient-to-b from-[#000_20.37%] to-[#000_79.34%] shadow-3xl text-white">
        <div className="flex w-full h-1 items-center justify-center space-x-2">
          {/* TODO: change to 5 */}
          {arrayOfSize(3).map((_, i) => {
            return (
              <div
                className={classes(
                  'flex-1 h-full rounded',
                  i <= page
                    ? 'bg-[linear-gradient(92deg,_var(--tw-gradient-stops))] from-[#CEC0F3_0.3%] to-[#C38AF4_53.07%]'
                    : 'bg-[#2A2B3A]'
                )}
                key={`pill-${i}`}
              />
            );
          })}
        </div>

        {contents}
        <div
          className={classes(
            'grid md:grid-cols-3 grid-cols-2 min-h-12 md:mt-8 mt-6 gap-3 btn-group-wrap',
            !!page && 'justify-between'
          )}
        >
          <Button
            className="h-[48px] md:w-[140px] w-full font-normal group back-btn-wrap m-0"
            variant="darkThemeOutlined"
            onClick={() => {
              if (page > 0) {
                setPage((prev) => Math.max(0, prev - 1));
              } else {
                goBack();
              }
            }}
            classNames={{
              container:
                'leading-6 text-base group-hover:text-[#000000] leading-6 text-[#F7F8F8] font-normal',
            }}
          >
            Back
          </Button>
          {!page && (
            <div className="flex-1 md:h-[48px] h-full flex lg:justify-end justify-start a-developer-btn-wrap m-0">
              <Button
                variant="link"
                className="h-full !text-white !normal-case md:p-2 !p-0"
                onClick={goBack}
              >
                I&apos;m not a developer
              </Button>
            </div>
          )}
          <Button
            isDisabled={!isAnswered}
            variant="darkThemeFilled"
            onClick={onSubmit}
            className="md:w-[140px] w-full h-[48px] next-btn-wrap m-0"
            classNames={{ container: 'text-base leading-6 font-normal' }}
          >
            Next
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default OnBoarding;
