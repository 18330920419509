import { Dispatch, SetStateAction } from 'react';

import { Button, CopyButton, Publisher, Skeleton } from 'components';
import { useView } from 'contexts';
import { truncate } from 'utils';

const MobileStatistics = ({
  setIsQueryModalOpen,
}: {
  setIsQueryModalOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const { resource: view } = useView();

  return (
    <div className="rounded-[14px] border border-stone-900 pt-[10px] pb-4 px-4">
      <div className="py-[10px] border-b border-stone-900">
        <Skeleton.Loader isDarkTheme containerClassName="w-1/2 h-5">
          <div className="flex items-center md:text-base leading-none sm:text-xl text-[18px] !text-white">
            {truncate(view?.hashId as string)}
            <CopyButton className="ml-2" value={view?.hashId} />
          </div>
        </Skeleton.Loader>
        <Skeleton.Loader isDarkTheme className="mt-1 w-1/2">
          <div className="text-zinc-400 text-xs font-normal leading-tight">Hash</div>
        </Skeleton.Loader>
      </div>
      <div className="py-[10px] border-b border-stone-900">
        <Skeleton.Loader isDarkTheme containerClassName="w-1/2 h-5">
          <div className="flex items-center md:text-base leading-none sm:text-xl text-[18px] !text-white">
            {view && (
              <Publisher
                isTruncated
                withLink
                value={view?.publisherAddress as string}
                className="sm:text-xl text-[18px] !text-white hover:underline"
              />
            )}
          </div>
        </Skeleton.Loader>
        <Skeleton.Loader isDarkTheme className="mt-1 w-1/2">
          <div className="text-zinc-400 text-xs font-normal leading-tight">Publisher</div>
        </Skeleton.Loader>
      </div>
      {/* TODO: enable ViewStatus When new architecture is implemented */}
      {/* <div className="py-[10px] border-b border-stone-900">
    <Skeleton.Loader isDarkTheme className="w-1/2" isLoading={loading}>
      <Status
        color={status?.status === 'Active' ? 'green' : 'red'}
        dotClassName="m-0"
        className="flex flex-row-reverse justify-end gap-2 text-lg "
      >
        {status?.status || 'Inactive'}
      </Status>
    </Skeleton.Loader>
    <Skeleton.Loader isDarkTheme className="w-1/2 mt-1">
      <div className="text-zinc-400 text-xs font-normal  leading-tight">
        Index Status
      </div>
    </Skeleton.Loader>
  </div> */}
      <Skeleton.Loader isDarkTheme className="mt-2">
        <Button
          variant="darkThemeOutlined"
          className=" w-full flex-1 !font-normal mt-4"
          onClick={() => setIsQueryModalOpen(true)}
        >
          Query
        </Button>
      </Skeleton.Loader>
    </div>
  );
};

export default MobileStatistics;
