import { HTMLAttributes } from 'types';

export function DepositIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="32" height="32" rx="6" fill="#222121" />
      <path
        d="M16.5505 8.09975L16.5255 8.15808L14.1088 13.7664H11.7338C11.1671 13.7664 10.6255 13.8831 10.1338 14.0914L11.5921 10.6081L11.6255 10.5247L11.6838 10.3914C11.7005 10.3414 11.7171 10.2914 11.7421 10.2497C12.8338 7.72475 14.0671 7.14975 16.5505 8.09975Z"
        stroke="white"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.0417 13.931C20.6667 13.8143 20.2667 13.7643 19.8667 13.7643H14.1084L16.5251 8.15599L16.5501 8.09766C16.6751 8.13932 16.7917 8.19766 16.9167 8.24766L18.7584 9.02266C19.7834 9.44766 20.5001 9.88932 20.9334 10.4227C21.0167 10.5227 21.0834 10.6143 21.1417 10.7227C21.2167 10.8393 21.2751 10.956 21.3084 11.081C21.3417 11.156 21.3667 11.231 21.3834 11.2977C21.6084 11.9977 21.4751 12.856 21.0417 13.931Z"
        stroke="white"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.9351 19.4573V17.8323C23.9351 15.9906 22.7101 14.4406 21.0518 13.9323C20.6851 13.824 20.2851 13.7656 19.8768 13.7656H11.7434C11.1768 13.7656 10.6434 13.8823 10.1434 14.0906C8.69342 14.7156 7.67676 16.1573 7.67676 17.8323L7.6772 19.4573L7.67703 21.3159C7.67689 22.9628 9.0045 24.302 10.6514 24.3161L12.5518 24.3323H19.0518L20.9288 24.3322C22.5816 24.3321 23.923 22.9952 23.9286 21.3425L23.9351 19.4573ZM23.9351 19.4573C23.9351 19.624 23.9434 19.2906 23.9351 19.4573Z"
        stroke="white"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.592 10.6055L10.1337 14.0888C8.68366 14.7138 7.66699 16.1555 7.66699 17.8305V15.3888C7.66699 13.0221 9.35033 11.0471 11.592 10.6055Z"
        stroke="white"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.9322 15.3904V17.832C23.9322 15.9987 22.7155 14.4404 21.0488 13.9404C21.4822 12.857 21.6072 12.007 21.3988 11.2987C21.3822 11.2237 21.3572 11.1487 21.3238 11.082C22.8738 11.882 23.9322 13.5237 23.9322 15.3904Z"
        stroke="white"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
