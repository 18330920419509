import { HTMLAttributes } from 'types';

export function Avatar(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      data-cy="profile-btn"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1570_18139)">
        <rect width="32" height="32" rx="8" fill="#141414" />
        <path
          d="M15.6209 15.4655C15.5452 15.4582 15.4543 15.4582 15.371 15.4655C13.5688 15.4072 12.1377 13.9858 12.1377 12.2364C12.1377 10.4506 13.637 9 15.4998 9C17.355 9 18.8618 10.4506 18.8618 12.2364C18.8543 13.9858 17.4231 15.4072 15.6209 15.4655Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.9285 18.3045C10.1391 19.5193 10.1391 21.4989 11.9285 22.7061C13.9621 24.0858 17.297 24.0858 19.3305 22.7061C21.12 21.4914 21.12 19.5118 19.3305 18.3045C17.3044 16.9323 13.9694 16.9323 11.9285 18.3045Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1570_18139">
          <rect width="32" height="32" rx="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
