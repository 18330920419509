import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { Button, NoRecentData, Skeleton } from 'components';
import { DOCS } from 'consts';
import pluralize from 'pluralize';
import { Link } from 'react-router-dom';
import { classes } from 'utils';

import { SkeletonData } from './SkeletonData';
import { useDataCollections } from './useDataCollections';

export const DataCollections = ({ status }: { status?: string | undefined | null }) => {
  const {
    isRecentDataLoading,
    isResourceLoading,
    refreshed,
    setRefreshed,
    refreshRecentData,
    tableContent,
    recentData,
  } = useDataCollections();
  return (
    <Skeleton.Provider isLoading={isRecentDataLoading || isResourceLoading}>
      <div
        className={classes(
          'card sm:p-6 p-4 rounded-[24px] !bg-black !border-[#1F1F1F] border relative overflow-hidden'
        )}
      >
        <div className="flex flex-row justify-between items-center mb-5 gap-2">
          <Skeleton.Loader isDarkTheme className="w-[60px] h-[27px]">
            {(recentData as any)?.header?.length ? (
              <div className="text-lg text-[#FFF]">Cycles</div>
            ) : (
              <div></div>
            )}
          </Skeleton.Loader>
          <div className="flex items-center md:text-sm text-xs text-[#B2B3B8] flex-1 justify-end gap-2">
            <Skeleton.Loader
              isDarkTheme
              className="sm:h-[15px] h-10 sm:w-[161px] "
              containerClassName="flex-1"
            >
              Refreshed{' '}
              {refreshed < 60
                ? `${refreshed + pluralize(' Second', refreshed)}`
                : `${
                    Math.floor(refreshed / 60) + pluralize(' Minute', Math.floor(refreshed / 60))
                  }`}{' '}
              Ago
            </Skeleton.Loader>
            <Skeleton.Loader isDarkTheme className="h-10 w-10 rounded-full">
              <Button
                onClick={() => {
                  setRefreshed(1);
                  if (refreshRecentData) refreshRecentData();
                }}
                variant="plain"
                className="w-10 h-10 bg-[#141414] rounded-full p-[10px]  relative hover:opacity-50"
              >
                <ArrowPathIcon className="w-5 h-5" />
              </Button>
            </Skeleton.Loader>
          </div>
        </div>
        {/* {status !== 'Active' && isRecentDataLoading && isResourceLoading && (
          <div className="flex flex-col items-center justify-center gap-4 p-11 min-[600px]:h-80 h-[calc(100%_-_510px)] bg-[linear-gradient(0deg,_#000000_0%,_#000000a3_100%)] absolute bottom-0 left-0 w-full z-30">
            <h5 className="text-2xl">Sample Data</h5>
            <p className="text-base text-[#B2B3B8] text-center ">
              This View is not yet indexed due to insufficient funds. Add funds to get this View
              indexed and ready to be queried
            </p>
            <button className="py-2 mt-2 px-5 inline-flex w-auto text-[#010314] bg-white rounded-full text-sm font-normal">
              Add Funds
            </button>
          </div>
        )} */}

        {(recentData as any)?.data?.length ? (
          tableContent
        ) : isRecentDataLoading ? (
          <SkeletonData />
        ) : (
          <div className="flex flex-col items-center justify-center py-16">
            <Skeleton.Loader isDarkTheme className="!mb-5 h-[100px] w-[100px]">
              <NoRecentData />
            </Skeleton.Loader>
            <Skeleton.Loader isDarkTheme className="h-5 w-[200px] mb-2">
              <div className="mt-5 text-xl mb-2 text-center">
                {status === 'Active'
                  ? 'Your View is being indexed, please wait a moment.'
                  : 'Fund this view to index it.'}
              </div>
            </Skeleton.Loader>
            {/* <Skeleton.Loader isDarkTheme className="h-5 w-[150px]">
            <div className="text-sm text-[#B2B3B8]">Add funds to index this View!</div>
          </Skeleton.Loader> */}

            <Skeleton.Loader isDarkTheme className="h-5 w-[150px] mt-4">
              <Link
                target="_blank"
                to={`${DOCS}/developers/analog-watch/developers-guide/funding-views`}
              >
                <Button className="text-sm text-[#B2B3B8] mt-4" variant="darkThemeFilled">
                  Learn More
                </Button>
              </Link>
            </Skeleton.Loader>
          </div>
        )}
      </div>
    </Skeleton.Provider>
  );
};
