import { useEffect, useState } from 'react';

// eslint-disable-next-line import/order
import { useView } from 'contexts';
import { useWindowSize } from 'hooks';
import { tagTypeOrder } from 'utils';

import AddFundsCard from './components/AddFundsCard';
import HeaderImage from './components/HeaderImage';
import DesktopStatistics from './components/Statistics/DesktopStatistics';
import MobileStatistics from './components/Statistics/MobileStatistics';
import Summary from './components/Summary';
import FundView from './FundView';
import QueryModal from './QueryModal';

export const ViewDetails = ({
  loading,
  balance,
  minCost,
  minCycle,
}: // status,
{
  loading?: boolean;
  balance: string | undefined;
  minCost: string | undefined;
  minCycle?: number;
  // status?: ViewIndexingStatusType | undefined;
}) => {
  const {
    windowSize: { width },
  } = useWindowSize();

  const { resource: view } = useView();
  const [isFundModalOpen, setIsFundModalOpen] = useState(false);
  const [isQueryModalOpen, setIsQueryModalOpen] = useState(false);
  const [tooltipIsOpen, setTooltipIsOpen] = useState<boolean>(false);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      setTooltipIsOpen(!tooltipIsOpen);
    }
  };

  const sortedTags = view?.tags?.toSorted((a, b) => {
    if (a?.tagType && b?.tagType) return tagTypeOrder[a?.tagType] - tagTypeOrder[b?.tagType];
    return -1;
  });

  useEffect(() => {
    const isModalOpen = sessionStorage.getItem('isAddFundsOpen') === 'true';
    setIsFundModalOpen(isModalOpen);
  }, []);

  useEffect(() => {
    sessionStorage.removeItem('isAddFundsOpen');
  }, [isFundModalOpen]);

  return (
    <>
      <div className="card grid xl:grid-cols-[2fr_442px] grid-cols-1 xl:gap-x-6 xl:gap-0 gap-2 items-start !mb-8 sm:mb-12 relative sm:p-6 p-4 !bg-[#000] rounded-[24px] border !border-[#1F1F1F] sm:!border-transparent xl:[grid-template-areas:'header_right'_'footer_footer'] [grid-template-areas:'header''right''footer']">
        <div className="flex w-full items-start justify-between xl:gap-3 gap-3  flex-col [grid-area:header]">
          <div className="flex justify-start sm:flex-row flex-col sm:gap-6 gap-3 items-start break-all">
            <HeaderImage />
            <Summary
              handleKeyDown={handleKeyDown}
              setTooltipIsOpen={setTooltipIsOpen}
              sortedTags={sortedTags}
              tooltipIsOpen={tooltipIsOpen}
            />
          </div>
        </div>

        {width > 767 ? (
          <DesktopStatistics setIsQueryModalOpen={setIsQueryModalOpen} />
        ) : (
          <MobileStatistics setIsQueryModalOpen={setIsQueryModalOpen} />
        )}

        <AddFundsCard balance={balance} loading={loading} setIsFundModalOpen={setIsFundModalOpen} />

        {isFundModalOpen && (
          <FundView
            setIsFundModalOpen={setIsFundModalOpen}
            view={view}
            minCost={minCost}
            minCycle={minCycle}
          />
        )}
        {isQueryModalOpen && <QueryModal isOpen setIsOpen={setIsQueryModalOpen} />}
      </div>
    </>
  );
};
