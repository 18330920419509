import { HTMLAttributes } from 'types';

export function EarningsIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="308"
      height="180"
      viewBox="0 0 308 180"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_1833_106564"
        // style="mask-type:alpha"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="-97"
        width="308"
        height="277"
      >
        <rect y="-97" width="308" height="277" fill="url(#paint0_linear_1833_106564)" />
      </mask>
      <g mask="url(#mask0_1833_106564)">
        <g filter="url(#filter0_f_1833_106564)">
          <circle
            cx="157"
            cy="77"
            r="90"
            fill="url(#paint1_linear_1833_106564)"
            fillOpacity="0.2"
          />
        </g>
        <path
          d="M196 73.2353C196 81.3571 177.42 87.9412 154.5 87.9412C131.58 87.9412 113 81.3571 113 73.2353M196 49.7059C196 41.584 177.42 35 154.5 35C131.58 35 113 41.584 113 49.7059V120.294C113 128.416 131.58 135 154.5 135C177.42 135 196 128.416 196 120.294V49.7059Z"
          stroke="white"
        />
        <path
          d="M196 49.7051C196 57.8269 177.42 64.411 154.5 64.411C131.58 64.411 113 57.8269 113 49.7051M196 96.7639C196 104.886 177.42 111.47 154.5 111.47C131.58 111.47 113 104.886 113 96.7639"
          stroke="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_1833_106564"
          x="-117"
          y="-197"
          width="548"
          height="548"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="92" result="effect1_foregroundBlur_1833_106564" />
        </filter>
        <linearGradient
          id="paint0_linear_1833_106564"
          x1="154"
          y1="13.3204"
          x2="154"
          y2="151.82"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D9D9D9" />
          <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1833_106564"
          x1="67"
          y1="-13"
          x2="255.342"
          y2="-3.80166"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0364583" stopColor="#DED1E1" />
          <stop offset="0.911458" stopColor="#CA2CF1" />
        </linearGradient>
      </defs>
    </svg>
  );
}
