import { useEffect, useMemo, useState } from 'react';

import { Card, Sql } from 'components';
import { useApi, useViewBuilder } from 'contexts';
import { useTabs, useWindowSize } from 'hooks';
import { useSearchParams } from 'react-router-dom';
import { classes } from 'utils';

import { ResultDisplay } from '../ResultDisplay';

import { Props } from './QueryDisplay';

const useQueryDisplay = (props: Props) => {
  const {
    isManualMode,
    setIsManualMode,
    setIsDeployOpen,
    editorRef,
    validate,
    isDryRunValid,
    isTestable,
    isDryRunning,
    duplicateColumn,
    statusClassName,
    statusIcon,
    statusMessage,
    untouched,
    viewDef,
    setViewDef,
    generatedViewDef,
    functionSpecs,
    isGraphIdValid,
    isGraphIdTouched,
    setIsGraphIdTouched,
    dryRunError,
    setScroll,
    executeDryRun,
  } = useViewBuilder();

  const [isOpen, setIsOpen] = useState(false);
  const { account } = useApi();
  const { windowSize } = useWindowSize();
  const [searchParams, setSearchParams] = useSearchParams();

  const { tabSelector, tabPanels } = useTabs(
    [
      {
        child: (
          <Sql
            className="pt-0 flex-1 scroll h-full"
            onChanges={(editor) => {
              if (editor.hasFocus()) {
                const latest = editor.getValue();
                setViewDef(latest);
                if (!isManualMode && latest !== generatedViewDef) {
                  setIsManualMode(true);
                }
              }
            }}
            value={viewDef}
            options={{ fixedGutter: false }}
            ref={editorRef}
          />
        ),
        label: 'Definition',
        value: 'definition',
      },
      {
        child: <ResultDisplay />,
        label: 'Test Run',
        value: 'testRun',
        disabled: !functionSpecs?.length,
      },
    ],
    [props.selectedTab, props.setSelectedTab],
    {
      classNames: {
        base: 'h-full p-0 w-auto space-x-4 relative top-[1px]',
        tab: (isSelected) =>
          classes(
            'flex items-center whitespace-nowrap border-0 text-base py-2',
            isSelected && 'px-3 bg-[#ffffff1c] rounded-[32px] text-white'
          ),
      },
    }
  );
  useEffect(() => {
    if (!isManualMode) {
      editorRef.current && editorRef.current.editor.setValue(generatedViewDef);
    }
  }, [editorRef, generatedViewDef, isManualMode]);

  useEffect(() => {
    if (props.selectedTab === 1) {
      executeDryRun();
    }
  }, [props.selectedTab]);

  const Component = useMemo(() => {
    if (windowSize.width < 992) {
      return 'div';
    }
    return Card;
  }, [windowSize.width]);

  return {
    Component,
    isManualMode,
    setIsDeployOpen,
    validate,
    isDryRunValid,
    isTestable,
    isDryRunning,
    duplicateColumn,
    statusClassName,
    statusIcon,
    statusMessage,
    untouched,
    functionSpecs,
    isGraphIdValid,
    isGraphIdTouched,
    setIsGraphIdTouched,
    dryRunError,
    setScroll,
    tabSelector,
    tabPanels,
    account,
    searchParams,
    setSearchParams,
    isOpen,
    setIsOpen,
  };
};

export default useQueryDisplay;
