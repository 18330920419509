/* eslint-disable @typescript-eslint/ban-ts-comment */

import { Tab } from '@headlessui/react';
import { Header, Skeleton } from 'components';
import { PublisherDetailsFragment, SearchLibraryCountQuery } from 'gql';
import reactStringReplace from 'react-string-replace';
import { HTMLAttributes, Maybe, Setter } from 'types';
import { classes } from 'utils';

import useProfileResources from './useProfileResources';

interface Props extends HTMLAttributes<HTMLDivElement> {
  value: Maybe<PublisherDetailsFragment>;
  apiComponent?: React.ReactNode;
  isDarkTheme?: boolean;
  additionalButton?: React.ReactNode;
  isLoading?: boolean;
  isCountDataLoading?: boolean;
  fundedViewComponent?: React.ReactNode;
  countData?: Maybe<SearchLibraryCountQuery>;
  refreshCountHandler: () => void;
  setTab?: Setter<number>;
  tab?: number;
}

export function ProfileResources({
  apiComponent,
  isDarkTheme,
  additionalButton,
  fundedViewComponent,
  countData,
  isCountDataLoading,
  refreshCountHandler,
  tab,
  setTab,
}: Props) {
  const { selectedTab, setSelectedTab, tabSelector, tabs, tabPanels } = useProfileResources({
    fundedViewComponent,
    countData,
    tab,
    isCountDataLoading,
    refreshCountHandler,
    apiComponent,
  });
  return (
    <div>
      <Tab.Group
        selectedIndex={selectedTab}
        onChange={(index: number) => {
          setTab && setTab(index);
          setSelectedTab(index);
        }}
      >
        <Header
          className="flex-col-reverse "
          accessory={
            apiComponent && (
              <Skeleton.Loader className="h-10 sm:w-[378px] w-[330px] !rounded-full !bg-[#FFFFFF1C] !backdrop:blur-md">
                {tabSelector}
              </Skeleton.Loader>
            )
          }
          additionalButton={selectedTab === 1 && additionalButton}
          hClassName={classes(
            isDarkTheme &&
              'text-white sm:text-[32px] text-[20px] sm:self-center self-start sm:order-1 order-2'
          )}
          accessoryClassname={classes('sm:order-2 order-3 h-auto')}
        >
          {tabs[selectedTab].alternateLabel ||
            reactStringReplace(tabs[selectedTab].label as string, /(\(\d+\))$/, (match) => (
              <Skeleton.Loader className="">
                <span key="parens" className="font-normal text-neutral-500">
                  {match}
                </span>
              </Skeleton.Loader>
            ))}
        </Header>
        {tabPanels}
      </Tab.Group>
    </div>
  );
}
