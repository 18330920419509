import { ElementType } from 'react';

import { ArbitrumLogo, EthereumLogo, PolkaJSYellowIcon, PolygonLogo, SolanaLogo } from 'components';
import { Chain, ResponseOption } from 'types';
import { classes } from 'utils';

export const chains: ResponseOption<Chain>[] = [
  ...(
    [
      ['ethereum', 'Ethereum', EthereumLogo],
      ['polygon', 'Polygon', PolygonLogo],
      ['arbitrum', 'Arbitrum', ArbitrumLogo],
      ['solana', 'Solana', SolanaLogo],
      ['polkadot', 'Polkadot', PolkaJSYellowIcon],
    ] as [Chain, string, ElementType][]
  ).map(([chain, name, Logo]: [Chain, string, ElementType]) => {
    return {
      label: (
        <div className="flex items-center leading-none space-x-2">
          <Logo
            className={classes(
              chain === 'polygon' && 'rotate-[290deg]',
              chain === 'polkadot' && 'rounded-full'
            )}
          />
          <span>{name}</span>
        </div>
      ),
      value: chain,
    };
  }),
  {
    label: 'Other',
    value: 'other',
  },
];
