import { HTMLAttributes } from 'types';

export function ViewBuilderIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.99969 12.7884C11.149 12.7884 12.9076 11.0299 12.9076 8.88055C12.9076 6.73121 11.149 4.97266 8.99969 4.97266C6.85035 4.97266 5.0918 6.73121 5.0918 8.88055C5.0918 11.0299 6.85035 12.7884 8.99969 12.7884Z"
        stroke="white"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.22412 10.6595L10.5399 7.34375"
        stroke="white"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5399 10.6595L7.22412 7.34375"
        stroke="white"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.355469 9.76934V8.22367C0.355469 7.31031 1.07984 6.55504 1.97466 6.55504C3.51715 6.55504 4.14778 5.43091 3.37228 4.0521C2.92913 3.2617 3.19331 2.23418 3.96882 1.7775L5.44314 0.908058C6.11638 0.495292 6.98563 0.741195 7.38616 1.43499L7.47991 1.60186C8.24689 2.98067 9.50815 2.98067 10.2837 1.60186L10.3774 1.43499C10.7779 0.741195 11.6472 0.495292 12.3204 0.908058L13.7947 1.7775C14.5703 2.23418 14.8344 3.2617 14.3913 4.0521C13.6158 5.43091 14.2464 6.55504 15.7889 6.55504C16.6752 6.55504 17.4081 7.30153 17.4081 8.22367V9.76934C17.4081 10.6827 16.6837 11.438 15.7889 11.438C14.2464 11.438 13.6158 12.5621 14.3913 13.9409C14.8344 14.7401 14.5703 15.7588 13.7947 16.2155L12.3204 17.085C11.6472 17.4977 10.7779 17.2518 10.3774 16.558L10.2837 16.3912C9.51668 15.0123 8.25541 15.0123 7.47991 16.3912L7.38616 16.558C6.98563 17.2518 6.11638 17.4977 5.44314 17.085L3.96882 16.2155C3.19331 15.7588 2.92913 14.7313 3.37228 13.9409C4.14778 12.5621 3.51715 11.438 1.97466 11.438C1.07984 11.438 0.355469 10.6827 0.355469 9.76934Z"
        stroke="white"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
