import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import {
  ResourceQuery,
  ResourceSmartContract,
  SearchType as ResourceType,
  ResourceView,
  useRecentDataLazyQuery,
} from 'gql';
import { useResourceQuery } from 'hooks';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { Maybe, VoidFn } from 'types';

type Resource = ResourceQuery['resource'];

interface Value<T extends Resource = Resource> {
  resource: T;
  recentData?: Maybe<unknown[]>;
  isRecentDataLoading?: boolean;
  refreshRecentData?: VoidFn;
  isResourceLoading?: boolean;
}

export const ResourceContext = createContext<Value>({ resource: undefined });

export function ResourceProvider({
  children,
  type,
}: React.PropsWithChildren<{ type: ResourceType }>) {
  const navigate = useNavigate();
  const { id: identifier, network } = useParams<{ id?: string; network?: string }>();
  const location = useLocation();

  const [recentData, setRecentData] = useState<Maybe<unknown[]>>();
  // TODO: Change static data to dynamic. And it should be an identifier
  const {
    data: resourceQuery,
    error,
    loading: isResourceLoading,
  } = useResourceQuery({
    variables: { request: { identifier: identifier as string, type, network } },
  });

  const [executeRecentDataQuery, { loading: isRecentDataLoading }] = useRecentDataLazyQuery();

  const refreshRecentData = useCallback(async () => {
    if (
      !location.pathname.includes('funding-activity') &&
      identifier &&
      type !== ResourceType.SmartContracts
    ) {
      const update = await executeRecentDataQuery({
        variables: { request: { identifier, type } },
        fetchPolicy: 'cache-and-network',
      });

      setRecentData(update?.data?.recentData);
    }
  }, [executeRecentDataQuery, identifier, type]);

  const value = useMemo(
    () => ({
      isRecentDataLoading,
      resource: resourceQuery?.resource,
      recentData,
      refreshRecentData,
      isResourceLoading,
    }),
    [isRecentDataLoading, recentData, refreshRecentData, resourceQuery?.resource, isResourceLoading]
  );

  useEffect(() => {
    if (
      error?.message === 'No View found' ||
      error?.message === 'View not found' ||
      error?.message === 'No SmartContract found' ||
      error?.message === 'Smart contract not found'
    ) {
      navigate(routes[404], { replace: true });
    }
  }, [error?.message]);

  useEffect(() => {
    refreshRecentData();
  }, [refreshRecentData]);
  return <ResourceContext.Provider value={value}>{children}</ResourceContext.Provider>;
}

export const useResource = <T extends Resource>() => useContext(ResourceContext) as Value<T>;

export const useSmartContract = () => useResource<ResourceSmartContract>();

export const useView = () => useResource<ResourceView>();
