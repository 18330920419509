import { useApiKey as useApiKeyCtx } from 'contexts';
import { useTabs } from 'hooks';
import { classes } from 'utils';

import { Details } from './Details/Details';

export const useApiKey = () => {
  const { apiKey } = useApiKeyCtx();
  //TODO: Will need in future
  // const { sessionKey } = useApi();

  // const { loading: isStatsLoading, data: stats } = useSSkStatsQuery({
  //   variables: { ssk: sessionKey || '' },
  //   skip: !sessionKey,
  // });

  // const chartSummary = useMemo(() => {
  //   if (!isStatsLoading) {
  //     return [
  //       {
  //         value: stats?.SSkStats?.totalSskQuery,`
  //         name: 'Queries Total',
  //         color: '#FFAD97',
  //       },
  //       {
  //         value: stats?.SSkStats.totalSskQueryFee,
  //         name: 'Queries Fees Total',
  //         color: '#F539B5',
  //       },
  //       {
  //         value: +(stats?.SSkStats?.totalSskQueryFee || 0) / (stats?.SSkStats?.totalSskQuery || 1),
  //         name: 'Average Query Cost',
  //         color: '#7CA1F6',
  //       },
  //     ];
  //   }
  // }, [stats, isStatsLoading]);

  const { selectedTab, setSelectedTab, tabSelector, tabPanels } = useTabs(
    [
      { child: <Details />, label: 'Details', value: 'details' },
      // {
      //   child: (
      //     <Stats chartData={graphData?.SSKGraph} fetchChart={fetchChart} summary={chartSummary} />
      //   ),
      //   label: 'Stats',
      //   value: 'stats',
      // },
    ],
    0,
    {
      classNames: {
        tab: (_) => classes('h-[32px]', _ && 'bg-[#FFFFFF1A] rounded-[32px]'),
        base: 'bg-black p-1 items-center rounded-[32px] text-white text-[14px] w-[145px] max-h-[42px] border-solid !border-[#ffffff1a] border',
      },
    }
  );
  return { selectedTab, setSelectedTab, tabSelector, tabPanels, apiKey };
};
