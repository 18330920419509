import { Page } from 'types';

import { DocumentIcon, ViewBuilderIcon, ViewIcon } from '../../svg';

type HelpPage = {
  title: string;
  md?: string;
  Icon: React.ReactNode;
  open?: string;
  button?: {
    label: string;
    link: string;
  };
  embeddedUrl?: string;
};

export const HELP_PAGES: Partial<Record<Page | 'SmartContractList', HelpPage>> = {
  SmartContractList: {
    title: 'List Smart Contract',
    md: `- Click on the “Smart Contract” link at the top right of the screen. Ensure you’ve connected your wallet.
- Enter the smart contract details, including the contract address, identifier, and instance (e.g., testnet or mainnet).
- Select the chain where the smart contract is currently deployed and click “Add Functions”.
- Add the functions you want to use for your contract and click the “List” button.
`,
    Icon: <DocumentIcon className="h-[18px] w-[14px]  text-white" />,
    button: {
      label: 'Listing Smart Contracts',
      link: 'https://docs.analog.one/documentation/developers/analog-watch/developers-guide/listing-smart-contracts',
    },
    embeddedUrl: 'https://jumpshare.com/embed/oImHtHI26qJA189c1QHn',
  },
  View: {
    title: 'Deploy a View',
    md: `- Click on the View Builder.
- Select the contract(s) and the relevant function(s) you want to index from the left panel.
- Verify that the autogenerated SQL statements in the Definition tab of the Console match the particular requirements for your use case and click on the “Test Query” button.
- If satisfied with the query results, click “Deploy View” to proceed. Otherwise, modify the query in the “Your Query” section.
- In the next screen, enter the View's name (you can use the identifier), type tags and a description, and click the “Next” button.
`,

    Icon: <ViewBuilderIcon className="h-[18px] w-[18px]" />,
    button: {
      label: 'Deploying Views',
      link: 'https://docs.analog.one/documentation/developers/analog-watch/developers-guide/deploying-views',
    },
    embeddedUrl: 'https://jumpshare.com/embed/eXtTrHCf2TXtoJTOG6HO',
  },
  ViewBuilder: {
    title: 'Fund a View',
    md: `- Head over to the Library section by clicking on the “Library” link and select the View you want to fund. Ensure you’ve connected your wallet.
- Navigate to the “Add Funds” button and click on it.
- Enter the amount of tokens you would like to allocate to the View, then click the “Add Funds” button.
- Wait for the transaction to be finalized on the Timechain.
`,
    button: {
      label: 'Funding Views',
      link: 'https://docs.analog.one/documentation/developers/analog-watch/developers-guide/funding-views',
    },
    Icon: <ViewIcon className="h-[18px] w-[18px]" />,
    embeddedUrl: 'https://jumpshare.com/embed/EXtwrl22GqoukNnJ6aBU',
  },
};
