import { Skeleton } from 'components';
import { useWindowSize } from 'hooks';
import { arrayOfSize } from 'utils';

export const SkeletonData = ({ rowCount }: { rowCount?: number }) => {
  const { isMobile } = useWindowSize();
  if (isMobile) {
    return (
      <div className="flex flex-col gap-3">
        {arrayOfSize(rowCount || 3).map((el, index) => {
          return (
            <div
              key={index}
              className="flex flex-col text-sm text-white bg-[#0E0E0E] rounded-2xl p-4"
            >
              <div className="flex flex-row items-center gap-2">
                <Skeleton.Loader isDarkTheme className="w-8 h-8 !rounded-[5px]" />
                <Skeleton.Loader isDarkTheme className="sm:h-[15px] h-6 w-[180px]" />
              </div>
              <div className="mt-4 mb-3 bg-[#1F1F1F] h-[1px]" />
              <div className="flex md:flex-row flex-col justify-between">
                <div className="flex flex-1 gap-[6px]">
                  <Skeleton.Loader
                    isDarkTheme
                    containerClassName="w-full"
                    className="h-[24px] w-[90%]"
                  />
                </div>
                <div className="flex flex-1 gap-[6px]">
                  <Skeleton.Loader
                    isDarkTheme
                    containerClassName="w-full"
                    className="h-[24px] w-[90%]"
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
  return (
    <div className="w-full p-6">
      <div className="grid grid-cols-3 pl-3">
        <Skeleton.Loader
          isLoading
          isDarkTheme
          className="max-w-[35px]"
          containerClassName="flex flex-row w-full justify-between"
        />
        <Skeleton.Loader
          isLoading
          isDarkTheme
          className="max-w-[35px]"
          containerClassName="flex flex-row w-full justify-between"
        />
        <Skeleton.Loader
          isLoading
          isDarkTheme
          className="max-w-[35px]"
          containerClassName="flex flex-row w-full justify-between"
        />
      </div>
      {arrayOfSize(rowCount || 3).map((el, index) => {
        return (
          <div
            key={index}
            className="border-x-0 border-y border-neutral-200 text-xs border-none bg-[#0E0E0E] my-4 rounded-2xl pl-3 h-[43px] flex items-center"
          >
            <Skeleton.Loader
              isLoading
              className="max-w-[115px] !rounded-xl"
              containerClassName="flex flex-row w-full justify-between"
            />
            <Skeleton.Loader
              isLoading
              className="max-w-[115px] !rounded-xl"
              containerClassName="flex flex-row w-full justify-between"
            />
            <Skeleton.Loader
              isLoading
              className="max-w-[115px] !rounded-xl"
              containerClassName="flex flex-row w-full justify-between"
            />
          </div>
        );
      })}
    </div>
  );
};
