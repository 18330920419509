import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { createRoot } from 'react-dom/client';
// import { StrictMode } from 'react';
import TagManager from 'react-gtm-module';

import './index.css';

import App from './App';

const tagManagerArgs = {
  gtmId: import.meta.env.VITE_WATCH_FRONTEND_GTMID,
};

TagManager.initialize(tagManagerArgs);

const httpLink = new HttpLink({
  uri: import.meta.env.VITE_GRAPHQL_ENDPOINT,
});

export const client = new ApolloClient({
  link: ApolloLink.from([new RetryLink(), httpLink]),
  cache: new InMemoryCache(),
});

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);
  root.render(
    // <StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
    // </StrictMode>
  );
}
