import { HTMLAttributes } from 'types';

export function SolanaLogo(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <div
      className={`rounded-3xl flex items-center justify-center w-6 h-6 shrink-0 bg-black ${props.className}`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="13"
        height="11"
        viewBox="0 0 13 11"
        fill="none"
      >
        <g clipPath="url(#clip0_4787_20464)">
          <path
            d="M12.4382 8.69175L10.4572 10.7844C10.4142 10.8298 10.3621 10.8661 10.3042 10.8909C10.2463 10.9156 10.1838 10.9284 10.1207 10.9284H0.729976C0.685167 10.9284 0.641335 10.9155 0.603866 10.8913C0.566396 10.8671 0.53692 10.8326 0.519059 10.7921C0.501199 10.7517 0.495733 10.7069 0.503331 10.6634C0.51093 10.6199 0.531263 10.5796 0.561832 10.5473L2.54429 8.45465C2.58724 8.40931 2.63917 8.37314 2.69688 8.34838C2.7546 8.32361 2.81686 8.31078 2.87981 8.31067H12.27C12.3148 8.31067 12.3587 8.32357 12.3962 8.34777C12.4336 8.37197 12.4631 8.40644 12.481 8.44691C12.4988 8.4874 12.5043 8.53212 12.4967 8.57562C12.4891 8.61912 12.4688 8.65948 12.4382 8.69175ZM10.4572 4.47777C10.4142 4.43232 10.3621 4.39607 10.3042 4.3713C10.2463 4.34654 10.1838 4.33377 10.1207 4.33381H0.729976C0.685167 4.33381 0.641335 4.3467 0.603866 4.3709C0.566396 4.39511 0.53692 4.42956 0.519059 4.47004C0.501199 4.51052 0.495733 4.55526 0.503331 4.59876C0.51093 4.64224 0.531263 4.68261 0.561832 4.71488L2.54429 6.80752C2.58724 6.85286 2.63917 6.88902 2.69688 6.91379C2.7546 6.93855 2.81686 6.95139 2.87981 6.95149H12.27C12.3148 6.95149 12.3587 6.9386 12.3962 6.91439C12.4336 6.89018 12.4631 6.85573 12.481 6.81526C12.4988 6.77478 12.5043 6.73004 12.4967 6.68654C12.4891 6.64305 12.4688 6.60269 12.4382 6.57041L10.4572 4.47777ZM0.729976 2.97462H10.1207C10.1838 2.97465 10.2463 2.96189 10.3042 2.93712C10.3621 2.91235 10.4142 2.8761 10.4572 2.83065L12.4382 0.738008C12.4688 0.705739 12.4891 0.665377 12.4967 0.62188C12.5043 0.578383 12.4988 0.533648 12.481 0.49317C12.4631 0.452693 12.4336 0.418235 12.3962 0.394032C12.3587 0.369829 12.3148 0.356935 12.27 0.356934H2.87981C2.81686 0.357039 2.7546 0.369871 2.69688 0.394636C2.63917 0.419401 2.58724 0.455571 2.54429 0.500907L0.562343 2.59355C0.531804 2.62578 0.51148 2.66611 0.503864 2.70954C0.496248 2.753 0.501671 2.7977 0.519468 2.83816C0.537265 2.87862 0.566663 2.91307 0.604056 2.93731C0.641449 2.96155 0.685211 2.97452 0.729976 2.97462Z"
            fill="url(#paint0_linear_4787_20464)"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_4787_20464"
            x1="1.51294"
            y1="11.1803"
            x2="11.1944"
            y2="0.100274"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.08" stopColor="#9945FF" />
            <stop offset="0.3" stopColor="#8752F3" />
            <stop offset="0.5" stopColor="#5497D5" />
            <stop offset="0.6" stopColor="#43B4CA" />
            <stop offset="0.72" stopColor="#28E0B9" />
            <stop offset="0.97" stopColor="#19FB9B" />
          </linearGradient>
          <clipPath id="clip0_4787_20464">
            <rect width="12" height="10.5714" fill="white" transform="translate(0.5 0.356934)" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}
