import { useMemo, useState } from 'react';

import { Definition, Skeleton, Sql } from 'components';
import { useViewDefinitionQuery } from 'gql';
import { useTabs } from 'hooks';
import { useParams } from 'react-router-dom';
import { format as sqlFormatter } from 'sql-formatter';
import { classes, countLines } from 'utils';

import { DataCollections } from './Components/DataCollections/DataCollections';
import { ViewSmartContractList } from './Components/ViewSmartContractList/ViewSmartContractList';

const useViewTab = () => {
  const [triggerQuery, setTriggerQuery] = useState(false);
  const { id: hashId } = useParams<{ id?: string }>();

  const { data: sqlData, loading: isSqlDataLoading } = useViewDefinitionQuery({
    variables: { hashId: hashId! },
    skip: !triggerQuery,
  });

  const sql = useMemo(
    () => sqlFormatter(sqlData?.ViewDefinition?.ViewDefinition?.sql || ''),
    [sqlData]
  );

  const { selectedTab, setSelectedTab, tabPanels, tabSelector, tabs } = useTabs(
    [
      {
        child: <DataCollections />,
        label: `Data`,
        value: 'data',
      },
      {
        child: <ViewSmartContractList />,
        label: 'Smart Contract',
        value: 'stats',
      },
      {
        child: (
          <>
            <Definition lineCount={sql ? countLines(sql) : 0} copyString={sql}>
              <Skeleton.Loader
                className="bg-[#ffffff1f] p-2 sm:min-h-[50px] min-h-[50px] !rounded-full m-7 max-w-[50%]"
                isLoading={isSqlDataLoading}
              >
                <Sql
                  className="bg-black pt-0 sm:max-h-[572px] max-h-[420px] overflow-y-scroll scrollbar-white"
                  options={{ readOnly: true }}
                  value={sql}
                />
              </Skeleton.Loader>
            </Definition>
          </>
        ),
        label: `Definition`,
        value: 'definition',
      },

      // Views details need info  #564 (removed tasks only)

      // {
      //   child: <TaskLists />,
      //   label: `Tasks`,
      //   value: 'tasks',
      // },

      // NOTE: Stats is temporary hide
      // {
      //   alternateLabel: 'Query Stats',
      //   child: <Stats viewName={view?.name} stats={stats} />,
      //   label: 'Stats',
      //   value: 'stats',
      // },
    ],
    0,
    {
      classNames: {
        base: 'card text-xs w-full !bg-transparent rounded-[32px] backdrop-blur-[12px] !border-[#ffffff33] p-1 sm:max-w-[404px] max-h-[40px]',
        tab: (_) =>
          classes(
            ' text-white text-sm !whitespace-pre sm:px-4 px-2 ',
            _ && 'bg-[#1B1B1B] rounded-[32px]'
          ),
      },
    },
    (newTab) => {
      if (newTab.value === 'definition') {
        setTriggerQuery(true);
      } else {
        setTriggerQuery(false);
      }
    }
  );

  return { selectedTab, setSelectedTab, tabPanels, tabSelector, tabs };
};

export default useViewTab;
