import { HTMLAttributes } from 'types';

export function GradientArrow(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M14.4297 5.93005L20.4997 12.0001L14.4297 18.0701"
        stroke="url(#paint0_linear_198_44762)"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.5 12.0001H20.33"
        stroke="url(#paint1_linear_198_44762)"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_198_44762"
          x1="18.938"
          y1="54.4901"
          x2="12.0505"
          y2="54.2614"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.03" stopColor="#8D74F7" />
          <stop offset="0.45" stopColor="#D285F7" />
          <stop offset="0.95" stopColor="#FFAD97" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_198_44762"
          x1="16"
          y1="16.0001"
          x2="7.5"
          y2="6.50006"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.03" stopColor="#8D74F7" />
          <stop offset="0.74" stopColor="#D285F7" />
          <stop offset="0.95" stopColor="#FFAD97" />
        </linearGradient>
      </defs>
    </svg>
  );
}
