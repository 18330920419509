import { useMemo } from 'react';

import { CopyButton, InfinitySvg, Skeleton, Statistics, Status } from 'components';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { HTMLAttributes, ApiKey as Value } from 'types';
import { classes } from 'utils';

interface Props extends HTMLAttributes<HTMLLinkElement> {
  value?: Value;
}

export const ApiKey = ({ value: propsValue }: Props) => {
  const [isActive, linkContents, linkClassName] = useMemo(() => {
    const isActive = propsValue?.status === 'enabled';
    return [
      isActive,
      <>
        <Skeleton.Loader
          isDarkTheme
          className="sm:min-w-[60px] sm:min-h-[60px] min-w-[42px] min-h-[42px]"
        >
          <InfinitySvg className="sm:min-w-[60px] sm:min-h-[60px] min-w-[42px] min-[42px]" />
        </Skeleton.Loader>

        <div className="relative  mx-2 w-fit md:w-auto text-ellipsis whitespace-pre-line overflow-hidden font-normal">
          <Skeleton.Loader isDarkTheme className="sm:w-[200px] h-[24px] w-[100px]">
            API Key:
          </Skeleton.Loader>
          <Skeleton.Loader isDarkTheme className="sm:w-[300px] h-[24px] w-[150px]">
            <div
              data-cy={`${propsValue?.key}`}
              className="text-white leading-6 truncate whitespace-pre-line"
            >
              {propsValue?.key}
            </div>
          </Skeleton.Loader>
        </div>
      </>,
      classes(
        'relative flex items-center whitespace-pre-line sm:w-fit w-[calc(100%_-_40px)]',
        isActive && 'hover:underline'
      ),
    ];
  }, [propsValue?.status, propsValue?.key]);

  return (
    <Skeleton.Provider isLoading={!propsValue}>
      <div className="card mb-6 sm:p-6 p-4 rounded-3xl border !border-[#1F1F1F] bg-gradient-to-b from-[#000_20.37%] to-[#000_79.34%]">
        <div className="lg:flex block w-full items-center justify-between md:text-lg font-medium text-white">
          <div className="flex text-ellipsis whitespace-pre-line lg:overflow-visible overflow-hidden">
            {isActive && propsValue?.key ? (
              <Link
                to={`${routes.apiKey}/${propsValue?.key}`}
                title={propsValue?.key}
                className={linkClassName}
              >
                {linkContents}
              </Link>
            ) : (
              <div className={linkClassName}>{linkContents}</div>
            )}
            <Skeleton.Loader isDarkTheme className="h-10 w-10 !rounded-full">
              <CopyButton
                className="bg-[#141414] h-10 w-10 rounded-full mt-auto"
                value={propsValue?.key || ''}
              />
            </Skeleton.Loader>
          </div>
          <div className="lg:w-[320px] w-full lg:border-l lg:border-t-0 lg:pt-0 border-t border-[#1f1f1f] lg:mr-2 lg:ml-6 lg:mt-0 mt-6 lg:pl-4 pt-4 md:w-auto text-ellipsis whitespace-pre-line overflow-hidden font-normal">
            <Skeleton.Loader isDarkTheme className="sm:w-[200px] h-[24px] w-[100px]">
              Project Name:
            </Skeleton.Loader>
            <Skeleton.Loader isDarkTheme className="sm:w-[300px] h-[24px] w-[150px]">
              <div data-cy={`${propsValue?.name}`} className="text-white leading-6 truncate">
                {propsValue?.name}
              </div>
            </Skeleton.Loader>
          </div>
        </div>
        <div className="mt-[-6px] flex w-full">
          <Statistics
            withBorder
            isDarkTheme
            classNames={{
              base: 'grid-flow-col border-[#1F1F1F] gap-6 sm:h-[70px] sm:pt-5 sm:mt-6 mt-4 p-0 !pt-4 h-auto sm:overflow-visible',
              label:
                'font-medium text-sm text-white text-[18px] font-normal mb-[3px] sm:text-[18px] text-[16px]',
              stat: ' text-white m-0 flex-1',
              value: '!text-[12px] !text-[#B2B3B8]',
            }}
            value={[
              {
                label: 'Date Created',
                value: propsValue?.createdAt
                  ? moment(propsValue?.createdAt).format('MM/DD/YYYY')
                  : '',
              },
              {
                label: 'Status',
                value: (
                  <Status
                    color={isActive ? 'green' : 'red'}
                    dotClassName="self-center items-center top-0"
                  >
                    {isActive ? 'Active' : 'Inactive'}
                  </Status>
                ),
              },
            ]}
          />
        </div>
      </div>
    </Skeleton.Provider>
  );
};
