import BigNumber from 'bignumber.js';
import { FundsModal } from 'components/FundsModal/FundsModal';
import useWithdrawFund from 'hooks/tokenomics/useWithdrawFund';

interface WithdrawFundProps {
  accountBalance?: BigNumber;
  setIsOpen: ((_: boolean) => void) | undefined;
  successModalSubmitTo?: string;
  renderContent?: React.ReactElement;
}

const WithdrawFund = ({ renderContent, ...props }: WithdrawFundProps) => {
  const {
    amount,
    amountError,
    error,
    fundRefAddress,
    handleSubmit,
    isWithdrawLoading,
    setAmount,
    LOWER_LIMIT,
  } = useWithdrawFund();

  return (
    <FundsModal
      {...props}
      amount={amount}
      setAmount={setAmount}
      amountError={amountError}
      error={error}
      fundRefAddress={fundRefAddress}
      onSubmit={handleSubmit}
      isLoading={isWithdrawLoading}
      showBalance
      isOpen
      texts={{
        header: ' Withdraw $TANLOG',
        headerSub:
          'To withdraw $TANLOG from your Analog Watch account. Note that withdrawing $TANLOG tokens from the Watch account locks it from deploying and querying views on the Analog Watch.',
        submit: 'Withdraw',
        inputLabel: 'Withdraw amount',
        inputPlaceHolder: 'Amount',
      }}
      successTexts={{
        header: `Great news! Your funds have been successfully withdrawed. 🎉`,
        submitToButton: 'See My Profile',
      }}
      renderContent={renderContent}
      LOWER_LIMIT={LOWER_LIMIT}
    />
  );
};

export default WithdrawFund;
