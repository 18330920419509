import { Button, CheckIcon, Form, GradientCircle, Loader, Modal } from 'components';
import { classes } from 'utils';

import { Props } from './types';
import useEditMetadata from './useEditMetadata';

export function EditMetadata({
  isLoading,
  labels,
  isOpen,
  setIsOpen,
  name,
  link,
  setName,
  description,
  setDescription,
  useCases,
  setUseCases,
  useCasesOption,
  setUseCasesOption,
  submitForGame,
  setSubmitForGame,
  onSubmit,
  isValid,
  ...editTags
}: Props) {
  const { step, setFormError, formContent, setStep, handleSubmitActions } = useEditMetadata({
    isLoading,
    labels,
    isOpen,
    setIsOpen,
    name,
    link,
    setName,
    description,
    setDescription,
    useCases,
    setUseCases,
    useCasesOption,
    setUseCasesOption,
    submitForGame,
    setSubmitForGame,
    onSubmit,
    isValid,
    ...editTags,
  });

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className="sm:py-6 py-6 px-0 max-w-[560px] max-[400px]:max-h-[90%]"
      classNames={{
        close: 'md:-top-12 -top-11',
        dialog: 'z-[100]',
      }}
      isDarkTheme
    >
      <div className="w-full  overflow-y-auto max-h-[75vh] scrollbar-white">
        <div className="flex items-center sm:px-6 px-4 mb-6">
          <div
            className={classes(
              'relative flex items-center justify-center text-sm h-8 min-w-8 rounded-full py-[4px] px-[8px]'
            )}
          >
            <GradientCircle className="absolute h-full w-full" />
            {step == 2 ? <CheckIcon /> : '01'}
          </div>
          <div
            className={classes(
              'h-[1px] w-full bg-[#1C1C1C]',
              step == 2 && 'bg-[linear-gradient(0deg,_#8D74F7_3%,_#D285F7_45%,_#FFAD97_95%)]'
            )}
          />
          <div
            className={classes(
              'relative flex items-center justify-center text-sm h-8 min-w-8 rounded-full py-[4px] px-[8px]',
              step == 1 && 'border border-solid border-[#1F1F1F]'
            )}
          >
            {step == 2 && <GradientCircle className="absolute h-full w-full" />}
            02
          </div>
        </div>
        <Form
          className="flex w-full flex-1 flex-col"
          onSubmit={() => {
            if (useCases.length) {
              setFormError((prev) => ({
                ...prev,
                useCase: {
                  isError: false,
                  isTouched: false,
                },
              }));
              onSubmit();
            } else {
              setFormError((prev) => ({
                ...prev,
                useCase: {
                  isError: true,
                  isTouched: true,
                },
              }));
            }
          }}
        >
          {isLoading ? (
            <Loader className="flex h-[400px] items-center text-white" />
          ) : (
            <div className="flex flex-col gap-6">
              {formContent}
              <div className="flex items-center gap-[19px] sm:px-6 px-4 flex-row max-[400px]:flex-col justify-between">
                {labels.helpLabel}
                <div className={classes('flex items-center gap-4 w-fit max-[400px]:w-full')}>
                  {step > 1 && (
                    <Button
                      variant="darkThemeOutlined"
                      className="text-base h-12 px-6 rounded-full w-fit max-[400px]:w-full"
                      onClick={(e: React.MouseEvent) => {
                        e.preventDefault();
                        setStep((prev) => prev - 1);
                      }}
                    >
                      Back
                    </Button>
                  )}
                  {step == 2 ? (
                    <Button
                      data-cy={`${labels.submitButton}`}
                      variant="darkThemeFilled"
                      isSubmit
                      className="text-base h-12 px-6 rounded-full w-fit max-[400px]:w-full"
                    >
                      {labels.submitButton}
                    </Button>
                  ) : (
                    <Button
                      variant="darkThemeFilled"
                      className="text-base h-12 px-6 rounded-full w-fit max-[400px]:w-full"
                      onClick={handleSubmitActions}
                    >
                      {step === 3 ? 'Save' : 'Next'}
                    </Button>
                  )}
                </div>
              </div>
            </div>
          )}
        </Form>
      </div>
    </Modal>
  );
}
