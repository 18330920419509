import { ResourceLogo, Skeleton } from 'components';
import { useView } from 'contexts';
import { classes } from 'utils';

const HeaderImage = () => {
  const { resource: view } = useView();

  return (
    <div className="flex sm:justify-between justify-start sm:w-auto w-full sm:items-start items-center gap-3 sm:gap-6">
      <div className="flex flex-col relative">
        <Skeleton.Loader isDarkTheme className="md:h-[128px] h-[65px] md:w-[128px] w-[65px]">
          <div className="absolute top-2/4 left-2/4 rounded-[59px] bg-[linear-gradient(287deg,_var(--tw-gradient-stops))] from-[#342B49_3%] via-[#342238_45%] to-[#533838_95%] filter blur-[25px] h-1/2 w-20 -translate-y-2/4 -translate-x-2/4"></div>
          <ResourceLogo
            value={view}
            className={classes(
              'relative sm:h-32 h-16 sm:min-h-fit min-h-[65px] bg-[#d3cccc14] rounded-[16px] sm:w-32 w-full m-0 '
            )}
          />
          <div className="absolute bottom-0 right-0 hidden sm:flex sm:h-[18px] h-[10px] items-center justify-center uppercase bg-[linear-gradient(287deg,_var(--tw-gradient-stops))] from-[#342B49_3%] via-[#342238_45%] to-[#533838_95%] text-white sm:text-[8px] text-[4px] rounded-[16px_0px] p-[1px_10px]">
            View
          </div>
        </Skeleton.Loader>
      </div>
      <h2 className="flex sm:hidden text-white text-lg">
        <Skeleton.Loader isDarkTheme className="sm:w-60 w-[120px]">
          {view?.name}
        </Skeleton.Loader>
      </h2>
    </div>
  );
};

export default HeaderImage;
