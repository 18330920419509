import { HTMLAttributes } from 'types';

export function Copy(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.3 11.6218V15.6118C14.3 18.9368 12.97 20.2668 9.645 20.2668H5.655C2.33 20.2668 1 18.9368 1 15.6118V11.6218C1 8.2968 2.33 6.9668 5.655 6.9668H9.645C12.97 6.9668 14.3 8.2968 14.3 11.6218Z"
        stroke="white"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.9992 5.92258V9.91258C19.9992 13.2376 18.6692 14.5676 15.3442 14.5676H14.2992V11.6226C14.2992 8.29758 12.9692 6.96758 9.64422 6.96758H6.69922V5.92258C6.69922 2.59758 8.02922 1.26758 11.3542 1.26758H15.3442C18.6692 1.26758 19.9992 2.59758 19.9992 5.92258Z"
        stroke="white"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
