import { Tab } from '@headlessui/react';
import { Header, Modal, Skeleton } from 'components';
import { DeploySuccess } from 'components/DeploySuccess';
import SelectFunctions from 'components/SelectFunctions/SelectFunctions';
import reactStringReplace from 'react-string-replace';
import { pathTo } from 'utils';

import useCollectionsContract from './useCollectionsContract';

export const CollectionsContract = () => {
  const {
    selectedTab,
    setSelectedTab,
    tabSelector,
    account,
    setIsAddFunctionOpen,
    tabs,
    tabPanels,
    isAddFunctionOpen,
    clearDryRunContractResult,
    mergeContractResult,
    isMergeContractError,
    smartContractAddress,
    smartContract,
    CloseModal,
  } = useCollectionsContract();

  return (
    <div>
      <Tab.Group selectedIndex={selectedTab} onChange={setSelectedTab}>
        <Header
          accessory={
            <Skeleton.Loader isDarkTheme className="h-10 sm:w-[280px] w-full">
              {tabSelector}
            </Skeleton.Loader>
          }
          className="text-white sm:flex-row flex-col-reverse sm:items-center items-start gap-4 sm:mb-6 mb-3 "
          hClassName="sm:text-[32px] text-[20px]"
          additionalButton={
            <Skeleton.Loader className="h-5 w-20 !rounded-2xl">
              {account && (
                <button
                  data-cy="add-function-btn-sc-detail"
                  className="bg-[linear-gradient(0deg,_#8D74F7_3%,_#D285F7_45%,_#FFAD97_95%)] text-transparent bg-clip-text text-sm"
                  onClick={() => setIsAddFunctionOpen(true)}
                >
                  Add Functions
                </button>
              )}
            </Skeleton.Loader>
          }
        >
          {tabs[selectedTab].alternateLabel ||
            reactStringReplace(tabs[selectedTab].label as string, /(\(\d+\))$/, (match) => (
              <Skeleton.Loader isDarkTheme className="h-5 w-[500px]">
                <span key="parens" className="font-normal text-neutral-500">
                  {match}
                </span>
              </Skeleton.Loader>
            ))}
        </Header>
        {tabPanels}
      </Tab.Group>
      <Modal
        isDarkTheme
        isOpen={isAddFunctionOpen}
        setIsOpen={setIsAddFunctionOpen}
        classNames={{ dialog: 'z-[100]' }}
        onClose={clearDryRunContractResult}
      >
        <div className="w-full">
          {mergeContractResult && !isMergeContractError && !smartContractAddress ? (
            <DeploySuccess
              submitTo={pathTo(
                'SmartContract',
                smartContract?.address as string,
                smartContract?.networks[0] as string
              )}
              labels={{
                text: 'You have successfully added the new function(s) into the smart contract!',
                button: 'See Smart Contract',
              }}
              isOpen
              setIsOpen={setIsAddFunctionOpen}
              isModal={false}
              isDarkTheme
            />
          ) : (
            <SelectFunctions isAddFunctions tags={smartContract?.tags} CloseModal={CloseModal} />
          )}
        </div>
      </Modal>
    </div>
  );
};
