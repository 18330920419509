import { BreadCrumbs, Skeleton } from 'components';
import { useView } from 'contexts';
import { useViewStatsQuery } from 'gql';
import { useBreadCrumbsChild } from 'hooks/useBreadCrumbs';
import { useParams } from 'react-router-dom';

import { ViewDetails } from './Components/ViewDetails/ViewDetails';
import ViewTab from './Components/ViewDetailsTab/ViewDetailsTab';

const View = () => {
  const { id: hashId } = useParams<{ id?: string }>();
  useBreadCrumbsChild({ page: 'View', params: [hashId] }, []);

  const { resource: view, isResourceLoading } = useView();

  const { data: stats, loading: isStatsLoading } = useViewStatsQuery({
    variables: { viewName: view?.name as string, viewHash: view?.hashId as string },
    fetchPolicy: 'cache-and-network',
    skip: !view?.name,
  });

  return (
    <Skeleton.Provider isLoading={isResourceLoading}>
      <div className="mb-20 lg:p-16 !pt-0 px-0  w-full justify-start text-white">
        <BreadCrumbs />
        <div className="[&>*]:mb-16">
          <ViewDetails
            loading={isStatsLoading}
            balance={stats?.ViewStats.fundLockedInView}
            minCost={stats?.ViewStats.minimumSponsor?.amount as string}
            minCycle={stats?.ViewStats.minimumSponsor?.cycles as number}
            // status={stats?.ViewStats.viewIndexingStatus as ViewIndexingStatusType | undefined}
          />
        </div>
        <ViewTab />
      </div>
    </Skeleton.Provider>
  );
};

export default View;
