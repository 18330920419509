import { HTMLAttributes } from 'types';

export function DocumentationIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="308"
      height="185"
      viewBox="0 0 308 185"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_1833_106585"
        // style="mask-type:alpha"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="-99"
        width="308"
        height="284"
      >
        <rect y="-99" width="308" height="284" fill="url(#paint0_linear_1833_106585)" />
      </mask>
      <g mask="url(#mask0_1833_106585)">
        <g filter="url(#filter0_f_1833_106585)">
          <circle
            cx="157"
            cy="75"
            r="90"
            fill="url(#paint1_linear_1833_106585)"
            fillOpacity="0.2"
          />
        </g>
        <path
          d="M144.35 53.9501H164.55C174.65 53.9501 174.65 48.9001 174.65 43.85C174.65 33.75 169.6 33.75 164.55 33.75H144.35C139.3 33.75 134.25 33.75 134.25 43.85C134.25 53.9501 139.3 53.9501 144.35 53.9501Z"
          stroke="white"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M174.65 43.9512C191.467 44.8602 199.9 51.0717 199.9 74.1503V104.45C199.9 124.65 194.85 134.75 169.6 134.75H139.3C114.05 134.75 109 124.65 109 104.45V74.1503C109 51.1222 117.434 44.8602 134.25 43.9512"
          stroke="white"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M154.447 96.8755V95.8152C154.447 92.3812 156.568 90.5631 158.689 89.0986C160.76 87.6845 162.83 85.8666 162.83 82.5336C162.83 77.8876 159.093 74.1504 154.447 74.1504C149.801 74.1504 146.064 77.8876 146.064 82.5336"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M154.426 108.949H154.472"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_1833_106585"
          x="-117"
          y="-199"
          width="548"
          height="548"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="92" result="effect1_foregroundBlur_1833_106585" />
        </filter>
        <linearGradient
          id="paint0_linear_1833_106585"
          x1="154"
          y1="14.1082"
          x2="154"
          y2="156.108"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D9D9D9" />
          <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1833_106585"
          x1="67"
          y1="-15"
          x2="255.342"
          y2="-5.80166"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0364583" stopColor="#DED1E1" />
          <stop offset="0.911458" stopColor="#CA2CF1" />
        </linearGradient>
      </defs>
    </svg>
  );
}
