import { HTMLAttributes } from 'types';

export function InfinitySvg(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_1833_109243)">
        <rect
          x="20"
          y="20"
          width="20"
          height="20"
          rx="10"
          fill="url(#paint0_linear_1833_109243)"
          fillOpacity="0.7"
          filter="blur(15px)"
        />
        <g filter="url(#filter0_bi_1833_109243)">
          <rect width="60" height="60" rx="8" fill="white" fillOpacity="0.08" />
        </g>
        <path
          d="M27.2694 25.9769C26.0394 24.2819 24.0744 23.1719 21.8094 23.1719C18.0444 23.1719 14.9844 26.2319 14.9844 29.9969C14.9844 33.7619 18.0444 36.8218 21.8094 36.8218C24.3444 36.8218 26.6994 35.4869 28.0044 33.3119L29.9994 29.9969L31.9794 26.6818C33.2844 24.5068 35.6394 23.1719 38.1744 23.1719C41.9394 23.1719 44.9994 26.2319 44.9994 29.9969C44.9994 33.7619 41.9394 36.8218 38.1744 36.8218C35.9244 36.8218 33.9594 35.7118 32.7144 34.0168"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_bi_1833_109243"
          x="-50"
          y="-50"
          width="160"
          height="160"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="25" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_1833_109243"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_1833_109243"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-3" />
          <feGaussianBlur stdDeviation="9.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_1833_109243" />
        </filter>
        <linearGradient
          id="paint0_linear_1833_109243"
          x1="30"
          y1="20"
          x2="30"
          y2="40"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B18F7E" />
          <stop offset="1" stopColor="#88296A" />
        </linearGradient>
        <clipPath id="clip0_1833_109243">
          <rect width="60" height="60" rx="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
