import React, { useState } from 'react';

import { useSmartContract, useViewBuilder } from 'contexts';
import { useWindowSize } from 'hooks';
import { tagTypeOrder } from 'utils';

const useSmartContractDetails = () => {
  const { resource: smartContract, isResourceLoading } = useSmartContract();

  const {
    windowSize: { width },
  } = useWindowSize();

  const [tooltipIsOpen, setTooltipIsOpen] = useState<boolean>(false);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      setTooltipIsOpen(!tooltipIsOpen);
    }
  };

  const sortedTags = smartContract?.tags?.toSorted((a, b) => {
    if (a?.tagType && b?.tagType) return tagTypeOrder[a?.tagType] - tagTypeOrder[b?.tagType];
    return -1;
  });

  const { setFunctionSpecs, setGraphId, setIsGraphIdTouched } = useViewBuilder();
  return {
    smartContract,
    isResourceLoading,
    sortedTags,
    tooltipIsOpen,
    setTooltipIsOpen,
    width,
    handleKeyDown,
    setFunctionSpecs,
    setGraphId,
    setIsGraphIdTouched,
  };
};

export default useSmartContractDetails;
