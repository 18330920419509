import { useContext } from 'react';

import { CheckBox } from 'components/CheckBox';
import { OptionProps, components } from 'react-select';
import { SelectOption } from 'types';
import { classes } from 'utils';

import { CustomizeContext } from '../Select';

export function Option<T>(props: OptionProps<SelectOption<T>, false>) {
  const { ltr, checkboxProps = {} } = useContext(CustomizeContext);
  const children = [
    <div className="flex-1" key="content">
      {props.children}
    </div>,
    <div key="checkbox">
      <CheckBox
        isChecked={props.isSelected}
        {...checkboxProps}
        classNames={{
          base: `${
            props.isSelected ? 'bg-white' : 'bg-black'
          } border border-[#47484A] rounded-full`,
          checkMark: 'text-black',
        }}
      />
    </div>,
  ];
  return (
    <components.Option {...props} className={classes(props.className)}>
      {ltr ? children.reverse() : children}
    </components.Option>
  );
}
