import BigNumber from 'bignumber.js';
import { FundsModal } from 'components/FundsModal/FundsModal';
import { ResourceView } from 'gql';
import useFundView from 'hooks/tokenomics/useFundView';
import { routes } from 'routes/routesConst';
import { Setter, View } from 'types';
import { formatBalance } from 'utils';

interface FundViewProps {
  setIsFundModalOpen: Setter<boolean>;
  view: View | ResourceView;
  minCost: string | undefined;
  minCycle?: number;
}

const FundView = ({ setIsFundModalOpen, view, minCost, minCycle }: FundViewProps) => {
  const {
    accountBalanceData,
    isSponsorViewLoading,
    tokenDecimals,
    amountError,
    handleSubmit,
    Summary,
    amount,
    setAmount,
    error,
    fundRefAddress,
  } = useFundView({
    view,
    minCost,
    minCycle,
  });

  return (
    <FundsModal
      showBalance={false}
      onlyAccountBalance
      accountBalance={new BigNumber(accountBalanceData?.userBalance?.balance as string)}
      isOpen
      setIsOpen={setIsFundModalOpen}
      onSubmit={handleSubmit}
      isLoading={isSponsorViewLoading}
      texts={{
        header: 'Add funds to index this View',
        headerSub:
          'To query this View, Analog Watch needs to index it first. You add the funds to cover the indexing fees, ensuring a minimum number of cycles while qualifying you for rewards when users query your View. Add funds now to index this View.',
        submit: 'Add Funds',
        inputLabel: 'Amount',
        inputPlaceHolder: formatBalance(new BigNumber(minCost || ''), {
          decimalPlaces: 5,
          symbol: '$TANLOG',
          tokenDecimals,
        }),
      }}
      successTexts={{
        header: "You've successfully funded the View!",
        submitToButton: 'View Funding Activity',
      }}
      successModalSubmitTo={`${routes.fundingActivity}/${view?.hashId}`}
      accountBalanceText="Your Balance"
      isAddFunds
      minimumReq={minCost}
      amount={amount}
      setAmount={setAmount}
      Summary={Summary}
      amountError={amountError}
      error={error}
      fundRefAddress={fundRefAddress}
    />
  );
};

export default FundView;
