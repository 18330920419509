import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import rehypeRaw from 'rehype-raw';
import { Setter } from 'types';
import { classes } from 'utils';

import { Details, Summary } from '../../Details';

import { HELP_PAGES } from './utils';

const HelpPageItems = ({
  openedSection,
  userProgress,
  setOpenedSection,
  setUserProgress,
}: {
  openedSection: number | null;
  userProgress: number[];
  setOpenedSection: Setter<number | null>;
  setUserProgress: Setter<number[]>;
}) => {
  return (
    <>
      {Object.entries(HELP_PAGES).map(
        ([page, { title, Icon, md, open, button, embeddedUrl }], index) => {
          if (!md && !embeddedUrl) {
            return (
              <div
                key={index}
                className="group border border-[#ffffff1f] text-white bg-gradient-to-t from-[#0E0E0E_0%] to-[#0E0E0E_100%] rounded-2xl w-full h-[55px] border-none p-3 cursor-pointer"
                onClick={() => {
                  window.open(open, '_self');
                }}
                role="button"
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    window.open(open, '_self');
                  }
                }}
              >
                <div className="relative flex mt-[-1px] items-center border-0 border-b border-[#ffffff1f] text-base border-none p-0">
                  <div className="bg-[#ffffff14] h-8 w-8 flex items-center justify-center rounded-lg mr-2">
                    {Icon}
                  </div>
                  <div className="text-sm">{title}</div>
                </div>
              </div>
            );
          }
          return (
            <Details
              key={index}
              open={openedSection === index}
              className={classes(
                'text-white bg-gradient-to-t from-[#0E0E0E_0%] to-[#0E0E0E_100%] rounded-2xl w-full border-none p-3 cursor-pointer',
                (openedSection === index || userProgress.includes(index)) &&
                  'bg-none bg-transparent rounded-2xl border border-solid border-[#1F1F1F] p-[11px]'
              )}
              onClick={(e) => {
                e.preventDefault();
                if (openedSection !== index) {
                  setOpenedSection(index);
                }
                if (!embeddedUrl && !userProgress.includes(index)) {
                  setUserProgress((arr) => [index, ...arr]);
                }
              }}
            >
              <Summary
                classNames={{
                  base: classes('border-none p-0 select-none'),
                }}
                onClick={() => {
                  if (openedSection === index) {
                    setOpenedSection(null);
                  }
                }}
              >
                <div className="bg-[#ffffff14] h-8 w-8 flex items-center justify-center rounded-lg mr-2">
                  {Icon}
                </div>
                <div className="text-sm">{title}</div>
              </Summary>
              <div className="md:mt-2 mt-0 text-[#B2B3B8]">
                {embeddedUrl && openedSection == index && (
                  <div className="md:w-[284px] w-[244px] h-[194px] bg-[#1c1c1c] relative">
                    <iframe
                      title="Embeded Help Video"
                      id={index.toString()}
                      src={embeddedUrl}
                      width="100%"
                      height="194"
                      allow="autoplay"
                      allowFullScreen
                    />
                  </div>
                )}
                <h4 className="md:mt-6 mt-3 mb-2 text-sm">{title}</h4>
                {md && (
                  <ReactMarkdown
                    rehypePlugins={[rehypeRaw as never]}
                    className="markdown text-xs leading-[18px] !mb-0"
                  >
                    {md}
                  </ReactMarkdown>
                )}
                {button && (
                  <div className="flex space-x-2 text-xs">
                    <span className="!ml-1">Refer to</span>
                    <Link
                      className="hyperlink !ml-1 flex items-center !bg-[linear-gradient(283deg,_#8D74F7_3%,_#D285F7_45%,_#FFAD97_95%)] bg-clip-text !text-transparent"
                      to={button.link}
                      target="_blank"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {button.label}
                    </Link>
                    <span className="!ml-1">to learn more</span>
                  </div>
                )}
              </div>
            </Details>
          );
        }
      )}
    </>
  );
};

export default HelpPageItems;
