import { Dispatch, SetStateAction } from 'react';

import { Input } from 'components/Input';

const CreateNewUseCase = ({
  setNewUsecase,
  newUsecase,
}: {
  setNewUsecase: Dispatch<SetStateAction<string>>;
  newUsecase: string;
}) => {
  return (
    <div className="py-6 sm:px-6 px-4 border border-x-0 border-solid border-[#1C1C1C]">
      <div className="text-xl">Add New Use Cases</div>
      <div className="flex-1 mt-4">
        <label htmlFor="usecase-name" className="text-white text-sm">
          Name of Use Case
        </label>
        <Input
          classNames={{ base: 'mt-2 h-12', input: 'pl-6' }}
          id={'usecase-name'}
          placeholder={'Please insert the name of use case'}
          onChange={(e) => {
            const filteredValue = e.replace(/[^a-z A-Z_]/g, ''); // Allow only letters,spaces and underscores
            filteredValue.length < 30 && setNewUsecase(filteredValue);
          }}
          value={newUsecase}
          filter={/^[^a-zA-z]/}
          isDarkTheme
        />
      </div>
    </div>
  );
};

export default CreateNewUseCase;
