import { useMemo } from 'react';

import { Image, SectionHeaderChip } from 'components';
import { useWindowSize } from 'hooks';

export const HowItWorks = () => {
  const { windowSize } = useWindowSize();

  const svg = useMemo(() => {
    if (windowSize.width <= 991) {
      return 'MobileHowItWorks.svg';
    }
    return 'DiagramsHowItWorks.svg';
  }, [windowSize]);

  return (
    <div className="flex flex-col justify-center items-center lg:my-[80px] lg:py-[80px] my-[50px] py-[50px]">
      <SectionHeaderChip text="Learn More" />
      <div className="text-white lg:mt-9 mt-[18px] lg:text-[60px] text-[32px] lg:leading-[56px] leading-[42px]">
        How It Works
      </div>
      <Image
        src={svg}
        loading="lazy"
        classNames={{ image: 'lg:mt-[62px] mt-[28px] w-full', base: 'w-full' }}
      />
    </div>
  );
};
