import { reservedKeywords } from 'consts';
import { classes } from 'utils';

import { XMarkIcon } from '../icons';
import { Skeleton } from '../Skeleton';

import { tagClassName } from './TagButton';
import { Props$Tag } from './types';

const reservedKeywordsStyle: any = {
  verified: 'bg-[_linear-gradient(117deg,_#BCF0B8_25.76%,_#8ABE81_74.26%)]',
  recommended: 'bg-[linear-gradient(117deg,_#70BDD6_25.76%,_#4E9BBC_74.26%)]', //'bg-gradient-to-r from-blue-400 via-blue-500 to-teal-400',
  trending: 'bg-[linear-gradient(275deg,_#8D74F7_5.07%,_#D285F7_46.17%,_#FFAD97_95.11%)]',
};

export function Tag({
  as: Component = 'li',
  className,
  classNames,
  onRemove,
  value,
  isDarkTheme,
  isActive,
  icon,
  ...props
}: Props$Tag) {
  const tagValue = props?.children?.toString() || value?.name || '';
  const isReserved: boolean = reservedKeywords.some(
    (val) => val.toLowerCase() === tagValue.toLowerCase()
  );

  !icon &&
    isReserved &&
    (icon = (
      <img
        src={`/logos/reservedKeywordsIcon/${tagValue.toLowerCase()}.svg`}
        alt={tagValue}
        height={12}
        width={12}
      />
    ));

  return (
    <Component
      {...props}
      className={classes(
        'mb-2 h-6 max-w-[12rem]',
        isDarkTheme && 'rounded-2xl',
        classNames?.base,
        props.onClick && 'cursor-pointer'
      )}
    >
      <Skeleton.Loader
        isDarkTheme
        containerClassName="block h-full leading-none"
        heightFix
        isLoading={!value && !props.children}
        className="h-6 w-9"
      >
        <div
          data-cy={`${value?.slug}`}
          data-tag={value?.slug}
          className={classes(
            tagClassName,
            classNames?.tag,
            className,
            isDarkTheme && 'rounded-2xl px-[8px] py-[4px] bg-[#f7f8f81f] text-[#F7F8F8]',
            isActive && isDarkTheme && 'bg-white text-[#00010A]',
            icon && 'pl-1',
            isReserved && reservedKeywordsStyle[tagValue?.toLowerCase()]
          )}
        >
          {icon && (
            <div
              className={classes(
                'flex justify-center items-center h-5 w-5 rounded-full bg-[#343434] mr-[2px]',
                'bg-black'
              )}
            >
              {icon}
            </div>
          )}
          <div
            className={classes(
              'block pl-1 text-xs items-center overflow-hidden uppercase text-ellipsis whitespace-nowrap leading-none',
              classNames?.name,
              isReserved && 'text-black text-center font-normal  uppercase'
            )}
          >
            {props.children || value?.name}
          </div>
          {onRemove && (
            <button
              className="relative -top-[1px] cursor-pointer"
              data-tag={value?.slug}
              onClick={onRemove}
            >
              <XMarkIcon className="ml-1 h-4 w-4" />
            </button>
          )}
        </div>
      </Skeleton.Loader>
    </Component>
  );
}
