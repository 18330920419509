import { useCallback, useEffect, useMemo, useState } from 'react';

import { newTagSlug } from '@watch/common/dist/utils';
import { reservedKeywords } from 'consts';
import { TagBasicsFragment, TagInput, useSuggestionsLazyQuery, useTagsQuery } from 'gql';
import { Setter, Suggestion, VoidFn } from 'types';
import debounce from 'utils/debounce';

type TagInitial = Pick<TagBasicsFragment, 'slug' | 'name'>;

export interface UseEditTags {
  searchString: string;
  setSearchString: Setter<string>;
  tags: TagInput[];
  setTags: Setter<TagInput[]>;
  resetTags: VoidFn;
  suggestions: Suggestion[] | undefined;
  newTag?: Suggestion;
  chainTags?: TagInput[];
  officalTags?: TagInput[];
  topTags?: TagInput[];
  isFocused: boolean;
  setIsFocused: Setter<boolean>;
  isReservedTag: (tagValue: string | undefined) => boolean;
}

function tagInputsFromInitial(initial: TagInitial[]): TagInput[] {
  const tags: TagInput[] = [];
  initial.forEach(({ name, slug }) => {
    if (!tags.some((tag) => tag.name === name)) {
      tags.push({ name, slug, isNew: false });
    }
  });
  return tags;
}

export function useEditTags(initial?: TagInitial[]): UseEditTags {
  const [searchString, setSearchString] = useState('');
  const [tags, setTags] = useState<TagInput[]>(tagInputsFromInitial(initial || []));
  const [isFocused, setIsFocused] = useState(false);

  const [getSuggestions, { data: suggestionsQuery }] = useSuggestionsLazyQuery({
    variables: { string: searchString, isTagOnly: true },
  });

  // fetch Chain Tags and Top Tags
  const { data: tagsQuery } = useTagsQuery({});

  const isReservedTag = useCallback(
    (tagValue: string | undefined) => {
      if (tagsQuery?.chainTags) {
        const { chainTags } = tagsQuery;
        return (
          chainTags.some(({ name }) => name.toLowerCase() === tagValue?.toLowerCase()) ||
          reservedKeywords.some((value) => value.toLowerCase() === tagValue?.toLowerCase())
        );
      }
      return false;
    },
    [tagsQuery?.chainTags]
  );

  const [suggestions, newTag] = useMemo(() => {
    const fromQuery = suggestionsQuery?.suggestions;
    const filteredFromQuery = fromQuery?.filter((tag) => !isReservedTag(tag.value as string));

    if (searchString?.length === 0) {
      return [filteredFromQuery, undefined];
    }

    const newSlug: string = newTagSlug(searchString);

    const isValid =
      newSlug.length > 0 &&
      searchString?.length > 0 &&
      (!filteredFromQuery ||
        !filteredFromQuery.some(({ value, isTag }) => isTag && newSlug === value));

    return [
      filteredFromQuery,
      isValid
        ? {
            isNew: true,
            isTag: true,
            value: newSlug,
            name: searchString,
          }
        : undefined,
    ];
  }, [searchString, suggestionsQuery?.suggestions, isReservedTag]);

  const resetTags = useCallback(() => {
    if (initial) {
      setTags(tagInputsFromInitial(initial));
    }
  }, [initial]);

  const memoizedGetSuggestions = useCallback(
    (searchString: string) => {
      getSuggestions({
        variables: {
          string: searchString,
        },
      });
    },
    [getSuggestions]
  );

  const debounceGetSuggestions = useCallback(
    debounce((searchString) => {
      memoizedGetSuggestions(searchString);
    }, 600),
    [memoizedGetSuggestions]
  );

  useEffect(() => {
    if ((searchString && searchString?.length > 0) || isFocused) {
      debounceGetSuggestions(searchString);
    }
  }, [searchString, debounceGetSuggestions, isFocused]);

  useEffect(() => {
    resetTags();
  }, [resetTags]);

  return useMemo(
    () => ({
      searchString,
      setSearchString,
      tags,
      setTags,
      resetTags,
      suggestions,
      newTag,
      isFocused,
      setIsFocused,
      isReservedTag,
      ...tagsQuery,
    }),
    [
      searchString,
      tags,
      suggestions,
      resetTags,
      newTag,
      tagsQuery,
      isFocused,
      setIsFocused,
      isReservedTag,
    ]
  );
}
