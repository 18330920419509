import { useEffect, useState } from 'react';

import { useBalanceHistoryLazyQuery, useMyProfileLazyQuery } from 'gql';
import { useCookies } from 'react-cookie';

const useBalanceHistory = () => {
  const startPageIndex = 1;
  const [pageIndex, setPageIndex] = useState<number>(startPageIndex);
  const [pageSize, setPageSize] = useState<number>(3);
  const [myProfile, setMyProfile] = useState<number | null>(null);

  const [{ sessionKey }] = useCookies(['account', 'sessionKey']);
  const [myProfileQuery, { loading: isProfileLoading }] = useMyProfileLazyQuery();
  const [balanceHistoryQuery, { data: balanceHistoryData, loading: isBalanceHistoryLoading }] =
    useBalanceHistoryLazyQuery();

  useEffect(() => {
    const fetchProfile = async () => {
      const { data: profile } = await myProfileQuery({
        variables: { sessionKey },
        fetchPolicy: 'cache-first',
      });

      setMyProfile(profile?.myProfile?.id as number);
    };
    fetchProfile();
  }, [myProfileQuery, sessionKey]);

  useEffect(() => {
    myProfile &&
      balanceHistoryQuery({
        variables: {
          userId: myProfile,
          limit: pageSize,
          page: pageIndex,
        },
        fetchPolicy: 'cache-and-network',
      });
  }, [balanceHistoryQuery, myProfile, pageSize, pageIndex]);

  return {
    balanceHistoryData: balanceHistoryData,
    isBalanceHistoryLoading: isBalanceHistoryLoading || isProfileLoading,
    startPageIndex,
    pageIndex,
    setPageIndex,
    pageSize,
    setPageSize,
  };
};

export default useBalanceHistory;
