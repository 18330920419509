export const routes = {
  base: '/',
  id: ':id',
  network: ':network',
  onboarding: '/onboarding',
  library: '/library',
  profile: '/profile',
  viewBuilder: '/view-builder',
  smartContract: '/smart-contract',
  view: '/view',
  apiKey: '/api-key',
  publisher: '/publisher',
  fundingActivity: '/funding-activity',
  balanceHistory: '/balance-history',
  404: '/404',
};

export const detailsRoutes = {
  smartContract: `${routes.smartContract}/${routes.id}/${routes.network}`,
  view: `${routes.view}/${routes.id}`,
  apiKey: `${routes.apiKey}/${routes.id}`,
  publisher: `${routes.publisher}/${routes.id}`,
  fundingActivity: `${routes.fundingActivity}/${routes.id}`,
};
