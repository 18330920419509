import { Skeleton } from 'components';
import { useMyProfile } from 'contexts';

export const AdditionalButton = () => {
  const profile = useMyProfile();

  return (
    <div className="flex flex-row sm:w-[unset] w-full justify-between items-center gap-4 sm:order-2 order-1">
      <Skeleton.Loader isDarkTheme className="w-[120px] h-10 !rounded-full">
        <button
          data-cy="add-api-key-btn"
          className="flex gap-2 md:text-base text-sm text-white cursor-pointer"
          onClick={() => profile.setIsCreateVisible(true)}
        >
          <div>+</div>Add API Key
        </button>
      </Skeleton.Loader>
      {/* <Skeleton.Loader isDarkTheme className="w-[140px] h-10 !rounded-full">
    <div className="flex flex-row shrink-0 pl-4 md:justify-end justify-start items-center text-sm border border-[#ffffff14] rounded-3xl">
      <span className="text-[#B2B3B8]">
        Sort by <span className="ml-1">·</span>
      </span>
      <Select
        value={sortBy}
        onChange={setSortBy}
        options={[
          { value: 'all', label: 'All' },
          { value: 'testnet', label: 'Testnet' },
          { value: 'mainnet', label: 'Mainnet' },
        ]}
        isLibrary
        classNames={{
          control: () =>
            'w-25 h-10 relative rounded-3xl shadow shadow-inner border border-white border-opacity-20 bg-transparent text-white text-sm font-normal pl-2 pr-4 py-2 placeholder:text-[#B2B3B8] hover:border-white',
          dropdownIndicator: () => 'text-white',
          option: (_) =>
            classes('bg-[#010101] text-white  hover:bg-[#1B1B1B]', _ && ''),
          menu: () => 'border-none !top-[45px] rounded-[8px] overflow-hidden',
        }}
      />
    </div>
  </Skeleton.Loader> */}
    </div>
  );
};

export default AdditionalButton;
