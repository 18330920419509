import { useEffect, useRef } from 'react';

import { useSkeleton } from 'components';
import { SearchType } from 'gql';
import { useLibrary, useWindowSize } from 'hooks';
import { useLocation } from 'react-router-dom';

const useFilters = () => {
  const {
    windowSize: { width },
  } = useWindowSize();

  const location = useLocation();
  const isFilterChanged = useRef(false);
  const isLoading = useSkeleton();

  const {
    searchType,
    setSearchType,
    searchTerm,
    tags,
    sortBy,
    setSortBy,
    currentPage,
    isLoading: isListLoading,
    isLoadingCount,
  } = useLibrary();

  useEffect(() => {
    if (
      location.state?.tab === SearchType.SmartContracts ||
      location.state?.tab === SearchType.Views
    ) {
      setSearchType(location.state?.tab);
    }
  }, []);

  useEffect(() => {
    if (searchTerm?.length || tags.length) {
      isFilterChanged.current = true;
    }
  }, [searchTerm, tags]);

  useEffect(() => {
    if (isFilterChanged.current && !isListLoading) {
      if (searchType === SearchType.SmartContracts && currentPage?.length === 0) {
        setSearchType(SearchType.Views);
      } else if (searchType === SearchType.Views && currentPage?.length === 0) {
        setSearchType(SearchType.SmartContracts);
      }
      isFilterChanged.current = false;
    }
  }, [currentPage?.length, isLoading]);

  return {
    width,
    sortBy,
    setSortBy,
    isListLoading,
    isLoading,
    isLoadingCount,
    searchType,
    setSearchType,
  };
};

export default useFilters;
