/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useMemo } from 'react';

import { useSearchParams } from 'react-router-dom';

// Utility function to parse boolean values from string
const parseBoolean = (value: string | null): boolean | string | null => {
  if (value === 'true') return true;
  if (value === 'false') return false;
  return value;
};

// Utility function to parse number values from string
const parseNumber = (value: string | null): number | string | null => {
  const number = parseFloat(value as string);
  return isNaN(number) ? value : number;
};

type UseParamStateReturn<T> = [
  T,
  (_?: T | ((_: T) => T)) => void,
  (values: Record<string, any>) => void
];

/**
 * Custom hook to manage a URL search parameter state.
 * Supports string, boolean, and number values for the parameter.
 *
 * @param {string} paramName - The name of the URL search parameter to manage.
 * @param {T} defaultValue - The default value for the parameter if it is not set.
 * @returns {UseParamStateReturn<T>} -
 *          A tuple containing the current state of the parameter, a function to update it,
 *          and a function to update multiple parameters.
 *
 * @example
 * const [paramState, setParamState, setMultipleParams] = useParamState('myParam', 'default');
 *
 * // Set parameter to a string
 * setParamState('value');
 *
 * // Set parameter to a boolean
 * setParamState(true);
 *
 * // Increment parameter if it's a number
 * setParamState((prev) => prev + 1);
 *
 * // Remove parameter
 * setParamState(null);
 *
 * // Update multiple params
 * setMultipleParams({ param1: 'value1', param2: 2 });
 */
export function useParamState<T extends string | boolean | number>(
  paramName: string,
  defaultValue?: T
): UseParamStateReturn<T> {
  const [searchParams, setSearchParams] = useSearchParams();

  const parseValue = (value: string | null): T => {
    const _value = (value || defaultValue) as string | null;
    if (typeof defaultValue === 'boolean') {
      return parseBoolean(_value) as T;
    } else if (typeof defaultValue === 'number') {
      return parseNumber(_value) as T;
    } else {
      return _value as T;
    }
  };

  const paramState = useMemo(() => {
    const paramValue = searchParams.get(paramName);
    return parseValue(paramValue);
  }, [paramName, searchParams]);

  const setParamState = useCallback(
    (paramValue?: T | ((_: T) => T)) => {
      const newValue =
        typeof paramValue === 'function'
          ? paramValue(parseValue(searchParams.get(paramName)))
          : paramValue;

      if (newValue !== undefined) {
        searchParams.set(paramName, newValue.toString());
      } else {
        searchParams.delete(paramName);
      }
      setSearchParams(searchParams);
    },
    [paramName, searchParams, setSearchParams]
  );

  const setMultipleParams = useCallback(
    (values: Record<string, any>) => {
      Object.keys(values).forEach((key) => {
        let value = values[key];
        value = typeof value === 'function' ? value(parseValue(searchParams.get(key))) : value;
        if (value !== undefined) {
          searchParams.set(key, value.toString());
        } else {
          searchParams.delete(key);
        }
      });
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  return [paramState, setParamState, setMultipleParams] as const;
}
