import { useEffect, useMemo } from 'react';

import { usePublisher as userPublisherCtx } from 'contexts';
import { useSearchLibraryCountLazyQuery } from 'gql';
import { useCookies } from 'react-cookie';
import { useLocation, useParams } from 'react-router-dom';

const usePublisher = () => {
  const { publisher, isLoading, publisherStats } = userPublisherCtx();
  const location = useLocation();
  const isPrivate = useMemo(() => location.pathname.includes('profile'), [location.pathname]);
  const { id } = useParams();
  const [{ account: accountCookie }] = useCookies(['account']);
  const [executeSearchLibraryCount, { data: countData, loading: isCountDataLoading }] =
    useSearchLibraryCountLazyQuery();

  useEffect(() => {
    executeSearchLibraryCount({
      variables: {
        filters: {
          publisherAddress: isPrivate ? accountCookie?.address : id,
        },
      },
    });
  }, [id]);

  const refreshSearchQuery = () => {
    executeSearchLibraryCount({
      variables: {
        filters: {
          publisherAddress: isPrivate ? accountCookie?.address : id,
        },
      },
      fetchPolicy: 'cache-and-network',
    });
  };
  return {
    isLoading,
    publisher,
    countData,
    publisherStats,
    refreshSearchQuery,
    isCountDataLoading,
  };
};

export default usePublisher;
