export const Header = () => {
  return (
    <div className="md:mb-6 mb-3">
      <h1 className="mb-2 sm:text-[56px] text-[32px]">Library</h1>
      <div className="sm:text-lg text-sm max-w-[800px] text-[#F3EAEC]">
        The Library is a marketplace of listed smart contracts and published Views.Use the listed
        smart contracts to build new Views or explore published Views directly in your browser.
      </div>
    </div>
  );
};
