import { HTMLAttributes } from 'types';

export function Clock(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.6667 8.00065C14.6667 11.6807 11.68 14.6673 8 14.6673C4.32 14.6673 1.33333 11.6807 1.33333 8.00065C1.33333 4.32065 4.32 1.33398 8 1.33398C11.68 1.33398 14.6667 4.32065 14.6667 8.00065Z"
        stroke="#B2B3B8"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4733 10.1192L8.40666 8.88586C8.04666 8.67253 7.75333 8.15919 7.75333 7.73919V5.00586"
        stroke="#B2B3B8"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
