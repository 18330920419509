import { MergeContractMutation } from 'gql';

interface useListNewSmartContractProps {
  smartContractAddress: string;
  isSmartContractAlreadyListed: boolean;
  mergeContractResult: MergeContractMutation | null | undefined;
}

const useListNewSmartContract = ({
  smartContractAddress,
  isSmartContractAlreadyListed,
  mergeContractResult,
}: useListNewSmartContractProps) => {
  let existErrorMsg = undefined,
    redirectAddress = '';

  const errorMessage = mergeContractResult?.MergeContract?.error;

  if (isSmartContractAlreadyListed) {
    existErrorMsg = 'Current listing already exists please check';
    redirectAddress = smartContractAddress;
  } else if (errorMessage?.includes('already exists with name')) {
    const nameRegex = /'([^']+)'/;

    const nameMatch = errorMessage.match(nameRegex);
    existErrorMsg = `A contract with the Identifier ${nameMatch && nameMatch[0]} already exists`;
    redirectAddress = '';
  }

  return { existErrorMsg, redirectAddress };
};
export default useListNewSmartContract;
