import { ResourceList } from 'components/ResourceList';
import { Maybe, SearchType } from 'gql';
import { SearchResult } from 'types';
import { classes } from 'utils';

interface ResourcesPanelProps<T> {
  placeholderCount?: number;
  searchType: SearchType;
  data?: Maybe<T[]>;
  isList?: boolean;
  isEmptyView?: React.ReactNode;
  isLoading?: boolean;
}

export function ResourcesPanel<T extends SearchResult>({
  data,
  placeholderCount,
  isList = true,
  isEmptyView,
  isLoading,
}: ResourcesPanelProps<T>) {
  return (
    <ResourceList<T>
      placeholderCount={placeholderCount}
      value={data || []}
      isLoading={isLoading}
      isList={isList}
      isDarkTheme
      className={classes(
        'flex flex-col ',
        !isList && 'grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-6',
        !isLoading && !isList && !data?.length && 'flex'
      )}
      isEmptyView={isEmptyView}
      withLocation
    />
  );
}
