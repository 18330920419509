import { HTMLAttributes } from 'types';

export function AnalogClock(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="188"
      height="205"
      viewBox="0 0 188 205"
      fill="none"
      {...props}
    >
      <path
        d="M167.565 36.2617L114.114 5.39822C101.638 -1.79941 86.1481 -1.79941 73.6717 5.39822L20.2212 36.2617C7.74477 43.4671 0 56.8836 0 71.2866V133.014C0 147.417 7.75247 160.833 20.2212 168.039L73.6717 198.894C79.906 202.493 86.9033 204.296 93.8928 204.296C100.882 204.296 107.88 202.493 114.114 198.894L167.565 168.039C180.041 160.833 187.786 147.417 187.786 133.014V71.2866C187.793 56.8913 180.041 43.4671 167.565 36.2617ZM169.946 133.014C169.946 141.059 165.615 148.557 158.641 152.587L105.19 183.443C98.2238 187.466 89.562 187.466 82.5878 183.443L29.1373 152.587C22.1632 148.565 17.8323 141.059 17.8323 133.014V71.2866C17.8323 63.2413 22.1632 55.7431 29.1373 51.7127L82.5878 20.8492C86.0711 18.8379 89.9781 17.8284 93.8851 17.8284C97.7922 17.8284 101.699 18.8379 105.182 20.8492L158.633 51.7127C165.607 55.7354 169.938 63.2413 169.938 71.2866V133.014H169.946Z"
        fill="url(#paint0_linear_540_908)"
      />
      <path
        d="M137.355 66.3231L88.5741 94.6049C85.6149 96.3157 84.0428 99.4599 84.1584 102.658C84.1507 102.812 84.1122 102.951 84.1122 103.105V142.16C84.1122 147.084 88.104 151.084 93.036 151.084C97.9603 151.084 101.96 147.092 101.96 142.16V107.498L146.278 81.7894C150.548 79.3234 152.004 73.8675 149.546 69.5982C147.08 65.3212 141.616 63.8571 137.355 66.3231Z"
        fill="url(#paint1_linear_540_908)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_540_908"
          x1="21.8551"
          y1="23.5111"
          x2="93.9242"
          y2="102.186"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_540_908"
          x1="26.2824"
          y1="8.60224"
          x2="102.343"
          y2="91.6345"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
      </defs>
    </svg>
  );
}
