import { useCallback, useState } from 'react';

import { Button, CustomInfoIcon, Input, Tag } from 'components';
import { useApi } from 'contexts';
import { useFormField } from 'hooks/useFormField';
import { useListSmartContract } from 'hooks/useListSmartContracts';
import { Link, useSearchParams } from 'react-router-dom';
import { classes, pathTo } from 'utils';

import useListNewSmartContract from './useListNewSmartContract';

type ListNewSmartContractProps = {
  onNext: (tabIndex?: number) => void;
};

function NewSmartContract({ onNext }: ListNewSmartContractProps) {
  const {
    smartContractAddress,
    handleSmartContractInputChange,
    isSmartContractAlreadyListed,
    isError,
    setIsError,
    mergeContractResult,
    setIsMergeContractError,
    selectedChain,
    tags,
    handleChainTagClick,
    setMergeContractResult,
    dryRunContract,
    smartContractIdentifier,
    smartContractInstance: selectedInstance,
  } = useListSmartContract();

  const [_, isValid, isTouched, setSmartContractValue, setIsTouched] = useFormField(
    '',
    (s) => s.length > 0 && /^[a-fA-F0-9]{40}$/.test(s)
  );
  const { existErrorMsg, redirectAddress } = useListNewSmartContract({
    smartContractAddress,
    isSmartContractAlreadyListed,
    mergeContractResult,
  });
  const { account, sessionKey } = useApi();

  const validateSmartContract = () => {
    setIsTouched(true);
    return setSmartContractValue(smartContractAddress);
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const [formError, setFormError] = useState({
    address: {
      isTouched: false,
      isError: false,
    },
    chain: {
      isTouched: false,
      isError: false,
    },
  });

  const validateInputs = useCallback(() => {
    if (!isSmartContractAlreadyListed) {
      setFormError({
        address: {
          isError: smartContractAddress.trim().length !== 40,
          isTouched: true,
        },
        chain: {
          isError: !selectedChain,
          isTouched: true,
        },
      });
    }
    return (
      isSmartContractAlreadyListed || (selectedChain && smartContractAddress.trim().length >= 20)
    );
  }, [smartContractAddress, isSmartContractAlreadyListed, selectedChain]);

  return (
    <>
      <div className="flex flex-col gap-4 sm:gap-6 p-6 sm:p-8 pt-[18px] sm:pt-[26px]">
        <div>
          <h6 className="text-xl sm:text-2xl  font-normal pb-[6px] sm:pb-2 text-white">
            Add a Smart Contract
          </h6>
          <p className="text-sm font-normal text-[#B2B3B8] sm:leading-[22px]">
            Specify the smart contract you want to access.
          </p>
        </div>
        <div className="mt-1">
          <label
            htmlFor="smart-contract-input"
            className="text-white text-sm font-normal leading-[22px]"
          >
            Smart Contract Address *
          </label>
          <Input
            dataCy="input-smart-contract-address"
            tabIndex={0}
            id="smart-contract-input"
            classNames={{
              base: classes(
                existErrorMsg ? 'mt-2' : 'mt-3',
                'dark h-10 sm:h-12 placeholder:text-[#ffffff7a] text-sm py-[11px] sm:py-3  px-4 border bg-[#010101] text-[#6C6B6B] rounded-3xl',
                isError ? 'border-red-400' : 'border-[#383838]'
              ),
              input: classes('text-white pl-0', !smartContractAddress && 'pl-2'),
            }}
            type="text"
            isError={
              (isTouched && !isValid) || (formError.address.isError && formError.address.isTouched)
            }
            placeholder="e.x. 484fhuiry7yrh8374393939"
            value={smartContractAddress}
            onChange={(text) => {
              handleSmartContractInputChange(text);
              setIsTouched(false);
              setMergeContractResult(null);
              if (formError.address.isError)
                setFormError((prev) => ({
                  ...prev,
                  address: {
                    isError: false,
                    isTouched: false,
                  },
                }));
            }}
            pre={smartContractAddress && <div className="pl-2 text-sm text-[#FFFFFF7A]">0x</div>}
          ></Input>
          {((isTouched && !isValid) ||
            (formError.address.isError && formError.address.isTouched)) &&
            !existErrorMsg && (
              <span data-cy="invalid-address-length-error" className="text-[#FF6666] text-xs mt-2">
                {/^.{40}$/.test(smartContractAddress)
                  ? 'Invalid address.'
                  : 'Invalid address length.'}
              </span>
            )}
          {existErrorMsg && (
            <div className="mt-1 text-[#FF5C5C] justify-start items-center gap-[8px] flex">
              <CustomInfoIcon stroke="#FF5C5C" />
              <div
                data-cy="Current listing already exists please check"
                className="text-xs font-normal leading-5"
              >
                This smart contract is already listed.
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col relative">
          <p className={classes('text-white text-sm')}>Select chain *</p>
          <div className="mt-3">
            <ul className={classes('flex flex-wrap items-center gap-2')}>
              {tags?.chainTags.map((tag) => {
                return (
                  <Tag
                    tabIndex={0}
                    key={tag.slug}
                    className="select-none items-center "
                    classNames={{
                      base: 'border border-transparent focus:border-white focus:border focus:border-solid',
                    }}
                    isDarkTheme
                    value={tag}
                    isActive={selectedChain?.slug.split('-')[0] === tag.slug}
                    onClick={() => handleChainTagClick(tag)}
                    icon={
                      <img src={`/logos/chain/${tag.name?.toLowerCase()}.svg`} alt={tag.slug} />
                    }
                  >
                    {tag.name}
                  </Tag>
                );
              })}
            </ul>
            {formError.chain.isTouched && formError.chain.isError && (
              <div data-cy="select-chain-error" className="text-[#FF6666] text-xs">
                Please select a chain.
              </div>
            )}
          </div>
        </div>
        {existErrorMsg ? (
          <div className="flex flex-col sm:flex-row gap-4">
            <Button
              variant="plain"
              isDisabled={!smartContractAddress && !isError}
              className="w-full h-11 sm:h-12 rounded-3xl py-[10px] px-5 sm:py-3 sm:px-6 border border-white"
              onClick={() => {
                if (validateSmartContract()) {
                  if (!account?.address) {
                    searchParams.set('connect', 'true');
                    setSearchParams(searchParams);
                    return;
                  }
                  onNext(2);
                  dryRunContract({
                    variables: {
                      data: {
                        address: smartContractAddress,
                        chain: selectedInstance?.value || 'mainnet',
                        identifier: smartContractIdentifier,
                        network: selectedChain?.slug as string,
                      },
                      sessionKey: sessionKey as string,
                    },
                  });
                }
              }}
            >
              <span className="text-base text-white">Add Functions</span>
            </Button>
            <Button
              variant="darkThemeFilled"
              isDisabled={!smartContractAddress && !isError}
              className="w-full h-11 sm:h-12 rounded-3xl py-[10px] px-5 sm:py-3 sm:px-6"
            >
              <Link
                to={pathTo('SmartContract', redirectAddress, selectedChain?.name)}
                data-cy="view-already-listed-sc"
                className="text-black text-base font-normal"
              >
                View Smart Contract
              </Link>
            </Button>
          </div>
        ) : (
          <Button
            variant="plain"
            isDisabled={!smartContractAddress || !selectedChain}
            className={classes(
              'w-full h-11 sm:h-12 rounded-3xl py-[10px] px-5 sm:py-3 sm:px-6',
              smartContractAddress && !isError ? 'bg-white' : 'bg-[#7a7a7a]'
            )}
            onClick={() => {
              setIsMergeContractError(false);
              setIsError(false);
              if (validateInputs()) {
                if (!account?.address) {
                  searchParams.set('connect', 'true');
                  setSearchParams(searchParams);
                  return;
                }
              }
              if (validateSmartContract()) {
                onNext();
              }
            }}
          >
            <span className="text-sm sm:text-base text-black">Next</span>
          </Button>
        )}
      </div>
    </>
  );
}

export default NewSmartContract;
