import { createRef, useEffect, useMemo, useState } from 'react';

import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { classes } from 'utils';

import { Button } from '../Button';

import HelpPageItems from './components/HelpPageItems';
import { HELP_PAGES } from './components/utils';

export function HelpMe() {
  const ref = createRef<HTMLDivElement>();
  const [isOpen, setIsOpen] = useState(false);
  const [openedSection, setOpenedSection] = useState<number | null>(null);
  const [userProgress, setUserProgress] = useState<number[]>([]);
  const progressStatus = useMemo(() => {
    const progress = (userProgress.length / Object.keys(HELP_PAGES).length) * 100;
    return progress.toFixed(0);
  }, [userProgress.length]);

  useEffect(() => {
    const progress = JSON.parse(localStorage.getItem('helpProgress') || '{}')?.progress;
    if (progress) {
      setUserProgress(progress);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('helpProgress', JSON.stringify({ progress: userProgress }));

    const iframeHandler = () => {
      setTimeout(() => {
        if (document.activeElement?.tagName === 'IFRAME') {
          if (!userProgress.includes(+document.activeElement.id)) {
            if (document.activeElement && document.activeElement.id)
              setUserProgress((arr) => [+document.activeElement!.id, ...arr]);
          }
        }
      });
    };
    document.addEventListener('blur', iframeHandler, true);
    return () => document.removeEventListener('blur', iframeHandler, true);
  }, [userProgress]);

  useEffect(() => {
    const onClickOutside = ({ target }: MouseEvent) => {
      if (ref.current && !ref.current.contains(target as Node)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('click', onClickOutside, true);
    } else {
      document.removeEventListener('click', onClickOutside, true);
    }
    return () => {
      document.removeEventListener('click', onClickOutside, true);
    };
  }, [isOpen, ref]);

  return (
    <div
      ref={ref}
      className={classes(
        'fixed flex flex-col max-w-fit items-end space-y-4 md:right-10 right-3 bottom-10 z-50 '
      )}
    >
      {isOpen && (
        <>
          <div
            className="fixed -z-10 sm:backdrop-blur-0 backdrop-blur-lg w-full h-full right-0 top-0"
            role="button"
            tabIndex={0}
            onClick={(e) => {
              setIsOpen(!isOpen);
              e.stopPropagation();
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                setIsOpen(!isOpen);
              }
            }}
          />
          <div
            className={classes(
              '!bg-gradient-to-b from-[#000_20.37%] to-[#000_79.34%] !border-[#1F1F1F] md:rounded-[24px] rounded-[14px] text-white right-0 ml-1.5 bottom-16 card overflow-hidden',
              openedSection !== null ? 'h-[550px]' : ''
            )}
          >
            <div
              className={classes(
                'md:w-[360px] sm:w-[295px] w-full py-6',
                openedSection !== null ? 'max-h-[500px]' : 'max-h-[344px]'
              )}
            >
              <div className="md:px-6 px-3">
                <h3 className="self-start uppercase text-lg leading-normal font-normal">
                  Quick Start
                </h3>
                <div className="text-[#B2B3B8] text-xs font-normal  leading-[18px] mt-1 mb-3">
                  Wondering where to start? Here’s everything you’ll want to learn to get started
                  with Analog Watch quickly.
                </div>
                <div className="h-[6px] w-full bg-[#ffffff1a] rounded-[17px] mb-[6px]">
                  <div
                    className="bg-[linear-gradient(287deg,_var(--tw-gradient-stops))] from-[#7457B8_3%] via-[#B65ECA_45%] to-[#D97474_95%] h-full rounded-[17px] transition-[width] ease-linear delay-100"
                    style={{ width: `${progressStatus}%` }}
                  ></div>
                </div>
                <div className="text-xs font-normal text-[#B2B3B8] mb-4">
                  {progressStatus}% Completed
                </div>
              </div>
              <div
                className={classes(
                  'flex flex-col space-y-2 md:px-6 px-3 overflow-x-hidden overflow-y-scroll scrollbar-dark',
                  openedSection !== null ? 'h-[500px] pb-28' : 'h-[182px]'
                )}
              >
                <HelpPageItems
                  openedSection={openedSection}
                  setOpenedSection={setOpenedSection}
                  setUserProgress={setUserProgress}
                  userProgress={userProgress}
                />
              </div>
            </div>
          </div>
        </>
      )}
      <Button
        variant="primary"
        className={classes(
          '!bg-white rounded-[9999px] to-red-400 h-12 justify-center items-center !border-none cursor-pointer select-none sm:!py-3 !py-[11px] px-6',
          !isOpen && 'bg-gradient-to-l  from-purple-500 !bg-transparent !pl-[22px] !pr-6'
        )}
        onClick={() => {
          setIsOpen((prev) => !prev);
        }}
      >
        {!isOpen ? (
          <div className="flex gap-1 items-center">
            <QuestionMarkCircleIcon className="w-7 h-7 stroke-1" />
            <p className="font-normal text-base normal-case">Help</p>
          </div>
        ) : (
          <div className="text-black font-normal text-base normal-case">Close</div>
        )}
      </Button>
    </div>
  );
}
