// import { Controls } from './Controls';
import { RadioGroup, Tab } from '@headlessui/react';
import { GradientRefreshButton } from 'components/GradientRefreshButton';
import { Header } from 'components/Header';
import { Select } from 'components/Select/Select';
import { Skeleton } from 'components/Skeleton';
import { CardIcon, ListIcon } from 'components/svg';
import { Maybe, SearchLibraryCountQuery } from 'gql';
import { SearchResult } from 'types';
import { classes, SORT_OPTIONS } from 'utils';

import { useDataDesign } from './useDataDesign';
interface DataDesignProps {
  isPrivate?: boolean;
  isLoading?: boolean;
  countData?: Maybe<SearchLibraryCountQuery>;
  refreshCountHandler: () => void;
}

export function DataDesign<T extends SearchResult>({
  isPrivate,
  isLoading: countDataLoading,
  countData,
  refreshCountHandler,
}: DataDesignProps) {
  const {
    refreshSearchQuery,
    sortBy,
    setSortBy,
    setIsList,
    isLoading,
    tabPanels,
    tabSelector,
    setSelectedTab,
    selectedTab,
    controls,
    setPage,
  } = useDataDesign({ isPrivate: Boolean(isPrivate), countData });

  return (
    <div className="">
      <Tab.Group
        selectedIndex={selectedTab}
        onChange={(index) => {
          setPage(1);
          setSelectedTab(index);
        }}
      >
        <Header
          accessory={
            <div className="flex md:flex-row flex-col justify-between sm:items-center items-start">
              <Skeleton.Loader
                isDarkTheme
                className="sm:w-[235px] w-[200px] h-[24px] !rounded-full"
              >
                {tabSelector}
              </Skeleton.Loader>
              <div className="md:flex hidden flex-row justify-center items-center">
                <Skeleton.Loader isDarkTheme className="w-[148px] h-10 !rounded-full  mr-3">
                  <div className="mr-[14px] flex flex-row shrink-0 pl-4 md:justify-end justify-start items-center text-sm border border-[#ffffff14] rounded-3xl">
                    <span className="text-[#B2B3B8]">
                      Sort by <span className="ml-1">·</span>
                    </span>
                    <Select
                      value={sortBy}
                      onChange={setSortBy}
                      options={SORT_OPTIONS as any}
                      isLibrary
                      classNames={{
                        control: () =>
                          'w-25 h-10 relative rounded-3xl shadow shadow-inner border border-white border-opacity-20 bg-transparent text-zinc-400 text-sm font-normal pl-2 pr-4 py-2',
                        dropdownIndicator: () => 'text-white ',
                        menu: () => 'w-fit min-w-fit border-none',
                        option: () => 'flex justify-between items-center',
                      }}
                    />
                  </div>
                </Skeleton.Loader>
                <Skeleton.Loader isDarkTheme>
                  <div className="mr-[14px]">
                    <GradientRefreshButton
                      onClick={() => {
                        if (refreshCountHandler && refreshSearchQuery) {
                          refreshCountHandler();
                          refreshSearchQuery();
                        }
                      }}
                      isLoading={isLoading || countDataLoading}
                    />
                  </div>
                </Skeleton.Loader>
                <RadioGroup className="flex flex-row gap-[2px]" defaultChecked defaultValue="list">
                  {[
                    { svg: <ListIcon />, value: 'list' },
                    { svg: <CardIcon />, value: 'card' },
                  ].map(({ svg, value }) => (
                    <RadioGroup.Option value={value} key={value}>
                      {({ checked }) => (
                        <Skeleton.Loader isDarkTheme className="w-10 h-10 !rounded-full">
                          <button
                            className={classes(
                              'w-10 h-10 p-[10px] flex items-center justify-center',
                              checked &&
                                'bg-[#1B1B1B] rounded-3xl border border-solid border-[#FFFFFF33]'
                            )}
                            onClick={() => setIsList(value === 'list')}
                          >
                            {svg}
                          </button>
                        </Skeleton.Loader>
                      )}
                    </RadioGroup.Option>
                  ))}
                </RadioGroup>
              </div>
            </div>
          }
          accessoryClassname={classes('flex md:flex-1 flex-row w-full h-auto')}
        />
        {tabPanels}
      </Tab.Group>
      <Skeleton.Provider isLoading={isLoading}>{controls}</Skeleton.Provider>
    </div>
  );
}
